import ApiService from "@/common/api.service";

export default {
  getTests(id, data) {
    return ApiService.query(`/exams/${id}`, data);
  },
  getSections(data) {
    return ApiService.query("/exam-sections", data);
  },
  updateInfo(id, data) {
    return ApiService.patch(`/exams/${id}`, data);
  }
};

<template>
  <div class="box">
    <div class="container">
      <div class="sat">
        <h1 class="sat_left">
          <p><span class="test-color">ACT</span>Mocks</p>
        </h1>
      </div>
      <hr class="mt-0" />
      <Type :type="testType" v-if="false" />
      <!-- <hr /> -->
      <el-form ref="form" @submit.native.prevent>
        <el-form-item>
          <el-input
            ref="filterInput"
            placeholder="Name"
            v-model="search.search"
            @keyup.enter.native="searchResult"
          >
            <el-button
              el-button
              slot="append"
              icon="el-icon-search"
              @click="searchResult"
            >
              Search
            </el-button>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-radio
            v-for="(source, index) in sources"
            :key="index"
            v-model="search.source"
            @input="searchResult"
            :label="source.value"
            border
          >
            <b>{{ source.name }}</b></el-radio
          >
        </el-form-item>
      </el-form>
      <el-alert v-if="resultsTotal > -1" type="success" :closable="false">
        <span slot="title">
          Results: <b>{{ resultsTotal }}</b>
        </span>
      </el-alert>
      <div v-if="practices">
        <div class="text-center" style="margin:15px 0">
          <el-pagination
            background
            @current-change="setPage"
            :page-size="pageSize"
            :current-page="page"
            :total="resultsTotal"
            :pager-count="9"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
        <el-table
          v-if="practices.length > 0"
          :data="practices"
          style="width: 100%"
          stripe
        >
          <el-table-column label="Type" width="60">
            <template slot-scope="scope">
              <el-tooltip
                v-if="practiceType === 'composePractices'"
                class="item"
                effect="dark"
                content="Linear Test"
                placement="top"
              >
                <el-tag type="success">
                  <i class="fas fa-arrow-right"></i>
                </el-tag>
              </el-tooltip>
              <el-tooltip
                v-if="practiceType === 'adaptivePractices'"
                class="item"
                effect="dark"
                content="Adaptive Test"
                placement="top"
              >
                <el-tag type="warning">
                  <i class="fas fa-code-branch"></i>
                </el-tag>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="Name" min-width="170">
            <template slot-scope="scope">
              <template>
                <template v-if="scope.row.can_purchase">
                  <b>{{ getCompanyExamTitle(scope.row.name) }} </b>
                </template>
                <span v-else style="opacity: 0.5;">
                  {{ getCompanyExamTitle(scope.row.name) }}
                </span>
              </template>
            </template>
          </el-table-column>
          <template v-if="practiceType === 'composePractices'">
            <el-table-column label="Questions" width="100">
              <template slot-scope="scope">
                <b class="text-success">
                  {{ scope.row.question_count }}
                </b>
              </template>
            </el-table-column>
          </template>
          <el-table-column
            width="130"
            label="Actions"
            fixed="right"
            align="center"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.can_purchase">
                <el-tooltip
                  effect="dark"
                  content="Start a test"
                  placement="top"
                >
                  <el-button
                    style="color: white !important;"
                    type="success"
                    size="mini"
                    @click="startATest(scope.row.id)"
                  >
                    <i class="fas fa-play"></i>
                  </el-button>
                </el-tooltip>
              </template>
              <template v-else>
                <router-link
                  :to="{
                    name: isRoleTeacher() ? 'EducatorPlans' : 'StudentPlans'
                  }"
                >
                  <el-tooltip
                    effect="dark"
                    content="Subscribe to unlock all ACT Tests."
                    placement="top"
                  >
                    <el-button
                      style="color: white !important;"
                      type="info"
                      size="mini"
                    >
                      <i class="fas fa-lock"></i>
                    </el-button>
                  </el-tooltip>
                </router-link>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <div v-else>
          <el-empty :description="$t('courses.empty-text')"></el-empty>
        </div>
        <div class="text-center" style="margin:15px 0">
          <el-pagination
            background
            @current-change="setPage"
            :page-size="pageSize"
            :current-page="page"
            :total="resultsTotal"
            :pager-count="9"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
        <el-alert
          type="info"
          class="mt-2 mb-4"
          show-icon
          :closable="false"
          style="text-align:left"
        >
          <span>
            Need more practices? Want a particular type of drill practices?
          </span>
          <a @click="goToFeedbackPage" style="cursor: pointer;">
            <b>Let us know </b>
          </a>
          <span>and we'll create them for you!</span>
        </el-alert>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Type from "@/views/act/components/Type";
// import Practices from "@/views/act/mockData/Practices.json";
import ACTList from "@/views/act/apis/list.js";
import Common from "@/mixins/common.js";
// import Theme from "@/common/theme";
// import { instant } from "@ivy-way/material";
import role from "@/mixins/role.js";
import roleMixin from "@/mixins/role";

export default {
  metaInfo() {
    return {
      title: "Take ACT Tests"
    };
  },

  components: { Type },

  mixins: [Common, role, roleMixin],

  props: [],
  data() {
    return {
      practices: null,
      resultsTotal: 0,
      pageSize: 0,
      search: {
        search: null,
        source: "official"
      },
      sources: [
        {
          name: "All",
          value: null
        },
        {
          name: "Official",
          value: "official"
        }
      ]
    };
  },
  computed: {
    practiceType() {
      if (this.testType === "Full Tests") {
        return "composePractices";
      } else if (this.testType === "Adaptive Tests") {
        return "adaptivePractices";
      } else {
        return "composePractices";
      }
    },
    testType() {
      let testType = "Full Tests";
      if (this.$route.query.type) {
        testType = this.$route.query.type;
      }
      return testType;
    },
    searchString() {
      return this.$route.query.search ? this.$route.query.search : null;
    },
    searchSource() {
      return this.$route.query.source ? this.$route.query.source : null;
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    }
  },
  watch: {},

  mounted() {
    this.search.search = this.searchString;
    this.search.source = this.searchSource;
    this.getExams();
  },

  methods: {
    async startATest(examId) {
      const res = await ACTList.getExam(examId);
      let userExamId = res.user_exam.id;
      this.$router.push({
        name: "ACTTest",
        params: {
          examId: examId,
          userExamId: userExamId
        }
      });
    },
    async getExams() {
      const res = await ACTList.getExams({
        exam_type_name: "Full Mock",
        test_name: "ACT",
        page: this.page,
        keyword: this.search.search,
        resource_group_identifier: this.search.source,
        per_page: 20,
        sort_by: null,
        sort_order: null
      });
      this.practices = res.exams.data;
      this.resultsTotal = res.exams.total;
      this.pageSize = res.exams.per_page;
    },
    getQuestionsCount(practices) {
      let count = 0;
      if (practices && practices.length > 0) {
        practices.forEach(item => {
          count += item.exam.exam_questions_count;
        });
      }
      return count;
    },
    setPage(page) {
      this.$router.push({
        name: this.$route.name,
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    },
    searchResult() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          ...this.search,
          page: 1
        }
      });
    },
    async goToFeedbackPage() {
      window.open(
        `mailto:${this.CompanyEmail}?subject=Requesting Practice Tests`,
        "_blank"
      );
    }
  }
};
</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
.act-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 14px;
}

.td-section a {
  margin-right: 15px;
}
.table th {
  color: white !important;
}
.sat {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
}
.box .container {
  padding-top: 0;
}

.sat_left {
  margin: 1rem 0;
  display: flex;
  color: var(--themeColor);
  text-decoration: none;
}

.sat_left > span {
  font-weight: 700;
  font-size: 20px;
}
.sat_left > p {
  font-size: 2rem;
  font-weight: 700;
  line-height: 3rem;
  margin-bottom: 0px;
}
</style>
<style scoped src="@/views/act/assets/css/act.css"></style>
<style scoped src="@/views/act/assets/css/simplebar.css"></style>
<style scoped src="@/views/act/assets/css/app.min.css"></style>

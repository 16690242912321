<template>
  <div class="container pt-3">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link
            :to="{
              name: 'EducatorFullTestsList'
            }"
          >
            Full Tests
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle"></Heading>
    <hr />
    <el-form
      :model="newTest"
      ref="newTest"
      label-width="100px"
      @submit.native.prevent
      @keyup.enter.native="createNew('newTest')"
    >
      <el-form-item label="Type" required prop="test_id">
        <el-radio
          v-for="(test, index) in Tests"
          :key="index"
          v-model="newTest.test_id"
          :label="test.id"
          border
        >
          <b class="test-color">{{ test.name }}</b></el-radio
        >
      </el-form-item>
      <el-form-item label="Name" required prop="name">
        <el-input
          v-model="newTest.name"
          placeholder="Name"
          maxlength="100"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="Description" prop="description">
        <el-input
          type="textarea"
          :rows="3"
          v-model="newTest.description"
          placeholder="Description"
          maxlength="200"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="success" @click="createNew('newTest')"
          >Create Now</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import { instant } from "@ivy-way/material";
import Add from "@/views/tests/fullTests/apis/add.js";
import Common from "@/mixins/common.js";

export default {
  metaInfo() {
    return {
      title: `${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: { Breadcrumb },

  mixins: [Common],

  props: [],
  data() {
    return {
      Tests: [
        {
          id: 4,
          name: "SAT"
        },
        {
          id: 1,
          name: "ACT"
        }
      ],
      newTest: {
        test_id: 4,
        exam_type_id: 1,
        status: "draft",
        name: "",
        description: ""
      }
    };
  },
  computed: {
    isTeacher() {
      return this.$route.name === "TeacherSatAddPractice";
    },
    isAdmin() {
      return this.$route.name === "SatAddPractice";
    },
    instant() {
      return instant;
    },
    myTitle() {
      let title = "Create Full Tests";
      return title;
    }
  },
  watch: {},

  async mounted() {},

  methods: {
    async createNew(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const res = await Add.createFullTest(this.newTest);
          this.$message.success("Create Full Test Success");
          this.$router.push({
            name: "EducatorFullTestsList"
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style scoped>
.box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding-top: 60px;
}
.box .left-menu {
  width: 200px;
  height: 100%;
}
.box .right-content {
  background: #fafafa;
}
.right-content-detail {
  width: inherit;
  margin-left: 72px;
  margin-right: 72px;
  margin-top: 20px !important;
  padding-bottom: 20px;
}
.button-group {
  padding: 1rem 20px;
}
::v-deep .el-menu {
  border: none;
}
::v-deep .button-group .el-button {
  width: 100%;
  margin: 0.5rem 0;
}
.right-content {
  flex: 1;
  height: 100%;
  overflow: auto;
}
</style>

<template>
  <div>
    <div class="satMocks-score">
      <el-tooltip effect="dark" content="Total" placement="top">
        <b>
          <router-link
            :to="{
              name: resultRoute,
              params: { userExamId: userExam.id }
            }"
            target="_blank"
          >
            {{ Number(this.userExam.score) }}
            <span style="color: black;"></span>
          </router-link>
        </b>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  components: {},

  mixins: [],

  props: ["examId", "userExam", "role", "resultRoute"],
  data() {
    return {};
  },
  computed: {
    ...mapState("user", ["profile", "token"])
  },
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped>
.satMocks-score {
  font-size: 1.5rem;
}
.table td {
  padding: 5px;
  vertical-align: middle;
  text-align: center;
}
.inner-table {
  text-align: center;
  width: 100%;
  height: 50%;
  border: 1px #333;
}
table.inner-table tr th,
table.inner-table tr td {
  border: none;
}
.table td .M_score {
  border-left: 1px solid #e6e9ed;
}
.table-bordered th,
.table-bordered td {
  border-left: none;
}
.table td .T_score {
  border-top: 1px solid #e6e9ed;
}
.T_score b {
  font-size: 18px;
}
.R_score,
.G_score,
.E_score,
.M_score,
.M_score b,
.T_score b {
  color: #444;
}
.inner-table b {
  font-size: 18px;
}
.inner-table .T_score b {
  font-size: 20px;
}
.T_score {
  cursor: pointer;
}
.score-edit {
  display: none;
}
.T_score:hover .score-edit {
  cursor: pointer;
  display: inline-block;
}
</style>

import { render, staticRenderFns } from "./Student.vue?vue&type=template&id=2b2e8b10&scoped=true"
import script from "./Student.vue?vue&type=script&lang=js"
export * from "./Student.vue?vue&type=script&lang=js"
import style0 from "@/views/home/assets/css/Index.css?vue&type=style&index=0&id=2b2e8b10&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b2e8b10",
  null
  
)

export default component.exports
<template>
  <div>
    <el-form
      ref="form"
      v-if="!isEdit"
      @submit.native.prevent
      label-width="100px"
    >
      <!-- <el-form-item label="Subjects">
        <el-checkbox-group
          v-model="search.subjects"
          @input="subjects => setSearchToLink({ subjects })"
        >
          <el-checkbox-button
            :key="satSubject.id"
            v-for="satSubject in subjectsNow"
            :label="satSubject.id"
          >
            {{ titleCase(satSubject.name) }}
          </el-checkbox-button>
        </el-checkbox-group>
      </el-form-item> -->
      <el-form-item>
        <span slot="label">
          Test
        </span>
        <el-radio-group
          v-model="search.test"
          @input="
            test => setSearchToLink({ test, source: null, subject_id: null })
          "
        >
          <el-radio
            v-for="(test, index) in Tests"
            :key="index"
            :label="test.id"
            :value="test.id"
            border
          >
            <b class="test-color">{{ test.name }}</b>
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <span slot="label">
          Module
        </span>
        <el-radio-group
          v-model="search.subject_id"
          @input="subject_id => setSearchToLink({ subject_id })"
        >
          <el-radio :label="null" :value="null" border>
            <b>All</b>
          </el-radio>
          <el-radio
            v-for="(subject, index) in subjectsObj[search.test]"
            :key="index"
            :label="subject.id"
            :value="subject.id"
            border
          >
            <b>{{ subject.name }}</b>
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Source">
        <el-radio
          v-for="(source, index) in sources"
          :key="index"
          v-model="search.source"
          :label="source.value"
          @input="source => setSearchToLink({ source })"
          border
        >
          <b>{{ source.name }}</b></el-radio
        >
      </el-form-item>
      <el-form-item label="Difficulty" v-if="false">
        <el-checkbox-group v-model="difficulty">
          <el-checkbox-button
            :key="index"
            v-for="(difficulty, index) in DifficultyArr"
            :label="difficulty.key"
            :value="difficulty.key"
          >
            {{ titleCase(difficulty.name) }}
          </el-checkbox-button>
        </el-checkbox-group>
      </el-form-item>
      <!-- <el-form-item>
        <span slot="label">
          Skills
          <el-tooltip
            class="item"
            effect="dark"
            content="Edit Skills"
            placement="top"
            v-if="isAdmin"
          >
            <router-link class="ml-2" :to="{ name: 'QuestionTags' }">
              <el-button type="success" size="mini">
                <i class="fa fa-edit"></i>
              </el-button>
            </router-link>
          </el-tooltip>
        </span>
        <el-select
          v-model="search.tags"
          multiple
          filterable
          style="width:100%"
          @change="setSearchToLink({ tags: search.tags })"
        >
          <el-option
            v-for="tag in tags"
            :key="tag.id"
            :label="`[${tag.type}] - ${tag.name}`"
            :value="tag.id"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <el-input
          ref="filterInput"
          placeholder="keyword"
          v-model="search.search"
          @keyup.enter.native="setSearchToLink({ search: search.search })"
        >
          <el-button
            el-button
            slot="append"
            icon="el-icon-search"
            @click="setSearchToLink({ search: search.search })"
          >
            Search
          </el-button>
        </el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { helper } from "@ivy-way/material";
import SAT from "@/apis/sat.js";
import Common from "@/mixins/common.js";
import Company from "@/apis/company";

export default {
  metaInfo() {
    return {
      title: "Questions - " + this.CompanyName
    };
  },

  components: {},

  mixins: [Common],

  props: ["search", "isAdmin", "isCompany", "isTeacher"],
  data() {
    return {
      Tests: [
        {
          id: 4,
          name: "SAT"
        },
        {
          id: 1,
          name: "ACT"
        }
      ],
      subjectsObj: {
        1: [
          {
            id: 1,
            name: "English"
          },
          {
            id: 2,
            name: "Math"
          },
          {
            id: 3,
            name: "Reading"
          },
          {
            id: 4,
            name: "Science"
          }
        ],
        4: [
          {
            id: 13,
            name: "English"
          },
          {
            id: 14,
            name: "Math"
          }
        ]
      },
      subjects: [],
      tags: [],
      difficulty: []
    };
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    helper() {
      return helper;
    },
    id() {
      return this.$route.query.id;
    },
    isAdd() {
      return this.id;
    },
    sources() {
      if (this.search.test === 4) {
        return [
          {
            name: "All",
            value: null
          },
          {
            name: "XMocks",
            value: "xmocks"
          },
          {
            name: "Official",
            value: "official"
          },
          {
            name: "CB Khan",
            value: "cb"
          },
          {
            name: "Barron's",
            value: "barron"
          },
          {
            name: "Kaplan",
            value: "kaplan"
          },
          {
            name: "The Princeton Review",
            value: "princeton"
          }
        ];
      } else if (this.search.test === 1) {
        return [
          {
            name: "All",
            value: null
          },
          {
            name: "Official",
            value: "official"
          }
        ];
      } else {
        return [
          {
            name: "All",
            value: null
          }
        ];
      }
    },
    examTagTypes() {
      return [
        { name: "Gammar", key: "question_grammar" },
        { name: "Math", key: "question_math" },
        { name: "Reading", key: "question_reading" },
        { name: "Science", key: "question_science" }
      ];
    },
    DifficultyArr() {
      return [
        { name: "Beginner", key: "1" },
        { name: "Easy", key: "2" },
        { name: "Medium", key: "3" },
        { name: "Hard", key: "4" },
        { name: "Super Advanced", key: "5" },
        { name: "(Not Set)", key: "0" }
      ];
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    }
  },
  watch: {},

  async mounted() {
    this.MockType = this.$route.query.test;
    this.Source = this.$route.query.source;
    // const subjects = await SAT.getSubjects();
    // this.subjects = subjects.sat_subjects;
    // this.getTags();
    // this.getCompanies();
  },

  methods: {
    async getTags() {
      const res = await SAT.getSATQuestionTags();
      this.tags = res.sat_question_tags;
    },
    setSearchToLink(data) {
      this.$emit("setSearchToLink", data);
    }
  }
};
</script>

<style scoped>
.container {
  background-color: white;
  padding-top: 0;
  padding: 40px 40px 20px 40px;
  margin-top: 40px;
  margin-bottom: 40px;
}
::v-deep .el-tabs--border-card {
  box-shadow: none;
}
::v-deep .el-select__tags {
  /* height: 40px;
  line-height: 40px; */
  margin: auto 0;
}
.search {
  margin: 20px 0px;
}

.questions {
  margin: 20px 0px;
}

.leftQuestionCountTip {
  position: fixed;
  left: 0px;
  z-index: 99;
  background: var(--themeColor);
  color: #ffffff;
  border-radius: 0px 4px 4px 0px;
  height: 40px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.add-question-btn {
  position: fixed;
  left: 0px;
  z-index: 99;
  background: var(--themeColor);
  color: #ffffff;
  border-radius: 0px 4px 4px 0px;
  height: 40px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>

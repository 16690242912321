<template>
  <div class="container pt-3 pb-3">
    <div class="newQuestion">
      <div v-if="isEdit" class="mb-2">
        <el-page-header
          @back="$router.go(-1)"
          :content="myTitle"
        ></el-page-header>
      </div>
      <Breadcrumb v-else>
        <template slot="restPage">
          <el-breadcrumb-item>
            <router-link
              :to="{
                name: 'EducatorQuestionsList'
              }"
            >
              Questions
            </router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            {{ myTitle }}
          </el-breadcrumb-item>
        </template>
      </Breadcrumb>
      <el-alert
        v-if="isDuplicate"
        class="mb-3"
        type="warning"
        effect="dark"
        :closable="false"
        show-icon
      >
        <b>
          Duplicated! After you edit the question, click "Add" to add the
          question.
        </b>
      </el-alert>
      <div class="question-form-container">
        <Heading heading="h4" :content="myTitle"></Heading>
        <hr class="divider" />
        <SmartInput></SmartInput>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import SmartInput from "@/views/questions/add/components/SmartInput";
import Common from "@/mixins/common.js";

export default {
  metaInfo() {
    return {
      title: `${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: {
    Breadcrumb,
    SmartInput
  },

  mixins: [Common],

  props: [],
  data() {
    return {
      isNew: 1
    };
  },
  computed: {
    questionId() {
      return this.$route.params.questionId;
    },
    myTitle() {
      return this.isEdit
        ? "Edit Question"
        : this.isDuplicate
          ? "Duplicate Question"
          : "Create Question";
    },
    isEdit() {
      return (
        this.questionId &&
        this.questionId > 0 &&
        this.$route.name.indexOf("SatDuplicateQuestion") < 0
      );
    },
    isDuplicate() {
      return this.$route.name.indexOf("SatDuplicateQuestion") > -1;
    }
  },
  watch: {},

  async mounted() {},
  methods: {}
};
</script>

<style scoped>
.question-form-container {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
  padding: 20px;
  margin-top: 20px;
}

.divider {
  margin: 20px 0;
  border: none;
  border-top: 1px solid #ebeef5;
}

::v-deep .el-alert__description {
  font-size: 14px;
}
::v-deep .el-dialog__header {
  background: #efefef;
  padding-bottom: 20px;
}
::v-deep .el-dialog__footer {
  background: #efefef;
  padding-top: 20px;
}
::v-deep .add-passage-dialog .el-dialog__body {
  height: 600px;
  overflow: auto;
}
</style>

<template>
  <div class="act-box">
    <div class="wrapper">
      <div class="left-side-menu">
        <div
          class="slimScrollDiv mm-active"
          style="position: relative; overflow: hidden; width: auto; height: 1129px;"
        >
          <div
            class="slimscroll-menu mm-show"
            style="overflow: hidden; width: auto; height: 1129px;"
          >
            <!--- Sidemenu -->
            <div id="sidebar-menu" class="mm-active">
              <ul class="metismenu mm-show" id="side-menu">
                <li>
                  <router-link
                    :to="{
                      name: 'SATList'
                    }"
                    :class="$route.name === 'SATList' ? 'active' : ''"
                    ><i class="dripicons-pencil"></i>
                    <span> Take SAT Tests </span></router-link
                  >
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'SATHistory'
                    }"
                    :class="$route.name === 'SATHistory' ? 'active' : ''"
                    ><i class="dripicons-pamphlet"></i>
                    <span> SAT Exam History </span></router-link
                  >
                </li>
              </ul>
            </div>
            <div class="clearfix"></div>
          </div>
          <div
            class="slimScrollBar"
            style="background: rgb(158, 165, 171); width: 8px; position: absolute; top: 0px; opacity: 0.4; display: none; border-radius: 7px; z-index: 99; right: 1px; height: 1129px;"
          ></div>
          <div
            class="slimScrollRail"
            style="width: 8px; height: 100%; position: absolute; top: 0px; display: none; border-radius: 7px; background: rgb(51, 51, 51); opacity: 0.2; z-index: 90; right: 1px;"
          ></div>
        </div>
      </div>
      <div
        class="content-page"
        style="height: auto !important; min-height: 0px !important;"
      >
        <div class="phone-menu">
          <el-menu
            :default-active="$route.name"
            class="el-menu-demo"
            mode="horizontal"
            :router="true"
            @select="handleSelect"
          >
            <el-menu-item
              index="SATList"
              :route="{
                name: 'SATList'
              }"
            >
              <span class="text-success">Take SAT Tests</span></el-menu-item
            >
            <el-menu-item
              index="SATHistory"
              :route="{
                name: 'SATHistory'
              }"
            >
              <span class="text-success">SAT Exam History</span></el-menu-item
            >
          </el-menu>
        </div>
        <div class="container-fluid" style="height: auto !important;">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  metaInfo() {},

  components: {},

  mixins: [],

  props: [],
  data() {
    return {};
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
.act-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 14px;
}

.td-section a {
  margin-right: 15px;
}
.table th {
  color: white !important;
}
</style>
<style scoped src="@/views/act/assets/css/act.css"></style>
<style scoped src="@/views/act/assets/css/simplebar.css"></style>
<style scoped src="@/views/act/assets/css/app.min.css"></style>

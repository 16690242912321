<template>
  <div class="container">
    <div class="wrapper card__wrapper">
      <div class="card__content">
        <h1 class="subheader">
          Contact Us
        </h1>
        <h2
          class="section-v2__header section-v2__header_v2 section-v2__header_main"
        >
          We Want to Hear from You!
        </h2>
        <div class="after">&nbsp;</div>
        <div class="section-v2__text">
          <div>
            <el-form
              label-position="top"
              ref="form"
              :model="form"
              :rules="formRules"
              label-width="80px"
            >
              <el-form-item label="Name" prop="name" required>
                <el-input v-model="form.name" placeholder="Name"></el-input>
              </el-form-item>
              <el-form-item label="Email" prop="email" required>
                <el-input v-model="form.email" placeholder="Email"></el-input>
              </el-form-item>
              <el-form-item prop="phone" :error="errors.phone">
                <label class="m-0">
                  Phone
                </label>
                <vue-tel-input
                  v-model="form.phone"
                  @input="onInput"
                ></vue-tel-input>
              </el-form-item>
              <el-form-item label="Message" prop="message" required>
                <el-input
                  v-model="form.message"
                  type="textarea"
                  :rows="3"
                  placeholder="Message"
                  maxlength="1000"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item prop="g-recaptcha-response" required>
                <div class="mt-3 mb-3">
                  <GRecaptcha
                    ref="recaptcha"
                    @getValidateCode="getValidateCode"
                  ></GRecaptcha>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  style="width: 100%;"
                  @click="contactUs"
                  >Submit</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="card__picture">
        <div class="right-title">
          <h4 class="text-success">Your Success is</h4>
          <h4>Our Priority</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import GRecaptcha from "@/components/GRecaptcha";
import formMixin from "@/mixins/form";
import Contact from "@/views/landingPage/apis/contact";

export default {
  metaInfo() {
    return {
      title: "Contact Us - " + this.CompanyName
    };
  },

  components: { GRecaptcha },

  mixins: [formMixin],

  props: [],
  data() {
    return {
      disabled: true,
      form: {
        name: "",
        email: "",
        Message: "",
        phoneCode: "",
        phone: "",
        "g-recaptcha-response": null
      },
      errors: {
        phone: ""
      }
    };
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    formRules() {
      return {
        name: [
          {
            required: true,
            message: "Name is required",
            trigger: "blur"
          }
        ],
        email: [
          {
            required: true,
            message: "Email is required",
            trigger: "blur"
          },
          {
            type: "email",
            message: "please valid email",
            trigger: "blur"
          }
        ],
        message: [
          {
            required: true,
            message: "Message is required",
            trigger: "blur"
          }
        ],
        phone: [
          {
            required: false,
            message: "phone",
            trigger: "blur"
          }
        ],
        "g-recaptcha-response": [
          {
            required: true,
            message: "Captcha is required",
            trigger: "blur"
          }
        ]
      };
    }
  },
  watch: {},

  mounted() {},

  methods: {
    onInput(phone, phoneObject) {
      console.log(phoneObject);
      this.form.phoneCode = phoneObject.country.dialCode;
    },
    async contactUs() {
      this.errors.phone = "";
      const valid = await this.validateForm(this.$refs["form"]);
      if (!valid) {
        return;
      }
      try {
        await Contact.contactUs({
          ...this.form,
          phone: "+" + this.form.phoneCode + this.form.phone
        });
        this.$message({
          message: "Success!",
          type: "success"
        });
        this.$nextTick(() => {
          this.$refs["form"].resetFields();
        });
      } catch (error) {
        let errors = error.data.errors;
        for (let index in errors) {
          if (index === "phone") {
            this.errors[index] = "Incorrect phone number format, please check.";
          } else {
            this.errors[index] = errors[index][0];
          }
        }
        return error;
      }
    },
    getValidateCode(token) {
      // this.t = value;
      console.log("token:" + token);
      this.form["g-recaptcha-response"] = token;
    }
  }
};
</script>

<style scoped>
.card__picture {
  position: relative;
  padding: 2rem;
}
.right-title {
  font-size: 5rem;
  font-weight: 700;
  background-color: white;
  border-radius: 1rem;
  opacity: 0.8rem;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  border: 1px solid #ebeef5;
  background-color: #fff;
  padding: 2rem 4rem;
}
.right-title h4 {
  margin: 0;
  font-size: 6rem;
  line-height: 8rem;
}
.card__picture:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: url("https://clickup.com/images/collaboration-detection/bg__with-dotted.svg")
    no-repeat top left / cover;
}

.card__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 10px 0;
  /* padding-top: 1rem;
  padding-bottom: 1rem; */
}
.card.card_picture-right .card__content {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  padding: 0;
}
.card__content {
  width: 38%;
}
.subheader {
  color: #7b68ee;
  font: 800 11px / 12px Axiforma, Arial, serif;
  text-align: left;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0;
  margin: 20px 0 15px;
}
.section-v2__header {
  position: relative;
  font: 800 3.5rem / 5rem Axiforma, Arial, serif;
  margin: 20px 0 15px 0;
  margin-bottom: 0;
}
.card__wrapper_v2 .card__content > * {
  text-align: left;
}

.section-v2__text {
  color: #7c828d;
  font: 400 14px / 25px, Arial, serif;
  margin: 0 auto;
}
.after {
  content: "";
  display: block;
  position: absolute;
  width: 100px;
  height: 5px;
  border-radius: 7px;
  position: static;
  margin-top: 30px;
  background: linear-gradient(90deg, #9f8fff 100%, #7b68ee 0);
  margin-bottom: 50px;
}

*,
::after,
::before {
  box-sizing: inherit;
}
::v-deep .el-form-item__label {
  padding: 0;
  margin: 0;
}
@media screen and (max-width: 768px) {
  .after {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .section-v2__header {
    line-height: 1.2;
  }
  .card__wrapper {
    display: block;
    padding: 1rem;
  }
  .card__content {
    width: 100%;
  }
  .section-v2__text {
    width: 100%;
  }
  .right-title {
    padding: 2rem;
  }
  .right-title h4 {
    margin: 0;
    font-size: 3rem;
    line-height: 4rem;
  }
}
::v-deep .vue-tel-input {
  background-color: white;
  color: #606266;
  color: black;
  height: 40px;
  border: 1px solid #dcdfe6;
}
::v-deep .vue-tel-input ::placeholder {
  color: #c0c4cc;
}
::v-deep .vue-tel-input:focus-within {
  border-color: var(--themeColor);
  box-shadow: none;
}
</style>

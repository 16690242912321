<template>
  <div class="box">
    <div class="flashcardHeader d-flex">
      <div class="pl-3 return">
        <div @click="$router.back(-1)" style="color: var(--themeColor); cursor: pointer">
          <i class="fas fa-chevron-left"></i>
        </div>
      </div>
      <div class="headerTitle">
        <span class="pr-4">Flashcards</span>
      </div>
    </div>
    <div class="container content" style="width: 100%;">
      <el-divider content-position="left"
        ><i class="fas fa-book book"></i> Flashcard</el-divider
      >
      <el-select v-model="book" style="width:100%" @change="changeBook">
        <el-option
          v-for="(book, index) in getCurrentBooks"
          :key="index"
          :label="book.name"
          :value="index + ''"
        >
        </el-option>
      </el-select>
      <!-- <h3 class="bookName">{{ selectedName }}</h3> -->
      <template v-if="false">
        <el-divider content-position="left"
          ><i class="fas fa-book book"></i> {{$t("flashCards.Type")}}</el-divider
        >
        <div class="row" style="margin:0">
          <div class="col-6 content-item">
            <div
              :class="startType === 2 ? 'list list-select' : 'list'"
              @click="changeType(2)"
            >
              <div class="list-title">{{$t("flashCards.Flash Cards")}}</div>
              <div style="font-size:28px">
                <i class="fas fa-copy"></i>
              </div>
            </div>
          </div>
          <div class="col-6 content-item">
            <div
              :class="startType === 1 ? 'list list-select' : 'list'"
              @click="changeType(1)"
            >
              <div class="list-title">{{$t("flashCards.Word List")}}</div>
              <div style="font-size:28px">
                <i class="fas fa-list"></i>
              </div>
            </div>
          </div>
        </div>
      </template>
      <el-divider content-position="left"
        ><i class="fas fa-list"></i> {{$t("flashCards.Select List(s)")}}</el-divider
      >
      <div class="">
        <div>
          <el-row>
            <el-col
              :span="6"
              v-for="(elem, index) in currentVocabList"
              :key="index"
              class="wordCard"
            >
              <!-- 動態新增 class name -->
              <div
                class="selectBlock"
                :class="`block${elem.id}`"
                @click="toggleSelect(elem.id)"
              >
                {{ elem.name }}
              </div>
            </el-col>
          </el-row>
        </div>
        <el-divider>{{$t("flashCards.or")}}</el-divider>
        <div style="padding: 0 8px">
          <div style="font-size: 20px;" class="pb-2">{{$t("flashCards.Enter range")}}:</div>
          <el-input v-model="start" placeholder="start" class="pb-3"></el-input>
          <el-input v-model="end" placeholder="end" class="pb-3"></el-input>
        </div>
      </div>
    </div>
    <div class="levelButtons pointer">
      <div class="d-flex flex-buttons" @click="goToNextPage">
        <div style="width: 100%; color: var(--themeColor);" class="btnStyle">
          <div style="background-color: var(--themeColor);" class="btnBar"></div>
          <div><i class="fas fa-play-circle"></i></div>
          <div class="btnClick">START</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import _ from "lodash";
import Flashcards from "@/apis/flashcards";

export default {
  metaInfo() {
    return {
      title: this.title + " - " + this.CompanyName
    };
  },
  data() {
    return {
      startType: 2,
      selectedName: "",
      selectedList: [],
      currentVocabList: [],
      start: null,
      end: null,
      book: null
    };
  },
  computed: {
    ...mapGetters("flashcards", ["getCurrentBooks"]),
    title() {
      return "Flashcards";
    }
  },
  async created() {
    await this.$store.dispatch("flashcards/getBooks");

    let defaultBook = this.getCurrentBooks[0];
    if (this.$route.query.index != undefined) {
      defaultBook = this.getCurrentBooks[this.$route.query.index];
      this.book = String(this.$route.query.index);
    } else {
      defaultBook = this.getCurrentBooks["0"];
      this.book ="0";
    }
    const vocab_list = await Flashcards.getBooksLists(defaultBook.id);
    this.currentVocabList = vocab_list.word_lists;
    // Object.keys(this.currentVocabList).forEach(val => {
    //   this.currentVocabList[val]["new_order"] = parseInt(
    //     this.currentVocabList[val]["title"]
    //   );
    // });
    this.currentVocabList = _.orderBy(
      this.currentVocabList,
      ["order"],
      ["asc"]
    );
    this.selectedName = defaultBook.name;
    this.$nextTick(() => {
      // this.toggleSelect(this.currentVocabList[0].id);
    });
  },
  mounted() {
    const main = document.getElementsByClassName("el-main");
    main[0].style.padding = "0";
  },
  beforeDestroy() {
    if (window.innerWidth > 992) {
      // 離開此頁面後將 padding 恢復原狀
      const main = document.getElementsByClassName("el-main");
      main[0].style.padding = "100px 0px 0px 0px";
    } else {
      const main = document.getElementsByClassName("el-main");
      main[0].style.padding = "60px 0px 0px 0px";
    }
  },
  methods: {
    changeBook() {
      this.$router.push({
        name: "FlashCards",
        query: {
          index: this.book
        }
      });
    },
    changeType(type) {
      this.startType = type;
    },
    onChange(val) {
      // 先將之前選取的顏色取消
      this.selectedList.forEach(id => {
        const currentClassName = "block" + id.toString();
        const block = document.getElementsByClassName(currentClassName);
        block[0].style.backgroundColor = "transparent";
      });
      // 再將 selectedList 清空
      this.selectedList = [];

      const currentBook = this.getCurrentBooks.find(
        x => x.title === this.selectedName
      );
      this.currentVocabList = currentBook.vocab_list;
      this.start = null;
      this.end = null;
    },

    // 當點選任一個數字 block 呼叫此 method，並傳入目前選擇 id
    toggleSelect(id) {
      // 獲取目前 block class name
      const currentClassName = "block" + id.toString();
      let ele = document.getElementsByClassName(currentClassName)[0];
      // 如果已經選擇此 block，則將他從 array 移除，並將背景設為透明
      if (this.selectedList.includes(id)) {
        this.selectedList = this.selectedList.filter(e => e !== id);
        ele.style.backgroundColor = "#efefef";
        ele.style.color = "black";
        ele.style.border = "1px dashed #ccc";
      } else {
        // 如果還沒選擇此 block，則將他放入 array，並將背景設為淺綠色
        this.selectedList.push(id);
        ele.style.backgroundColor = this.themeColor;
        ele.style.color = "white";
        ele.style.border = "1px solid " + this.themeColor;
      }
    },

    async goToNextPage() {
      // 檢查使用者是否有選擇任一 list

      if (this.selectedList.length === 0) {
        if (
          this.start !== null &&
          this.end !== null &&
          this.start > 0 &&
          this.end <= this.currentVocabList.length &&
          this.start <= this.end
        ) {
          this.selectedList = [];
          for (let i = this.start - 1; i < this.end; i++) {
            this.selectedList.push(this.currentVocabList[i].id);
          }
          this.$router.push({
            name: "MemorizeList",
            params: {
              title: this.selectedName
            },
            query: {
              selectedList: this.selectedList.join(",")
            }
          });
        } else {
          this.$message({
            message: "Please enter a valid range!",
            type: "error"
          });
        }
      } else {
        if (this.startType === 2) {
          this.$router.push({
            name: "MemorizeList",
            params: {
              title: this.selectedName
            },
            query: {
              selectedList: this.selectedList.join(",")
            }
          });
        } else {
          this.$router.push({
            name: "FlashCardsList",
            params: {
              title: this.selectedName
            },
            query: {
              selectedList: this.selectedList.join(",")
            }
          });
        }
        // this.$router.push({
        //   name: "MemorizeList",
        //   params: {
        //     title: this.selectedName
        //   }
        // });
      }
    },
    // 同等於 hover 的效果，滑鼠移到 block 上則將背景設為淺綠色
    handleMouseover(id) {
      const currentClassName = "block" + id.toString();
      const block = document.getElementsByClassName(currentClassName);
      block[0].style.backgroundColor = "#efefef";
    },
    // 滑鼠從 block 移開，如果是已經選取則顏色不變，如果尚未選取則將背景變為透明
    handleMousleave(id) {
      if (!this.selectedList.includes(id)) {
        const currentClassName = "block" + id.toString();
        const block = document.getElementsByClassName(currentClassName);
        block[0].style.backgroundColor = "#efefef";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bookName {
  padding: 8px;
  font-weight: 500;
}
.box {
  height: 100vh;
  display: grid;
  grid-template-rows: 55px auto 83px;
}
.content {
  overflow: auto;
  padding: 0 8px;
}
.el-button {
  outline: none;
}
.flashcardHeader {
  height: 55px;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  color: black;
  font-size: 28px;
}

.return:hover {
  opacity: 60%;
}

.headerTitle {
  text-align: center;
  width: 100%;
  font-weight: 600;
}
.wordCard {
  margin-bottom: 15px;
  padding-left: 8px;
  padding-right: 8px;
}

.selectBlock {
  border-radius: 1px;
  text-align: center;
  cursor: pointer;
  height: 65px;
  line-height: 65px;
  text-align: center;
  border: 1px dashed #ccc;
  border-radius: 6px;
  background: #efefef;
  font-size: 18px;
  color: #212529;
  font-weight: 500;
}


.levelButtons {
  position: fixed;
  background-color: white;
  width: 100%;
  bottom: 0;
  cursor: pointer;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

.flex-buttons {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.btnBar {
  height: 10px;
}

.btnStyle {
  font-size: 26px;
  border: 1px solid #e0e0e0;
  padding-bottom: 12px;
  font-weight: 600;
}
.btnStyle:hover {
  opacity: 0.5;
}

.btnClick {
  font-size: 18px;
  line-height: 20px;
  padding-top: 0;
  cursor: pointer;
}
.list {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  font-weight: 500;
}
.list-select {
  color: #fff;
  background: var(--themeColor);
  border: 1px solid var(--themeColor);
}
.content-item {
  margin-bottom: 15px;
  padding: 0 8px;
}
::v-deep .el-divider {
  margin-bottom: 32px;
}
::v-deep .el-divider__text.is-left {
  left: 0;
}
::v-deep .el-divider__text {
  font-size: 20px;
  padding-left: 8px;
  color: var(--themeColor);
}
</style>

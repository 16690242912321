<template>
  <div>
    <el-tabs class="mt-4" type="border-card">
      <el-tab-pane :label="$t('AIQuestions.Computer Preview')">
        <span slot="label"
          ><i class="fas fa-laptop"></i>
          {{ $t("AIQuestions.Computer Preview") }}</span
        >
        <div class="tab-pc" ref="tab-pc">
          <template v-if="question && question.type === 'single'">
            <MultipleChoiceWithPassage
              v-if="
                passage !== null &&
                  passage.content !== null &&
                  passage.content !== ''
              "
              :passageIntro="
                passage &&
                passage.introduction &&
                passage.introduction.length > 0
                  ? passage.introduction[0]
                  : null
              "
              :passageContent="passage.content"
              :content="question.content"
              :options="getOptions(question.options)"
              :explanation="
                question.explanations.length > 0
                  ? question.explanations[0].explanation
                  : '-'
              "
              :correctAnswer="getCAnswers(question.answers)"
              :showMark="false"
              mode="explanation"
            ></MultipleChoiceWithPassage>
            <MultipleChoice
              v-else
              :content="question.content"
              :options="getOptions(question.options)"
              :explanation="
                question.explanations.length > 0
                  ? question.explanations[0].explanation
                  : '-'
              "
              :correctAnswer="getCAnswers(question.answers)"
              :showMark="false"
              mode="explanation"
            ></MultipleChoice>
          </template>
          <Math
            v-if="question && question.type === 'math'"
            mode="explanation"
            :content="question.content"
            :options="getOptions(question.options)"
            :explanation="
              question.explanations.length > 0
                ? question.explanations[0].explanation
                : '-'
            "
            :correctAnswer="getCAnswers(question.answers)"
            :showMark="false"
          ></Math>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="$t('AIQuestions.Mobile Preview')">
        <span slot="label"
          ><i class="fas fa-mobile-alt"></i>
          {{ $t("AIQuestions.Mobile Preview") }}</span
        >
        <div class="tab-phone-box" ref="tab-phone-box">
          <div class="tab-phone">
            <template v-if="question && question.type === 'single'">
              <MultipleChoiceWithPassage_M
                v-if="
                  passage !== null &&
                    passage.content !== null &&
                    passage.content !== ''
                "
                :passageIntro="
                  passage &&
                  passage.introduction &&
                  passage.introduction.length > 0
                    ? passage.introduction[0]
                    : null
                "
                :passageContent="passage.content"
                :content="question.content"
                :options="getOptions(question.options)"
                :explanation="
                  question.explanations.length > 0
                    ? question.explanations[0].explanation
                    : '-'
                "
                :correctAnswer="getCAnswers(question.answers)"
                :showMark="false"
                mode="explanation"
              ></MultipleChoiceWithPassage_M>
              <MultipleChoice_M
                v-else
                :content="question.content"
                :options="getOptions(question.options)"
                :explanation="
                  question.explanations.length > 0
                    ? question.explanations[0].explanation
                    : '-'
                "
                :correctAnswer="getCAnswers(question.answers)"
                :showMark="false"
                mode="explanation"
              ></MultipleChoice_M>
            </template>
            <Math_M
              v-if="question && question.type === 'math'"
              mode="explanation"
              :content="question.content"
              :options="getOptions(question.options)"
              :explanation="
                question.explanations.length > 0
                  ? question.explanations[0].explanation
                  : '-'
              "
              :correctAnswer="getCAnswers(question.answers)"
              :showMark="false"
            ></Math_M>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  components: {},

  mixins: [],

  props: ["showQuestion", "showPassage"],
  data() {
    return {
      showPhone: false,
      showPC: false
    };
  },
  computed: {
    question() {
      return JSON.parse(JSON.stringify(this.showQuestion));
    },
    passage() {
      return JSON.parse(JSON.stringify(this.showPassage));
    }
  },
  watch: {
    question: {
      deep: true,
      handler() {
        this.initMathJaxPlugin();
      }
    }
  },

  mounted() {
    this.initMathJaxPlugin();
  },

  methods: {
    getOptions(options) {
      options.forEach(option => {
        option["letter"] = option["label"];
        option["title"] = option["content"];
      });
      return options;
    },
    getCAnswers(CAnswers) {
      console.log(CAnswers);
      let CAList = [];
      if (CAnswers) {
        CAnswers.forEach(CA => {
          if (CA.answer[0]) {
            CAList.push(CA.answer[0]);
          }
        });
      }
      console.log(CAList);
      return CAList.length > 0 ? CAList[0] : null;
    },
    initMathJaxPlugin() {
      this.$mathJax.loadMathJaxScript(() => {
        this.$mathJax.MathQueue([this.$refs["tab-phone"]]);
        this.$mathJax.MathQueue([this.$refs["tab-pc"]]);
      });
    }
  }
};
</script>

<style scoped>
.show-phone {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.5;
  background: #000;
  z-index: 999;
}
.closed {
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  z-index: 1000;
  position: fixed;
  top: 80px;
  right: 40px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  color: #fff;
  background-color: #606266;
}
.phone {
  position: fixed;
  width: 390px;
  height: 884px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 80px auto 0;
  z-index: 1000;
  background-color: white;
  overflow: auto;
  padding-top: 1rem;
  border-radius: 1rem;
  padding-bottom: 1rem;
}
.show-pc {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.5;
  background: #000;
  z-index: 999;
}
.pc {
  position: fixed;
  width: 1280px;
  height: 720px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 80px auto 0;
  z-index: 1000;
  background-color: white;
  overflow: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.tab-phone-box {
  /* opacity: 0.5; */
  background: #000;
  padding: 1rem 0;
}
.tab-phone {
  width: 390px;
  height: 884px;
  margin: 0 auto;
  background-color: white;
  overflow: auto;
  padding-top: 1rem;
  border-radius: 1rem;
  padding-bottom: 1rem;
}
</style>

<template>
  <div class="box">
    <div class="container">
      <Breadcrumb>
        <template slot="restPage">
          <!-- <el-breadcrumb-item>
            <router-link :to="{ name: 'MyClasses' }">
              {{ $t("TeacherCourses.My Classes") }}
            </router-link>
          </el-breadcrumb-item> -->
          <el-breadcrumb-item>
            <router-link :to="{ name: 'StudentClassesList' }">
              My Classes
            </router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            {{ myTitle }}
          </el-breadcrumb-item>
        </template>
      </Breadcrumb>
      <Heading :content="myTitle"></Heading>
      <hr />
      <template v-if="isInClass">
        <el-alert
          class="mb-4"
          type="info"
          show-icon
          :closable="false"
          style="text-align:left"
        >
          <span>
            Already joined this class,
          </span>
          <router-link :to="{ name: 'StudentClassesList' }">
            <b>view your all classes</b>
          </router-link>
          .
        </el-alert>
      </template>
      <div class="form-box">
        <el-form v-if="!course" label-width="140px" @submit.native.prevent>
          <el-form-item :label="$t('TeacherCourses.Class Code')" required>
            <el-input
              :placeholder="$t('TeacherCourses.Class Code')"
              type="text"
              v-model="code"
              @keyup.enter.native="viewClass"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="success" :disabled="!code" @click="viewClass">
              <i class="fa fa-eye"></i>
              View Class
            </el-button>
          </el-form-item>
        </el-form>
        <el-form v-if="course" label-width="140px">
          <el-form-item
            v-if="course.users && course.users.owner"
            :label="$t('TeacherCourses.Teacher')"
            prop="title"
          >
            <b
              class="mr-1"
              v-for="(teacher, index) in course.users.owner"
              :key="teacher.id"
            >
              <router-link
                v-if="isAdmin"
                :to="{ name: 'AdminViewUser', params: { id: teacher.id } }"
              >
                {{ user.displayName(teacher.first_name, teacher.last_name) }}
              </router-link>
              <template v-else>
                <span class="text-success">
                  {{ user.displayName(teacher.first_name, teacher.last_name) }}
                </span>
              </template>
              <span v-if="index < course.users.length - 1">
                ,
              </span>
            </b>
          </el-form-item>
          <el-form-item :label="$t('TeacherCourses.Class Name')" prop="title">
            <b class="text-success">
              {{ course.name }}
            </b>
          </el-form-item>
          <el-form-item
            :label="$t('TeacherCourses.Class Description')"
            prop="title"
          >
            <span class="text-success">
              <b v-if="course.description">{{ course.description }}</b>
              <b v-else>-</b>
            </span>
          </el-form-item>
          <!-- <el-form-item :label="$t('TeacherCourses.Method')" prop="type">
            <b class="text-success" v-if="course.is_online">
              <i class="fa fa-globe"></i>
              {{ $t("TeacherCourses.Online") }}
            </b>
            <b class="text-success" v-else>
              <i aria-hidden="true" class="fa fa-building"></i>
              {{ $t("TeacherCourses.In-Person") }}
            </b>
          </el-form-item> -->
          <el-form-item :label="$t('TeacherCourses.Class Dates')" prop="date">
            <b class="text-success">
              <template v-if="course.started_at">
                <span v-if="course.started_at">{{
                  instant.formatGoogleTime(course.started_at)
                }}</span>
                <span v-if="course.ended_at">
                  ~
                  {{ instant.formatGoogleTime(course.ended_at) }}
                </span>
              </template>
              <template v-else>
                -
              </template>
            </b>
          </el-form-item>
          <el-form-item :label="$t('TeacherCourses.Class Time')" prop="time">
            <b class="text-success">
              <template v-if="course.started_time">
                <span>
                  {{ moment(course.started_time, "HH:mm").format("A hh:mm") }}
                </span>
                <span v-if="course.ended_time">
                  ~
                  {{ moment(course.ended_time, "HH:mm").format("A hh:mm") }}
                </span>
              </template>
              <template v-else>
                -
              </template>
            </b>
          </el-form-item>
          <el-form-item>
            <template v-if="!isInClass">
              <el-button
                type="success"
                :disabled="!code"
                @click="studentJoinClass"
              >
                {{ $t("TeacherCourses.Join Now") }}
              </el-button>
            </template>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { instant, user } from "@ivy-way/material";
import Breadcrumb from "@/components/Breadcrumb";
import Join from "@/views/courses/apis/Join.js";
import moment from "moment";

export default {
  metaInfo() {
    return {
      title: `${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: { Breadcrumb },

  mixins: [],

  props: [],
  data() {
    return {
      code: null,
      course: null
    };
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    instant() {
      return instant;
    },
    user() {
      return user;
    },
    moment() {
      return moment;
    },
    myTitle() {
      return this.$t("TeacherCourses.Join a class");
    },
    isInClass() {
      let inClass = false;
      if (this.course && this.course.users && this.course.users.member) {
        this.course.users.member.forEach(user => {
          if (this.getProfile.id === user.id) {
            inClass = true;
          }
        });
      }
      return inClass;
    }
  },
  watch: {},

  mounted() {
    if (this.$route.query.code) {
      this.code = this.$route.query.code;
      this.getClassBySlug();
    } else {
      // this.getClassBySlug();
      this.code = null;
    }
  },

  methods: {
    async getClassBySlug() {
      const res = await Join.getClassBySlug(this.code);
      this.course = res;
    },
    async studentJoinClass() {
      const res = await Join.studentJoinClass(this.course.id);
      await this.$message({
        message: this.$t("Success!"),
        type: "success"
      });
      this.$router.push({ name: "StudentClasses" });
    },
    viewClass() {
      // this.$router.replace({
      //   name: "JoinCourse",
      //   query: {
      //     code: this.code
      //   }
      // });
      if (this.code) {
        this.getClassBySlug();
      }
    }
  }
};
</script>

<style scoped>
.form-box {
  max-width: 550px;
  /* margin: 0 auto; */
}
.box .container {
  padding-top: 1rem;
}
.referral-code span {
  display: inline-block;
  height: 3rem;
  line-height: 3rem;
  padding: 0 1rem;
  font-size: 2rem;
  background: rgb(241, 243, 244);
  border-radius: 4px;
}
</style>

<template>
  <div>
    <!-- Your Total Score -->
    <div class="row total">
      <div
        v-if="
          practiceType === 'composePractices' ||
            practiceType === 'composePractice'
        "
        class="col-md-6 col-sm-12 total_score print_div"
      >
        <h4 class="title">
          Your Total Score
        </h4>
        <div class="total_above row">
          <p class="col-xs-8 score">
            {{ total }}
          </p>
          <p class="col-xs-4 t_score">
            400 to 1600
          </p>
        </div>
      </div>
      <div v-else class="col-md-6 col-sm-12 total_score print_div">
        <h4 class="title">
          Your Prediction Score
        </h4>
        <div class="total_above row">
          <p class="col-xs-8 score">
            {{ total }}
          </p>
          <p class="col-xs-4 t_score">
            200 to 800
          </p>
        </div>
      </div>
    </div>
    <!-- Section Scores -->
    <template
      v-if="
        practiceType === 'composePractices' ||
          practiceType === 'composePractice'
      "
    >
      <template v-if="isNew">
        <el-divider></el-divider>
        <div class="total section" v-if="practiceType === 'composePractices'">
          <h4 class="title">
            Section Scores
          </h4>
          <div class="row">
            <div class="col-md-6 col-sm-12 total_score print_div">
              <div class="total_above row">
                <p class="col-xs-8 score">
                  {{ score.english_score }}
                </p>
                <p class="col-xs-4 t_score">200 to 800</p>
              </div>
              <h6>Your English Score</h6>
            </div>
            <div class="col-md-6 col-sm-12 total_score print_div">
              <div class="total_above row">
                <p class="col-xs-8 score">
                  {{ score.math_score }}
                  <small class="differt-score">
                    <span
                      v-if="progress_section_math_score > 0"
                      class="text-success"
                    >
                      <i class="fas fa-caret-up"></i>
                      {{ Math.abs(progress_section_math_score) }}
                    </span>
                    <span
                      v-if="progress_section_math_score < 0"
                      class="text-danger"
                    >
                      <i class="fas fa-caret-down"></i>
                      {{ Math.abs(progress_section_math_score) }}
                    </span>
                  </small>
                </p>
                <p class="col-xs-4 t_score">200 to 800</p>
              </div>
              <h6>Your Math Score</h6>
            </div>
          </div>
        </div>
      </template>
      <template v-if="!isNew">
        <!-- Test Scores -->
        <el-divider></el-divider>
        <div class="total section">
          <h4 class="title">
            Section Scores
          </h4>
          <div class="row">
            <div class="col-md-6 col-sm-12 total_score print_div">
              <div class="total_above row">
                <p class="col-xs-8 score">
                  {{ score.english_score }}
                </p>
                <p class="col-xs-4 t_score">200 to 800</p>
              </div>
              <h6>Your Evidence-Based Reading and Writing Score</h6>
            </div>
            <div class="col-md-6 col-sm-12 total_score print_div">
              <div class="total_above row">
                <p class="col-xs-8 score">
                  {{ score.math_score }}
                </p>
                <p class="col-xs-4 t_score">200 to 800</p>
              </div>
              <h6>Your Math Score</h6>
            </div>
          </div>
        </div>
        <div class="total scores">
          <h4 class="title">
            Test Scores
          </h4>
          <div class="row">
            <div class="col-md-4">
              <div class="total_above row">
                <p class="col-xs-8 score">
                  {{ score.reading_score }}
                  <small class="differt-score">
                    <span
                      v-if="progress_reading_score > 0"
                      class="text-success"
                    >
                      <i class="fas fa-caret-up"></i>
                      {{ Math.abs(progress_reading_score) }}
                    </span>
                    <span v-if="progress_reading_score < 0" class="text-danger">
                      <i class="fas fa-caret-down"></i>
                      {{ Math.abs(progress_reading_score) }}
                    </span>
                  </small>
                </p>
                <p class="col-xs-4 t_score">10 to 40</p>
              </div>
              <h6>Reading</h6>
            </div>
            <div class="col-md-4">
              <div class="total_above row">
                <p class="col-xs-8 score">
                  {{ score.writing_score }}
                  <small class="differt-score">
                    <span
                      v-if="progress_writing_score > 0"
                      class="text-success"
                    >
                      <i class="fas fa-caret-up"></i>
                      {{ Math.abs(progress_writing_score) }}
                    </span>
                    <span v-if="progress_writing_score < 0" class="text-danger">
                      <i class="fas fa-caret-down"></i>
                      {{ Math.abs(progress_writing_score) }}
                    </span>
                  </small>
                </p>
                <p class="col-xs-4 t_score">10 to 40</p>
              </div>
              <h6>Writing and Language</h6>
            </div>
            <div class="col-md-4">
              <div class="total_above row">
                <p class="col-xs-8 score">
                  {{ score.math_score }}
                  <small class="differt-score">
                    <span v-if="progress_math_score > 0" class="text-success">
                      <i class="fas fa-caret-up"></i>
                      {{ Math.abs(progress_math_score) }}
                    </span>
                    <span v-if="progress_math_score < 0" class="text-danger">
                      <i class="fas fa-caret-down"></i>
                      {{ Math.abs(progress_math_score) }}
                    </span>
                  </small>
                </p>
                <p class="col-xs-4 t_score">10 to 40</p>
              </div>
              <h6>Math</h6>
            </div>
          </div>
        </div>
        <div class="row total">
          <div class="col-md-6 col-sm-12 total_score print_div">
            <h4 class="title">
              Prediction Scores
            </h4>
            <div class="total_above row">
              <p class="col-xs-8 score">
                {{ total - 50 > 400 ? total - 50 : 400 }}
                -
                {{ total + 50 >= 1600 ? 1600 : total + 50 }}
              </p>
              <p class="col-xs-4 t_score">
                400 to 1600
              </p>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>
<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Common from "@/mixins/common.js";

export default {
  components: {},

  mixins: [Common],

  props: ["total", "score", "practiceType", "isNew"],
  data() {
    return {
      lineCount: 10,
      section_english_score: 200,
      section_math_score: 200,
      progress_section_english_score: 0,
      progress_section_math_score: 0,
      progress_total_score: 0,
      progress_reading_score: 0,
      progress_writing_score: 0,
      progress_math_score: 0,
      total_score: 400,
      reading_score: 10,
      writing_score: 10,
      math_score: 10
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    getResolve(question_id) {
      return 0;
    },
    showTime(seconds) {
      let minute;
      let second;
      if (seconds && seconds > 0) {
        minute = Math.floor(seconds / 60);
        second = Math.floor(seconds) - minute * 60;
      } else {
        minute = 0;
        second = 0;
      }
      // 让个位数的时间正常显示
      if (minute <= 9) minute = "0" + minute;
      if (second <= 9) second = "0" + second;
      return `Time spent: ${minute}:${second}`;
    }
  }
};
</script>
<style scoped>
.total p,
.total ul {
  margin: 0px;
}
.total {
  margin-top: 30px;
}
.total .title {
  font-size: 30px;
  color: var(--themeColor);
}
.total_under {
  padding: 0px;
}
.total_above {
  padding: 0 15px;
  margin-bottom: 0.5rem;
}
.total_above .score {
  font-size: 60px;
  color: #222;
  font-weight: 700;
  line-height: 70px;
  height: 70px;
  margin-right: 1rem;
}
.differt-score-true {
  color: var(--themeColor);
}
.differt-score {
  font-size: 25px;
  font-weight: 700;
}
.total .score + p {
  margin-top: 25px;
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  border-left: 4px solid #ccc;
}
.total .total_above .title {
  margin-bottom: 0;
  height: 40px;
  /* line-height: 40px; */
}
.total .total_above p.c_score {
  margin-top: 5px;
  margin-left: 20px;
  height: 30px;
  line-height: 30px;
  padding-left: 20px;
  border-left: 4px solid #ccc;
}
.total_under p:first-child {
  font-size: 30px;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  margin-top: 10px;
}
.total .range {
  font-size: 18px;
  color: #000;
}
.total_under p:last-child {
  font-size: 16px;
  color: #666;
}
.essay li > div > div:first-child {
  font-weight: 700;
  color: #222;
  padding: 0px;
}
.essay li > div > div:last-child {
  padding-left: 20px;
  border-left: 3px solid #ccc;
}
.essay li > p {
  font-size: 16px;
  color: #666;
}
.essay .e_score {
  line-height: 34px;
  border-left: 3px solid #ccc;
}
.total_progress {
  margin-top: 20px;
}
.progress {
  margin: 20px 0;
  height: 20px;
  overflow: hidden;
  line-height: 20px;
  font-size: 15px;
  background-color: #e9ecef;
  border-radius: 3px;
}
.progress-bar-info {
  background-color: #007bff;
}
.progress-bar-success {
  background-color: var(--themeColor);
}
.progress > span {
  position: absolute;
  left: 50%;
  color: #222;
  line-height: 20px;
}
.section .tag {
  display: inline-block;
  width: 25px;
  height: 10px;
  margin-right: 5px;
  border-radius: 10%;
}
.section .tag-left {
  background: #007bff;
}
.section .tag-right {
  background: var(--themeColor);
}
.green-td {
  width: 96px;
  /* height: 50px; */
  text-align: center;
  /* line-height: 50px; */
  color: #fff;
  background-color: var(--themeColor);
}

.table td {
  height: 20px;
  padding: 2px 5px;
  text-align: center;
  vertical-align: middle;
  border: #4fb81e 1px solid;
}
.table .right-border {
  border: #4fb81e 1px solid;
  border-right: 3px solid #4fb81e;
}
.total .table >>> .el-tag {
  margin: 5px 0;
}
.table td > div {
  display: inline-block;
  margin: 0 10px;
}
.table.work-tags td {
  text-align: left;
}
tr.hover:hover .hover-answer {
  background: #cfcfcf;
}
.title {
  font-size: 30px;
}
.sat_left > div > span {
  display: inline-block;
  position: absolute;
  left: 290px;
  top: 28px;
}
.sat_left > div > p:first-child {
  font-size: 140px;
  font-weight: 700;
  color: var(--themeColor);
  line-height: 140px;
  margin-bottom: 0px;
}
.sat_right {
  padding-left: 0px;
  padding-top: 30px;
  border-bottom: 1px solid#DCDFE6;
}
.sat_right ul {
  clear: both;
}
.sat_right ul li {
  font-size: 20px;
  line-height: 30px;
}
.sat_right img {
  width: 90%;
}
.view-image {
  margin: 10px 0;
  display: flex;
}
.view-image-item {
  margin-right: 15px;
}
.view-image-item >>> img {
  border-radius: 5px;
  border: 1px solid #ccc;
}
.sat .sat_right ul {
  list-style: none;
  position: relative;
  padding-left: 0;
}
@media screen and (max-width: 768px) {
  .total .title {
    font-size: 1.5rem;
  }
  .total {
    margin-top: 0;
  }
  .total_score {
    margin-bottom: 1rem;
  }
  .total_above {
    padding: 0 15px;
    margin-bottom: 0.5rem;
  }
  .total_above .score {
    font-size: 3rem;
    line-height: 3rem;
    height: 3rem;
    margin-right: 1rem;
  }
  .total .score + p {
    margin-top: 0;
    height: 3rem;
    line-height: 3rem;
    border-left: 4px solid #ccc;
  }
  ::v-deep .el-divider--horizontal {
    margin: 1rem 0;
  }
}
</style>

<template>
  <div>
    <el-collapse
      accordion
      v-model="FileIndexNow"
      @change="getFolderFiles(Files, FileIndexNow)"
    >
      <draggable
        :disabled="isView"
        :list="Files"
        @change="$emit('resetOrder', Files)"
      >
        <div v-for="(File, index) in Files" :key="File.id" class="file-style">
          <div
            v-if="File.type === 'folder'"
            :class="{
              'folder-active': FileIndexNow === index,
              folder: true
            }"
          >
            <el-collapse-item :name="index" class="folder-header">
              <template slot="title">
                <div
                  style="flex:1;width: 100%;  display: flex; align-items: center;justify-content: space-between;"
                >
                  <div class="pt-2 pb-2">
                    <div class="season-title m-0">
                      <b>
                        <span class="folder-color" v-show="FileIndexNow !== index">
                          <i class="fas fa-folder"></i>
                        </span>
                        <span class="folder-color" v-show="FileIndexNow === index">
                          <i class="fas fa-folder-open"></i>
                        </span>
                        Folder
                      </b>
                    </div>
                    <div class="season-info" style="display: inline-block;">
                      <b>{{ File.name }}</b>
                    </div>
                  </div>
                  <div class="d-flex mr-2" v-show="!isView">
                    <el-dropdown trigger="click"  v-show="!isView">
                      <span class="more-icon" @click.stop="">
                        <i class="fa-solid fa-ellipsis-vertical"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                          <div
                            @click.stop="
                              $emit('createNewFile', File.id, File, Files)
                            "
                          >
                            Add New
                          </div>
                        </el-dropdown-item>
                        <el-dropdown-item>
                          <div @click.stop="deleteFileBySlug(File)">
                            {{ $t("newCourses.Delete") }}
                          </div>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                      <!-- <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                          <div @click="createFile(subjectIndex)">
                            {{ $t("newCourses.New Folder") }}
                          </div>
                        </el-dropdown-item>
                        <el-dropdown-item>
                          <div @click="editSubject(subjectIndex)">
                            {{ $t("newCourses.Rename") }}
                          </div>
                        </el-dropdown-item>
                        <el-dropdown-item>
                          <div
                            @click.stop="
                              removeSubjectAlert(
                                subjects,
                                subjectIndex,
                                'subject'
                              )
                            "
                          >
                            {{ $t("newCourses.Delete") }}
                          </div>
                        </el-dropdown-item>
                        <el-dropdown-item
                          divided
                          :disabled="subjectIndex === 0"
                        >
                          <div @click="upData(subjects, subjectIndex)">
                            {{ $t("newCourses.Move up") }}
                          </div>
                        </el-dropdown-item>
                        <el-dropdown-item
                          :disabled="subjectIndex === subjects.length - 1"
                        >
                          <div @click="downData(subjects, subjectIndex)">
                            {{ $t("newCourses.Move down") }}
                          </div>
                        </el-dropdown-item>
                      </el-dropdown-menu> -->
                    </el-dropdown>
                  </div>
                </div>
              </template>
              <div
                class="subject-children"
                v-loading="!File.hasOwnProperty('files')"
              >
                <div v-if="File.files">
                  <div v-if="File.files.length > 0">
                    <FileListItem
                      class="pl-4"
                      :Files="File.files"
                      :isView="isView"
                      @createNewFile="createNewFile"
                      @deleteFileBySlug="deleteFileBySlug"
                      @viewFile="viewFile"
                      @resetOrder="resetOrder"
                    ></FileListItem>
                  </div>
                  <el-empty v-else description="No Materials" :image-size="50">
                  </el-empty>
                </div>
                <!-- <div
                  class="text-center mt-3 mb-3"
                  style="padding-left: 1rem; padding-right: 1rem;"
                >
                  <el-button
                    type="primary"
                    size="default"
                    style="width: 100%;"
                    @click="$emit('createNewFile', File.id, File)"
                  >
                    <i class="fa fa-plus"></i>
                    New
                  </el-button>
                </div> -->
              </div>
            </el-collapse-item>
          </div>
          <div v-else>
            <div
              :class="{
                'file-active': FileIndexNow === index,
                'section-season': true,
                'pl-2': true,
                'pr-2': true,
                'document-color': File.type === 'document',
                'link-color': File.type === 'link',
                'video-color': File.type === 'video',
                'exam-color': File.type === 'exam',
              }"
              style="align-items:center; display: flex; align-items: center;justify-content: space-between;"
              @click="viewFileDetail(File, index)"
            >
              <div style="flex:1;" class="p-2">
                <div class="season-title m-0" style="width: 100%;">
                  <b v-show="File.type === 'document'">
                    <i class="fas fa-file"></i>
                    File
                  </b>
                  <b v-show="File.type === 'link'">
                    <i class="fas fa-link"></i>
                    Link
                  </b>
                  <b v-show="File.type === 'video'">
                    <i class="fab fa-youtube"></i>
                    Youtube Video
                  </b>
                  <b v-show="File.type === 'exam'">
                    <i class="fas fa-chart-bar"></i>
                    Test
                  </b>
                </div>
                <div class="season-info">
                  <b>{{ File.name }}</b>
                </div>
              </div>
              <div>
                <el-dropdown trigger="click" v-show="!isView">
                  <span class="more more-icon" @click.stop="">
                    <i class="fa-solid fa-ellipsis-vertical"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                      <div @click.stop="deleteFileBySlug(File)">
                        {{ $t("newCourses.Delete") }}
                      </div>
                    </el-dropdown-item>
                    <!-- <el-dropdown-item divided :disabled="index === 0">
                              <div @click="upData()">
                                {{ $t("newCourses.Move up") }}
                              </div>
                            </el-dropdown-item>
                            <el-dropdown-item
                              :disabled="
                                index === lesson.material_files.length - 1
                              "
                            >
                              <div @click="downData()">
                                {{ $t("newCourses.Move down") }}
                              </div>
                            </el-dropdown-item> -->
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>
        </div>
      </draggable>
    </el-collapse>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import draggable from "vuedraggable";
import Materials from "@/views/courses/materials/apis/materials";

export default {
  metaInfo() {},

  components: { draggable },

  mixins: [],
  name: "FileListItem",
  props: ["Files", "isView"],
  data() {
    return {
      FileIndexNow: null
    };
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    }
  },
  watch: {},

  mounted() {},

  methods: {
    viewFileDetail(File, index) {
      this.FileIndexNow = index;
      this.$emit("viewFile", File);
    },
    deleteFileBySlug(File) {
      this.$emit("deleteFileBySlug", File);
    },
    createNewFile(id, File, Files) {
      this.$emit("createNewFile", id, File, Files);
    },
    viewFile(file) {
      this.$emit("viewFile", file);
    },
    async resetOrder(Files) {
      this.$emit("resetOrder", Files);
    },
    async getFolderFiles(Files, index) {
      let id = Files[index].id;
      const File = Files[index];
      if (!Object.prototype.hasOwnProperty.call(File, "files")) {
        const files = await Materials.getFiles({
          teaching_class_id: this.$route.params.id,
          parent_id: id
        });
        this.$set(Files[index], "files", files);
      }
    }
  }
};
</script>

<style scoped>
.add-link {
  display: flex;
}
.el-menu {
  border: none;
}
.subject-content {
  border-left: solid 1px #e6e6e6;
}
::v-deep .add-link .el-input {
  margin-right: 20px;
}
::v-deep .el-dropdown-menu__item {
  padding: 0;
}
::v-deep .el-dropdown-menu__item--divided:before {
  margin: 0;
}
::v-deep .el-dropdown-menu__item > div {
  padding: 0 20px;
  text-align: center;
}
.file-detail-removes {
  margin-left: 1rem;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 0;
}
::v-deep .alert {
  padding: 0;
}
.box {
  /* padding-top: 20px; */
  padding-bottom: 1rem;
}
.course-menu {
  margin-bottom: 2rem;
}

.subject-section:hover {
  cursor: move;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
}
.subject-section {
  padding: 0 1rem 1rem 1rem;
  margin: 1rem 0;
  /* pointer-events: none; */
}
.subject-section-active {
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
}
.subject-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
  border-bottom: 1px solid #42a16a;
  height: 4rem;
}
.more-icon {
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  text-align: center;
  line-height: 2.5rem;
  font-size: 1rem;
}
.more-icon:hover {
  background-color: rgba(32, 33, 36, 0.039);
}
.subject-title h4 {
  margin: 0;
}
.file {
  border-radius: 0.5rem;
  margin: 0 0 1rem 0;
}
.file:hover {
  border-radius: 0.5rem;
  cursor: move;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
}
::v-deep .el-alert__content {
  width: 100%;
}
.file-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}
::v-deep .container .el-card__body {
  padding-top: 0;
  padding-right: 24px;
}
::v-deep .el-dialog__body {
  max-height: 40rem;
  overflow: auto;
}
::v-deep .el-collapse-item__header {
  margin: 5px;
  border-radius: 4px;
  background-color: rgb(239 246 255 / 0.8);
  overflow: hidden;
  color: #303133;
  transition: 0.3s;
  line-height: 1.5rem;
}
::v-deep .el-collapse-item__header:hover {
  background-color: #dbeafe
}
</style>

<style scoped>
.video-box {
  display: flex;
  /* margin-top: 1rem; */
  background-color: #fafafa;
}
.edu-play-left {
  min-width: 28%;
  max-width: 500px;
  border-right: 1px solid #ebeef5;
  background-color: white;
  display: flex;
  flex-direction: column;
  /* max-height: 50rem;
    overflow: scroll; */
}
.edu-play-right {
  box-sizing: border-box;
  padding: 0 0 10px 0;
  flex: 1;
  padding-bottom: 3rem;
  background-color: #fafafa;
}
::v-deep .el-card__body {
  padding-left: 0;
  padding: 0;
}
.videoContainer {
  border: 1px solid #ebeef5;
  border-radius: 1rem;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  overflow: hidden;
}
.fileContainer {
  /* border: 1px solid #ccc; */
  /* border-radius: 1rem; */
  position: relative;
  /* padding-bottom: 56.25%; */
  padding: 1.5rem;
  overflow: auto;
  min-height: 600px;
  /* background-color: #fafafa; */
}

.videoContainer iframe,
.video-container object,
.video-container embed,
.videoContainer img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
::v-deep .el-collapse {
  border-top: none;
  border-bottom: none;
}

::v-deep .el-collapse-item__header {
  height: auto;
  padding: 0 1rem;
  border-bottom: none;
}

.subject-children {
  min-height: 50px;
}

::v-deep .subject-active .el-collapse-item__header {
  background-color: white;
}
::v-deep .el-collapse-item__content {
  padding-bottom: 0;
}

.section-card-list {
  height: 50rem;
  overflow: auto;
}
.section-season {
  position: relative;
  display: flex;
  /* margin-top: 0.5rem; */
  background: #ccc;
  /* border: 0.5px solid transparent; */
  border-radius: 6px;
  font-size: 1rem;
  overflow: visible;
  cursor: pointer;
  /* margin: 1rem; */
}
.season-order {
  width: 2.5rem;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.1rem;
  color: #9499a0;
  text-align: center;
}
.season-title {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #18191c;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* width: 196px; */
}
.season-info {
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: #61666d;
  word-break: break-all !important;
}
.active {
  cursor: default;
  /* border: 1px solid var(--themeColor); */
  background: rgba(11, 163, 149, 0.05);
}
.folder {
  /* border: 1px solid #ebeef5; */
}
.folder-active {
  /* border: 1px solid #ebeef5; */
  /* background-color: var(--themeColor) ; */
}

.class-title {
  margin-top: 1rem;
}
.block-list-item-info {
  display: flex;
  width: 10rem;
  box-sizing: border-box;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 1rem;
  line-height: 2rem;
  color: #61666d;
}
.description {
  color: #9499a0;
  white-space: pre-line;
}
.speaker-avatar {
  display: flex;
  flex-direction: column;
  width: 100px;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .video-box {
    display: block;
  }
  .section-card-list {
    min-height: auto;
  }
  .edu-play-right {
    padding-bottom: 0;
  }
  .fileContainer {
    padding: 1rem;
    padding-top: 1.5rem;
    min-height: auto;
  }
}
</style>

<style scoped>
.section-season {
  margin: 5px;
  border-radius: 4px;
  /* border: 1px solid #ebeef5; */
  background-color: rgb(239 246 255 / 0.8);
  overflow: hidden;
  color: #303133;
  transition: 0.3s;
}
.section-season:hover {
  background-color: #dbeafe;
}
.file-active.section-season {
  background-color: #dbeafe;
  /* box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); */
}
/* .link-color {
  background-color: #fff7ed;
}
.link-color:hover,
.file-active.link-color {
  background-color: #ffedd5;
} */
.document-color {
}
.document-color:hover {

}

.folder-color {
  color: rgb(37 99 235);
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.testType == 'SAT')?[(_vm.userExam.finished_at)?_c('SAT',{attrs:{"tscore":_vm.userExam.score,"examId":_vm.examId,"userExam":_vm.userExam,"resultRoute":"EducatorSATResultPage"}}):_c('router-link',{attrs:{"to":{
        name: 'EducatorSATResultPage',
        params: { userExamId: _vm.userExam.id }
      },"target":"_blank"}},[_c('el-tag',{staticClass:"text-center",staticStyle:{"width":"12rem"},attrs:{"type":"success","effect":"plain"}},[_c('i',{staticClass:"fas fa-user-check"}),(_vm.userExam.metadata)?_c('b',[_vm._v(" "+_vm._s(`Module: ${_vm.userExam.metadata.sectionOrder}`)+", "+_vm._s(`Question: ${_vm.userExam.metadata.questionOrder}`)+" ")]):_c('b',[_vm._v(" Module: 1, Question: 1 ")])])],1)]:_vm._e(),(_vm.testType == 'ACT')?[(_vm.userExam.finished_at)?_c('ACT',{attrs:{"examId":_vm.examId,"userExam":_vm.userExam,"resultRoute":"EducatorACTResultPage"}}):_c('router-link',{attrs:{"to":{
        name: 'EducatorACTResultPage',
        params: { userExamId: _vm.userExam.id }
      },"target":"_blank"}},[_c('el-tag',{staticClass:"text-center",staticStyle:{"width":"12rem"},attrs:{"type":"success","effect":"plain"}},[_c('i',{staticClass:"fas fa-user-check"}),(_vm.userExam.metadata)?_c('b',[_vm._v(" "+_vm._s(`Module: ${_vm.userExam.metadata.sectionOrder}`)+", "+_vm._s(`Question: ${_vm.userExam.metadata.questionOrder}`)+" ")]):_c('b',[_vm._v(" Module: 1, Question: 1 ")])])],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
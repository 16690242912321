var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container pt-3"},[_c('Breadcrumb',[_c('template',{slot:"restPage"},[_c('el-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.myTitle)+" ")])],1)],2),_c('Heading',{attrs:{"heading":"h4","content":_vm.myTitle}}),_c('hr'),(_vm.companies)?[(_vm.resultsTotal > -1)?_c('el-alert',{staticClass:"mt-3",attrs:{"type":"success","closable":false}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" Results: "),_c('b',[_vm._v(_vm._s(_vm.resultsTotal))])])]):_vm._e(),_c('div',{staticClass:"text-center",staticStyle:{"margin":"15px 0"}},[_c('el-pagination',{attrs:{"background":"","page-size":_vm.pageSize,"current-page":_vm.page,"total":_vm.resultsTotal,"pager-count":9,"layout":"prev, pager, next"},on:{"current-change":_vm.setPage}})],1),(_vm.companies)?_c('el-table',{attrs:{"data":_vm.companies,"stripe":""}},[_c('el-table-column',{attrs:{"label":"Company Name","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
              name: 'CompanyInfo',
              params: {
                id: scope.row.id
              }
            }}},[_c('b',[_vm._v(_vm._s(scope.row.name))])])]}}],null,false,2384174983)}),_c('el-table-column',{attrs:{"label":_vm.$t('TeacherCourses.Class Dates'),"width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.created_at)?[_vm._v(" "+_vm._s(_vm.instant.formatGoogleTime(scope.row.created_at))+" ")]:[_vm._v(" - ")]]}}],null,false,3511058903)}),_c('el-table-column',{attrs:{"label":_vm.$t('TeacherCourses.Action'),"fixed":"right","width":"240"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{staticClass:"mr-2",attrs:{"effect":"dark","content":_vm.$t('TeacherCourses.Preview'),"placement":"top"}},[_c('router-link',{attrs:{"to":{
                name: 'CompanyInfo',
                params: {
                  id: scope.row.id
                }
              }}},[_c('el-button',{attrs:{"type":"success","size":"mini"}},[_c('i',{staticClass:"fas fa-eye"})])],1)],1)]}}],null,false,1303755450)})],1):_vm._e(),_c('div',{staticClass:"text-center",staticStyle:{"margin":"15px 0"}},[_c('el-pagination',{attrs:{"background":"","page-size":_vm.pageSize,"current-page":_vm.page,"total":_vm.resultsTotal,"pager-count":9,"layout":"prev, pager, next"},on:{"current-change":_vm.setPage}})],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div v-if="userExam.finished_at" class="satMocks-score">
      <template
        v-if="
          userExam.user_exam_results && userExam.user_exam_results.length > 1
        "
      >
        <el-tooltip effect="dark" content="English" placement="top">
          <b>
            {{ userExam.user_exam_results[0].score }}
            +
          </b>
        </el-tooltip>
        <el-tooltip effect="dark" content="Math" placement="top">
          <b>
            {{ userExam.user_exam_results[1].score }}
            =
          </b>
        </el-tooltip>
      </template>
      <el-tooltip
        v-if="userExam.id > 0"
        effect="dark"
        content="Total"
        placement="top"
      >
        <b>
          <router-link
            :to="{
              name: resultRoute,
              params: { userExamId: userExam.id }
            }"
            target="_blank"
          >
            {{ Number(tscore) }} <span style="color: black;"></span>
          </router-link>
        </b>
      </el-tooltip>
      <b v-else> {{ Number(tscore) }} <span style="color: black;"></span> </b>
    </div>
    <div v-else>
      <router-link
        :to="{
          name: 'EducatorSATResultPage',
          params: { userExamId: userExam.id }
        }"
        target="_blank"
      >
        <el-tag
          class="text-center"
          style="width: 12rem;"
          type="success"
          effect="plain"
        >
          <i class="fas fa-user-check"></i>
          <b v-if="userExam.metadata">
            {{ `Module: ${userExam.metadata.sectionOrder}` }},
            {{ `Question: ${userExam.metadata.questionOrder}` }}
          </b>
          <b v-else>
            Module: 1, Question: 1
          </b>
        </el-tag>
      </router-link>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  components: {},

  mixins: [],

  props: ["examId", "userExam", "escore", "mscore", "tscore", "resultRoute"],
  data() {
    return {};
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    testTypeNew() {
      return this.getPracticeType(this.testType);
    },
    satIvywayUrl() {
      return process.env.VUE_APP_SAT_IVYWAY_FRONTEND_DOMAIN;
    }
  },
  watch: {},

  mounted() {},

  methods: {
    getPracticeType(category) {
      const OLD_PRACTICE_READING = 1;
      const OLD_PRACTICE_WRITING = 2;
      const OLD_PRACTICE_MATH = 3;
      const OLD_PRACTICE_MATH_CALC = 4;
      const OLD_DRILL_READING = 5;
      const OLD_DRILL_WRITING = 6;
      const OLD_DRILL_MATH = 7;
      const OLD_DRILL_MATH_CALC = 8;
      const OLD_FULL_TEST = 9;
      const OLD_ADAPTIVE_PRACTICE = 10;
      const DRILL_PRACTICE_ENGLISH = 11;
      const DRILL_PRACTICE_MATH = 12;
      const MODULE_PRACTICE_ENGLISH = 13;
      const MODULE_PRACTICE_MATH = 14;
      const FULL_TEST = 15;
      const ADAPTIVE_PRACTICE = 16;
      if (category >= 15) {
        return "composePractices";
      } else if (category == 11 || category == 12) {
        return "drill";
      } else if (category == 13 || category == 14) {
        return "singlePractice";
      } else {
        return "singlePractice";
      }
    },
    setExam() {
      this.$emit("setExam");
    },
    removeExam() {
      this.$emit("removeExam");
    }
  }
};
</script>

<style scoped>
.satMocks-score {
  font-size: 1.5rem;
}
.table td {
  padding: 5px;
  vertical-align: middle;
  text-align: center;
}
.inner-table {
  text-align: center;
  width: 100%;
  height: 50%;
  border: 1px #333;
}
table.inner-table tr th,
table.inner-table tr td {
  border: none;
}
.table td .M_score {
  border-left: 1px solid #e6e9ed;
}
.table-bordered th,
.table-bordered td {
  border-left: none;
}
.table td .T_score {
  border-top: 1px solid #e6e9ed;
}
.T_score b {
  font-size: 18px;
}
.R_score,
.G_score,
.E_score,
.M_score,
.M_score b,
.T_score b {
  color: #444;
}
.inner-table b {
  font-size: 18px;
}
.inner-table .T_score b {
  font-size: 20px;
}
.T_score {
  cursor: pointer;
}
.score-edit {
  display: none;
}
.T_score:hover .score-edit {
  cursor: pointer;
  display: inline-block;
}
</style>

<template>
  <div>
    <template v-if="testType == 'SAT'">
      <SAT
        v-if="userExam.finished_at"
        :tscore="userExam.score"
        :examId="examId"
        :userExam="userExam"
        resultRoute="SATResultPage"
      />
      <router-link
        v-else
        :to="{
          name: 'SATTest',
          params: {
            examId: examId,
            userExamId: userExam.id
          }
        }"
        target="_blank"
      >
        <el-button type="warning" style="width: 100%">
          <i class="fa fa-play"></i>
          Resume
        </el-button>
      </router-link>
    </template>
    <template v-if="testType == 'ACT'">
      <ACT
        v-if="userExam.finished_at"
        :examId="examId"
        :userExam="userExam"
        resultRoute="ACTResultPage"
      />
      <router-link
        v-else
        :to="{
          name: 'ACTTest',
          params: {
            examId: examId,
            userExamId: userExam.id
          }
        }"
        target="_blank"
      >
        <el-button type="warning" style="width: 100%">
          <i class="fa fa-play"></i>
          Resume
        </el-button>
      </router-link>
    </template>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import SAT from "@/views/courses/tests/components/SatScore";
import ACT from "@/views/courses/tests/components/ActScore";

export default {
  metaInfo() {},

  components: { SAT, ACT },

  mixins: [],

  props: ["examId", "userExam", "testType", "role"],
  data() {
    return {};
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    }
  },
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped></style>

<template>
  <div class="container pt-3">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link :to="{ name: 'EducatorFullTestsList' }">
            Full Tests
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ PageTitle }}
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          Browse
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <h4>
      {{ getCompanyExamTitle(composePractice.name) }}
    </h4>
    <hr class="separate-line" />
    <el-card>
      <div>
        <el-form label-width="150px" label-position="top">
          <el-form-item label="Name">
            <b class="text-success">
              {{ PageTitle }}
            </b>
          </el-form-item>
          <el-form-item label="Description">
            {{ composePractice.description }}
          </el-form-item>
          <el-form-item label="Modules">
            <b class="text-success">
              {{ composePractice.compose_practice_practices.length }}
            </b>
            Modules
          </el-form-item>
          <el-form-item label="Students" v-if="isRoleAdmin()">
            <el-button type="success" size="mini" style="width: 4rem;">
              <i class="fas fa-users"></i>
              {{ composePractice.exam.user_exams_count }}
            </el-button>
          </el-form-item>
          <el-form-item label="Date Created">
            {{ instant.formatGoogleTime(composePractice.created_at) }}
          </el-form-item>
        </el-form>
        <hr />
      </div>
      <router-link :to="{ name: 'EducatorFullTestPDF', params: $route.params }">
        <el-button type="success" style="width: 100%;">
          <i class="fas fa-eye"></i>
          View PDF
        </el-button>
      </router-link>
      <div class="mt-4 mb-3">
        <el-checkbox
          v-model="showPassage"
          label="Passage"
          size="medium"
          border
        ></el-checkbox>
        <el-checkbox
          v-model="showQuestion"
          label="Entire Question"
          size="medium"
          border
        ></el-checkbox>
        <el-checkbox
          v-model="showOptions"
          label="Choices"
          size="medium"
          border
        ></el-checkbox>
        <el-checkbox
          v-model="showAnswer"
          label="Answer"
          size="medium"
          border
        ></el-checkbox>
        <el-checkbox
          v-model="showExplanation"
          label="Explanation"
          size="medium"
          border
        ></el-checkbox>
        <el-checkbox
          v-model="showLabels"
          label="Domain and Skills"
          size="medium"
          border
        ></el-checkbox>
      </div>
      <div ref="questions-all">
        <div ref="targetDom">
          <template
            v-if="composePractice.compose_practice_practices.length > 0"
          >
            <div
              class="mb-4"
              v-for="(practice,
              index) in composePractice.compose_practice_practices"
              :key="practice.id"
              v-show="sectionIndex - 1 === index || !sectionIndex"
            >
              <div class="small">
                <h4>
                  {{ getCompanyExamTitle(practice.name) }}
                </h4>
                <hr />
              </div>
              <QuestionsTable
                :showQuestion="showQuestion"
                :showPassage="showPassage"
                :showOptions="showOptions"
                :showAnswer="showAnswer"
                :showExplanation="showExplanation"
                :showLabels="showLabels"
                :questions="practice.AllQuestions"
                :search="$route.query.search"
                :showOrder="true"
                :isAdmin="isRoleAdmin()"
                :hideCheckbox="true"
              >
              </QuestionsTable>
            </div>
          </template>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import SAT from "@/apis/sat.js";
import Browse from "@/views/tests/fullTests/browse/apis/browse.js";
import Common from "@/mixins/common.js";
import { instant } from "@ivy-way/material";
import $ from "jquery";
import roleMixin from "@/mixins/role";
import QuestionsTable from "@/views/questions/list/components/QuestionsTable.vue";

export default {
  metaInfo() {
    return {
      title: `${this.PageTitle} - ${this.CompanyName}`
    };
  },

  components: { Breadcrumb, QuestionsTable },

  mixins: [Common, roleMixin],

  props: [],
  data() {
    return {
      showPassage: true,
      showQuestion: true,
      showOptions: true,
      showAnswer: true,
      showExplanation: false,
      showLabels: false,
      isNew: 1,
      showCreateNewSectionDialog: false,
      breakTime: [],
      composePractice: {
        test_id: null,
        test_type: "",
        exam: {
          title: null
        },
        compose_practice_practices: []
      },
      adaptivePracticesArr: [],
      adaptiveIndex: 0,
      practices: [],
      subjects: [],
      subject_id: null,
      keyWord: "",
      pageSize: 0,
      page: 0,
      resultsTotal: 0
    };
  },
  computed: {
    PageTitle() {
      let title = this.composePractice.name
        ? this.getCompanyExamTitle(this.composePractice.name)
        : "";
      return title;
    },
    instant() {
      return instant;
    },
    id() {
      return this.$route.params.id;
    },
    sectionIndex() {
      return this.$route.query.section;
    },
    practiceType() {
      return this.$route.query.practiceType;
    }
  },
  watch: {
    showPassage() {
      this.initMathJaxPlugin();
      this.$nextTick(async () => {
        this.setImg();
      });
    },
    showExplanation() {
      this.initMathJaxPlugin();
      this.$nextTick(async () => {
        this.setImg();
      });
    },
    showAnswer() {
      if (this.showAnswer) {
        this.showOptions = true;
      }
    },
    showOptions() {
      this.initMathJaxPlugin();
      this.$nextTick(async () => {
        this.setImg();
      });
    },
    showQuestion() {
      this.initMathJaxPlugin();
      this.$nextTick(async () => {
        this.setImg();
      });
    },
    questions() {
      this.initMathJaxPlugin();
      this.$nextTick(async () => {
        this.setImg();
      });
    }
  },

  async mounted() {
    await this.getTests();
    await this.initMathJaxPlugin();
    this.$nextTick(async () => {
      this.setImg();
    });
    let vm = this;
    window.onkeydown = function(e) {
      // 屏蔽ctrl+p 打印
      if (e.ctrlKey && e.keyCode == 80) {
        vm.$message({
          dangerouslyUseHTMLString: true,
          message:
            "Please use the  <i class='fas fa-download'></i> <b>Download PDF</b> button below to create a PDF file for this test.!",
          type: "warning"
        });
        e.preventDefault();
        e.returnValue = false;
        return false;
      }
    };
  },

  methods: {
    async getTests() {
      const res = await Browse.getTests(this.id);
      res.exam.exam_sections.forEach(practice => {
        let questions = [];
        practice.exam_questions.forEach((question, index) => {
          questions.push(question.question);
        });
        practice["AllQuestions"] = questions;
      });
      this.composePractice = {
        ...res.exam,
        compose_practice_practices: res.exam.exam_sections
      };
    },
    setImg() {
      $(".small img").each(function() {
        if ($(this).attr("src") !== "") {
          $(this).attr("src", $(this).attr("src") + "?" + new Date().getTime());
        }
        $(this).attr("crossorigin", "anonymous");
      });
    },
    isSplit(nodes, index, pageHeight) {
      return (
        nodes[index].offsetTop + nodes[index].offsetHeight < pageHeight &&
        nodes[index + 1] &&
        nodes[index + 1].offsetTop + nodes[index + 1].offsetHeight > pageHeight
      );
    },
    getFooterElement(remainingHeight, fillingHeight = 36) {
      let newNode = document.createElement("div");
      newNode.style.background = "#ffffff";
      newNode.style.width = "calc(100% + 8px)";
      newNode.style.marginLeft = "-4px";
      newNode.style.marginBottom = "0px";
      newNode.style.height = remainingHeight + fillingHeight + "px"; // pdf截断需要一个空白位置
      return newNode;
    },
    outPutPdfFn(ele, title) {
      this.$message.warning("Processing... Please wait.");
      let vm = this;
      const A4_WIDTH = 592.28;
      const A4_HEIGHT = 841.89;
      vm.$nextTick(async () => {
        let target = vm.$refs.targetDom;
        let pageHeight = (target.scrollWidth / A4_WIDTH) * A4_HEIGHT;
        let lableListID = document.getElementsByClassName("small");
        for (let i = 0; i < lableListID.length; i++) {
          let multiple = Math.ceil(
            (lableListID[i].offsetTop + lableListID[i].offsetHeight) /
              pageHeight
          );

          if (this.isSplit(lableListID, i, multiple * pageHeight)) {
            console.log(multiple + "Page: " + multiple * pageHeight);
            console.log(this.isSplit(lableListID, i, multiple * pageHeight));

            console.log(i + " offsetTop:" + lableListID[i].offsetTop);
            console.log(i + " offsetHeight:" + lableListID[i].offsetHeight);

            let divParent = lableListID[i].parentNode; // 获取该div的父节点
            let _H =
              multiple * pageHeight -
              (lableListID[i].offsetTop + lableListID[i].offsetHeight);

            console.log(_H);
            let newNode = vm.getFooterElement(_H);
            let next = lableListID[i].nextSibling; // 获取div的下一个兄弟节点
            // 判断兄弟节点是否存在
            if (next) {
              // 存在则将新节点插入到div的下一个兄弟节点之前，即div之后
              divParent.insertBefore(newNode, next);
            } else {
              // 不存在则直接添加到最后,appendChild默认添加到divParent的最后
              divParent.appendChild(newNode);
            }
          }
        }
        this.getPdf(ele, title);
      });
    },
    downloadPDF(ele, title) {
      this.outPutPdfFn(ele, title);
      // this.getPdf(ele, title);
    },
    initMathJaxPlugin() {
      this.$mathJax.loadMathJaxScript(() => {
        this.$mathJax.initMathJaxConfig();
        this.$mathJax.MathQueue([this.$refs["questions-all"]]);
      });
    },
    addPassage(index) {
      if (this.composePractice.compose_practice_practices.length === 4) {
        this.$message({
          message: "No more than 4 Subjects",
          type: "warning"
        });
      } else {
        this.composePractice.compose_practice_practices.push(
          this.practices[index]
        );
      }
    },
    async getComposePracticeDetail() {
      const res = await SAT.getEditorComposePracticeDetail(this.id);
      res.compose_practice.practices.forEach(practice => {
        let questions = [];
        practice.exam.exam_questions.forEach((question, index) => {
          questions.push(question.question);
        });
        practice["AllQuestions"] = questions;
      });
      this.composePractice = {
        ...res.compose_practice,
        compose_practice_practices: res.compose_practice.practices
      };
    }
  }
};
</script>

<style scoped>
::v-deep .el-form-item__content {
  line-height: 1.5;
}
.question-card {
  cursor: move;
}
.questions-list {
  height: 600px;
  overflow: auto;
  padding: 1rem;
  /* border: 2px solid var(--themeColor); */
}

#content {
  position: relative;
  width: 1184.56px;
  margin: 0 auto;
}
::v-deep .el-form-item__content {
  line-height: 1.5;
}
.question-card {
  cursor: move;
}

::v-deep .el-dialog__body {
  padding: 10px 20px;
}
::v-deep .el-form-item__label {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 2rem;
}
</style>

import ApiService from "@/common/api.service";

export default {
  upgradePlan(data) {
    return ApiService.post("pro/payment-link", data);
  },
  getPayUrl(data) {
    return ApiService.query("stripe/subscription", data);
  },
  subscriptionForUser(userId, data) {
    return ApiService.post(`/users/${userId}/subscriptions`, data);
  },
  subscriptionForByPoints(companyId, data) {
    return ApiService.post(
      `organizations/${companyId}/assign-subscription`,
      data
    );
  }
};

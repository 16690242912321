<template>
  <div
    @keyup.esc="
      showPC = false;
      showPhone = false;
    "
  >
    <el-card>
      <h5>
        Question
      </h5>
      <hr />
      <div class="add-question" v-if="question">
        <div>
          <el-form
            ref="form"
            :model="question"
            label-width="150px"
            v-if="isAdmin"
          >
            <el-form-item label="Answered Count">
              <el-input
                type="number"
                v-model="question.answered_count"
              ></el-input>
            </el-form-item>
            <el-form-item label="Correct Count">
              <el-input
                type="number"
                v-model="question.correct_count"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="Correct Rate">
              <el-input
                type="number"
                v-model="question.correct_rate"
              ></el-input>
            </el-form-item> -->
            <hr />
          </el-form>
          <el-form ref="form" :model="question" label-width="100px">
            <el-form-item>
              <div slot="label">
                <div>
                  <span slot="reference">
                    <span class="text-danger">*</span>
                    {{ $t("AddQuestions.Question") }}
                  </span>
                </div>
              </div>
              <div class="question-ck">
                <Ckeditor
                  :value="question.content || ''"
                  @input="value => $emit('setQuestion', { content: value })"
                />
                <el-alert
                  type="info"
                  :closable="false"
                  class="explanation mt-3"
                >
                  <ul style="margin-bottom:0;line-height: 2;">
                    <li>
                      <kbd>Shift</kbd> +
                      <kbd>Enter</kbd>
                      {{ $t("AddQuestions.for line break") }}
                    </li>
                  </ul>
                </el-alert>
              </div>
            </el-form-item>
            <el-form-item required>
              <span slot="label">
                {{ $t("AddQuestions.Type") }}
              </span>
              <el-radio-group v-model="question.type">
                <el-radio label="single" border>
                  {{ $t("AddQuestions.Multiple Choice") }}
                </el-radio>
                <el-radio label="math" border>
                  {{ $t("AddQuestions.Grid-In") }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="Difficulty">
              <el-radio-group v-model="question.difficulty">
                <!-- <el-radio :label="null" :value="null" border>
                  (Not Set)
                </el-radio> -->
                <el-radio
                  :key="index"
                  v-for="(difficulty, index) in DifficultyArr"
                  :label="difficulty.key"
                  :value="difficulty.key"
                  border
                >
                  {{ titleCase(difficulty.name) }}
                </el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item
              :label="$t('AddQuestions.Choices')"
              v-if="question.type === 'single'"
            >
              <div slot="label">
                <span slot="reference">
                  <span class="text-danger">*</span>
                  {{ $t("AddQuestions.Choices") }}
                </span>
              </div>
              <div v-for="(option, index) in question.options" :key="index">
                <div class="question-letter">
                  <div style="width: 45px;">
                    <el-checkbox
                      :label="option.label"
                      :value="option.label"
                      @change="selectedAnswers"
                      v-model="questionAnswers"
                    ></el-checkbox>
                  </div>
                  <div style="flex: 1">
                    <Ckeditor v-model="option.content" />
                  </div>
                </div>
              </div>
              <!-- 选项区域 -->
              <!-- 如果是SAT考试类型(MockType === 4)，显示4个选项 -->
              <!-- 如果是ACT考试类型(MockType === 1)，可以添加或删除选项 -->
              <!-- 每个选项包含一个复选框和富文本编辑器 -->
              <!-- 复选框用于选择正确答案 -->
              <!-- 富文本编辑器用于编辑选项内容 -->
              <div class="text-right" v-if="MockType === 1">
                <el-button
                  v-show="question.options.length < 5"
                  type="success"
                  class="ml-2"
                  @click="addOptions(question.options.length)"
                >
                  <i class="fa fa-plus"></i>
                  Add a choice
                </el-button>
                <el-button
                  v-show="question.options.length > 4"
                  type="danger"
                  class="ml-2"
                  @click="removeOptions(question.options.length)"
                >
                  <i class="fa fa-minus"></i>
                  Remove a choice
                </el-button>
              </div>
              <el-alert type="info" class="mt-3" :closable="false">
                <ul style="margin-bottom:0; line-height: 2">
                  <li>
                    For SAT questions, there will be 4 fixed choices (A-D)
                  </li>
                  <li>
                    For ACT questions, you can add or remove choices (A-E)
                  </li>
                  <li>Check the box next to the correct answer(s)</li>
                  <li>
                    Use the rich text editor to enter choice content
                  </li>
                </ul>
              </el-alert>
            </el-form-item>
            <el-form-item>
              <div slot="label">
                <span slot="reference">
                  <span class="text-danger">*</span>
                  {{ $t("AddQuestions.Answer") }}
                </span>
              </div>
              <template v-if="question.type === 'single'">
                <div style="line-height: 40px;">
                  <b
                    class="text-success"
                    v-for="(answer, index) in question.answers"
                    :key="index"
                  >
                    {{ answer.answer[0] }}
                  </b>
                </div>
                <el-alert type="info" class="mt-3" :closable="false">
                  <ul style="margin-bottom:0; line-height: 2">
                    <li>
                      {{ $t("AddQuestions.Check the right choice") }}
                    </li>
                    <li>
                      {{ $t("AddQuestions.If this question") }}
                    </li>
                  </ul>
                </el-alert>
              </template>
              <template v-else>
                <div
                  class="d-flex mb-3"
                  v-for="(answer, index) in question.answers"
                  :key="index"
                >
                  <el-input v-model="answer.answer[0]"></el-input>
                  <el-button type="success" class="ml-2" @click="addAnswer">
                    <i class="fa fa-plus"></i>
                  </el-button>
                  <el-button
                    type="danger"
                    class="ml-2"
                    @click="removeAnswer(index)"
                    v-if="index > 0"
                  >
                    <i class="fas fa-minus"></i>
                  </el-button>
                </div>
                <el-alert type="info" class="mt-3" :closable="false">
                  <ul style="margin-bottom:0; line-height: 2">
                    <li
                      v-html="$t('AddQuestions.All fields cannot be blank')"
                    ></li>
                    <li v-html="$t('AddQuestions.right answer')"></li>
                  </ul>
                  <ul style="margin-bottom:0; line-height: 2">
                    <li
                      v-for="(item, index) in answerExamples['math']"
                      :key="index"
                      v-html="item"
                    ></li>
                  </ul>
                </el-alert>
              </template>
            </el-form-item>
            <el-form-item :label="$t('AddQuestions.Explanation')">
              <div class="explanation-content">
                <Ckeditor
                  :value="question.explanations[0].explanation || ''"
                  @input="
                    value =>
                      $emit('setQuestion', {
                        explanations: [{ explanation: value }]
                      })
                  "
                />
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { helper } from "@ivy-way/material";
// import Ckeditor from "@/components/ckeditor/index.vue";
import Ckeditor from "@/components/ckeditor/ckeditor5.vue";
import SAT from "@/apis/sat.js";
import Common from "@/mixins/common.js";

export default {
  components: {
    Ckeditor
  },

  mixins: [Common],

  props: ["MockType", "question", "passage", "tags"],
  data() {
    return {
      selectedTags: [],
      questionAnswers: []
    };
  },
  computed: {
    DifficultyArr() {
      return [
        { name: "Beginner", key: 1 },
        { name: "Easy", key: 2 },
        { name: "Medium", key: 3 },
        { name: "Hard", key: 4 },
        { name: "Super Advanced", key: 5 }
      ];
    },
    helper() {
      return helper;
    },
    answerExamples() {
      return {
        default: [
          this.$t("AddQuestions.With 1 answer1"),
          this.$t("AddQuestions.With multiple answers1")
        ],
        math: [
          this.$t("AddQuestions.With 1 answer2"),
          this.$t("AddQuestions.With multiple answers2"),
          this.$t("AddQuestions.If the answer is a range"),
          this.$t("AddQuestions.If the answer is a range and contains")
        ]
      };
    }
  },
  watch: {
    question: {
      deep: true,
      handler() {
        let questionAnswers = [];
        console.log(this.question.answers);
        this.question.answers.forEach((answer, index) => {
          questionAnswers.push(answer.answer[0]);
        });
        this.questionAnswers = questionAnswers;
        this.setMath("ivy-question-content");
      }
    }
  },

  async mounted() {
    // await this.getTags();
    if (this.question.answers && this.question.answers.length > 0) {
      this.question.answers.forEach(answer => {
        if (answer.answers === null) {
          answer.answers = [""];
        }
      });
    }
    this.setMath("ivy-question-content");
    console.log(1);
  },

  methods: {
    selectedAnswers(answer) {
      console.log(this.questionAnswers);
      let AnswersArr = [];
      this.questionAnswers.forEach(item => {
        let newAnswer = {
          answer: [item]
        };
        this.question.answers.forEach((answer, index) => {
          if (answer.answer[0] == item) {
            newAnswer = answer;
          }
        });
        AnswersArr.push(newAnswer);
      });
      this.question.answers = AnswersArr;
    },
    setMath(ref) {
      this.$nextTick(() => {
        let tag = this.$refs[ref];
        if (this.$mathJaxSAT.isMathjaxConfig) {
          this.$mathJaxSAT.initMathjaxConfig();
        }
        if (tag) {
          this.$mathJaxSAT.MathQueue([tag]);
        }
      });
    },
    setType(value) {
      this.question.answers = [
        {
          answers: []
        }
      ];
      this.$emit("setQuestion", { type: value });
    },
    replaceAll(string, search, replace) {
      return string.split(search).join(replace);
    },
    // async getTags() {
    //   const res = await SAT.getSATQuestionTags();
    //   this.tags = res.sat_question_tags;
    // },
    addAnswer() {
      this.question.answers.push({
        answer: [""]
      });
    },
    addOptions(order) {
      let arr = {
        1: {
          label: "A",
          content: ""
        },
        2: {
          label: "B",
          content: ""
        },
        3: {
          label: "C",
          content: ""
        },
        4: {
          label: "D",
          content: ""
        },
        5: {
          label: "E",
          content: ""
        }
      };
      this.question.options.push(arr[order + 1]);
    },
    removeOptions(order) {
      this.question.options.splice(order - 1, 1);
    },
    removeAnswer(index) {
      this.question.answers.splice(index, 1);
    },
    handleClose() {
      this.$emit("closeDialog");
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    saveQuestion() {
      this.$emit("saveQuestion");
    }
  }
};
</script>

<style scoped>
.add-question {
  padding-bottom: 1rem;
}
::v-deep .el-dialog__body {
  height: 500px;
  overflow: auto;
}
::v-deep .el-alert__title {
  font-size: 16px;
}
::v-deep .el-alert .el-alert__description {
  font-size: 14px;
}
.question-letter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
::v-deep .question-letter .label {
  margin: 0;
}
::v-deep .el-input-group {
  margin-left: 2rem;
  width: auto;
  flex: 1;
}
::v-deep .sat-material-questionBox-MultipleChoice {
  margin-top: 0;
}
::v-deep .sat-material-container-MultipleChoice {
  height: auto;
  display: block;
}
::v-deep .MathJax .mrow {
  font-size: 1.2rem !important;
}
::v-deep .question-ck .ck-content {
  min-height: 40px;
  /* max-height: 200px; */
  overflow: auto;
}
::v-deep .explanation-content .ck-content {
  min-height: 200px;
  max-height: 300px;
}
::v-deep .smartEditor .ck-content {
  min-height: 200px;
  max-height: 600px;
}
::v-deep .add-passage-dialog .el-dialog__body {
  height: 800px;
  overflow: auto;
}

::v-deep .MathJax .mrow {
  font-size: 1.1rem !important;
}
ul li {
  line-height: 1.5;
}
</style>

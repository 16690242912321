<template>
  <div v-if="showSessionClass">
    <!-- <Heading heading="h4" :content="myTitle"></Heading>
    <hr /> -->
    <div>
      <el-form
        :model="sessionClass"
        ref="newTest"
        label-width="140px"
        @submit.native.prevent
        @keyup.enter.native="createNew('newTest')"
      >
        <el-form-item
          :label="$t('TeacherCourses.Class Name')"
          required
          prop="name"
        >
          <el-input
            v-model="sessionClass.name"
            :placeholder="$t('TeacherCourses.Class Name')"
            maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('TeacherCourses.Class Description')"
          prop="description"
        >
          <el-input
            v-model="sessionClass.description"
            type="textarea"
            :rows="5"
            :placeholder="$t('TeacherCourses.Class Description')"
            maxlength="200"
            show-word-limit
          ></el-input>
        </el-form-item>
        <!-- <el-form-item :label="$t('TeacherCourses.Method')" prop="type">
          <el-radio-group v-model="sessionClass.is_online" size="small">
            <el-radio-button :label="0">
              <i aria-hidden="true" class="fa fa-building"></i>
              {{ $t("TeacherCourses.In-Person") }}
            </el-radio-button>
            <el-radio-button :label="1">
              <i aria-hidden="true" class="fa fa-globe"></i>
              {{ $t("TeacherCourses.Online") }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item :label="$t('TeacherCourses.Class Dates')" prop="date">
          <el-date-picker
            style="width: 455px"
            v-model="classDate"
            type="daterange"
            range-separator="~"
            :start-placeholder="$t('TeacherCourses.Start Date')"
            :end-placeholder="$t('TeacherCourses.End Date')"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('TeacherCourses.Class Time')">
          <el-time-select
            class="mr-3"
            :placeholder="$t('TeacherCourses.Start Time')"
            :picker-options="{
              start: '07:00',
              step: '00:30',
              end: '23:30'
            }"
            :disabledHours="disabledHours"
            v-model="sessionClass.started_time"
          >
          </el-time-select>
          <el-time-select
            :placeholder="$t('TeacherCourses.End Time')"
            v-model="sessionClass.ended_time"
            :picker-options="{
              start: '07:00',
              step: '00:30',
              end: '23:30',
              minTime: sessionClass.started_time
            }"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="createNew('newTest')">
            <i class="fas fa-save"></i>
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import Class from "@/views/courses/apis/Add.js";
import SAT from "@/apis/sat";
import Common from "@/mixins/common.js";
import Theme from "@/common/theme";
import role from "@/mixins/role.js";
import Company from "@/apis/company";

export default {
  metaInfo() {
    return {
      title: `${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: {},

  mixins: [Common, role],

  props: [],
  data() {
    return {
      pickerOptions: {
        disabledDate(date) {
          return date.getTime() < Date.now();
        }
      },
      types: ["Modules", "Drills"],
      fullTypes: ["Linear", "Adaptive"],
      showSessionClass: true,
      classDate: [],
      value1: "",
      sessionClass: {
        name: "",
        description: "",
        // is_online: 0,
        started_at: "",
        ended_at: "",
        started_time: "",
        ended_time: ""
      },
      isDataChangeCount: 0,
      subjects: [],
      showStudents: false,
      students: [],
      student_users: [],
      old_student_users: [],
      selectedStudent: null,
      showTeachers: false,
      teachers: [],
      teacher_users: [],
      old_teacher_users: [],
      selectedTeacher: null,
      composePractices: null,
      adaptivePractices: null,
      modules: null,
      drills: null,
      practices: null,
      DrillPractices: null,
      classExams: []
    };
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    isAdmin() {
      return (
        this.$route.name === "AdminSatAddCourse" ||
        this.$route.name === "AdminEditCourse"
      );
    },
    isTeacher() {
      return (
        this.$route.name === "TeacherSatAddCourse" ||
        this.$route.name === "TeacherEditCourse"
      );
    },
    isCompany() {
      return (
        this.$route.name === "CompanySatAddCourse" ||
        this.$route.name === "CompanyEditCourse"
      );
    },
    companyId() {
      return this.getProfile.company_id;
    },
    instant() {
      return instant;
    },
    user() {
      return user;
    },
    myTitle() {
      return "Edit Information";
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    type() {
      let type = "";
      if (this.$route.name === "SatAddPractice") {
        type = "practice";
      } else if (this.$route.name === "SatAddComposePractice") {
        type = "composePractice";
      }
      return type;
    },
    ...mapState("user", ["token", "lang"]),
    isLogin() {
      return this.token !== "";
    },
    theme() {
      return Theme.name;
    },
    classId() {
      return this.$route.params.id;
    },
    isEdit() {
      return this.$route.params.id > 0;
    },
    classExamsIDArr() {
      let arr = [];
      this.classExams.forEach(exam => {
        if (exam.exam) {
          arr.push(exam.exam.id);
        }
      });
      return arr;
    }
  },
  watch: {
    sessionClass: {
      handler(n, o) {
        this.isDataChangeCount++;
        if (this.isDataChangeCount > 1) {
          //阻止路由跳转
          this.$store.commit("router/SET_EDITING", true);
        }
      },
      deep: true
    }
  },
  beforeDestroy() {
    // 不再監聽 onbeforeunload
    window.onbeforeunload = null;
  },
  async created() {
    //阻止浏览器刷新关闭
    window.onbeforeunload = () => {
      return "Your changes may not be saved. Are you sure you want to leave the page?";
    };
  },
  async mounted() {
    if (this.$route.params.id) {
      this.showSessionClass = false;
      await this.getClass(this.$route.params.id);
    }
    // await this.getComposePractices();
    // await this.getAdaptivePractices();
    // await this.getModules();
    // await this.getDrills();
    // this.searchUsers();
    // this.searchTeachers();
    if (this.isTeacher && !this.isEdit) {
      if (this.teacher_users.length === 0) {
        this.teacher_users.push(this.getProfile);
      }
    }
    this.showSessionClass = true;
  },

  methods: {
    async getComposePractices() {
      const res = await SAT.getComposePractices({
        is_new: 1,
        page: 1,
        is_public: 1,
        per_page: 100
      });
      this.composePractices = res.compose_practice.data;
    },
    async getAdaptivePractices() {
      const res = await SAT.getAdaptivePractices({
        is_new: 1,
        page: 1,
        is_public: 1,
        per_page: 100
      });
      this.adaptivePractices = res.adaptive_practices.data;
    },
    async getModules() {
      const res = await SAT.getClassPractices(this.$route.params.id, {
        categories: [13, 14]
      });
      this.modules = res.exams;
    },
    async getDrills() {
      const res = await SAT.getClassPractices(this.$route.params.id, {
        categories: [11, 12]
      });
      this.drills = res.exams;
    },
    addStudent() {
      let selectedStudent = this.students[this.selectedStudent];
      let isIn = false;
      this.student_users.forEach(student => {
        if (selectedStudent.id === student.id) {
          isIn = true;
        }
      });
      if (isIn) {
        this.$message({
          message: "Cannot be added repeatedly!",
          type: "warning"
        });
      } else {
        this.student_users.push(selectedStudent);
        this.showStudents = false;
      }
    },
    removeStudent(index) {
      console.log(index);
      this.student_users.splice(index, 1);
    },
    addTeacher() {
      let selectedTeacher = this.teachers[this.selectedTeacher];
      let isIn = false;
      this.teacher_users.forEach(student => {
        if (selectedTeacher.id === student.id) {
          isIn = true;
        }
      });
      if (isIn) {
        this.$message({
          message: "Cannot be added repeatedly!",
          type: "warning"
        });
      } else {
        this.teacher_users.push(selectedTeacher);
        this.showTeachers = false;
      }
    },
    showTeachersWindow() {
      this.showTeachers = true;
      this.selectedTeacher = null;
    },
    showStudentWindow() {
      this.showStudents = true;
      this.selectedStudent = null;
    },
    removeTeacher(index) {
      this.teacher_users.splice(index, 1);
    },
    addNewExam(type) {
      this.classExams.push({
        type: type,
        exam_id: null,
        which_week: this.classExams.length,
        exam_date: null,
        hide_exp: 0
      });
    },
    removeExam(index) {
      this.classExams.splice(index, 1);
    },
    async searchUsers(query) {
      if (this.isAdmin) {
        this.searchAdminUsers(query);
      }
      if (this.isCompany) {
        this.searchCompanyUsers(query);
      }
    },
    async searchTeachers(query) {
      if (this.isAdmin) {
        this.searchAdminTeachers(query);
      }
      if (this.isCompany) {
        this.searchCompanyTeachers(query);
      }
    },
    async searchAdminUsers(query) {
      const students = await SAT.getStudents({
        keyword: query !== "" ? query : null,
        per_page: 50,
        sort: "name",
        page: this.page
      });
      this.students = students.users.data;
    },

    async searchCompanyUsers(query) {
      const students = await Company.getUsers({
        keyword: query !== "" ? query : null,
        per_page: 50,
        sort: "name",
        page: this.page
      });
      this.students = students.users.data;
    },
    async searchAdminTeachers(query) {
      const students = await SAT.getStudents({
        keyword: query !== "" ? query : null,
        per_page: 50,
        page: this.page,
        sort: "name",
        roles: "teacher"
      });
      this.teachers = students.users.data;
    },
    async searchCompanyTeachers(query) {
      const res = await Company.listCompanyTeachers(this.companyId, {
        keyword: query !== "" ? query : null,
        per_page: 50,
        page: this.page
      });
      this.teachers = res.users.data;
    },
    async getClass(id) {
      const res = await Class.getClassDetail(id);
      this.sessionClass["name"] = res["name"];
      this.sessionClass["description"] = res["description"];
      // this.sessionClass["is_online"] = res["is_online"];
      this.sessionClass["started_time"] = res["started_time"];
      this.sessionClass["ended_time"] = res["ended_time"];
      this.classDate = [
        res["started_at"] ? res["started_at"] : "",
        res["ended_at"] ? res["ended_at"] : ""
      ];
      this.teacher_users = res["users"];
      this.student_users = res["users"];
      // this.old_teacher_users = JSON.parse(JSON.stringify(res["teacher_users"]));
    },
    createNew(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          this.sessionClass.started_at = this.classDate[0];
          this.sessionClass.ended_at = this.classDate[1];
          let class_id = null;
          if (this.isEdit) {
            class_id = this.classId;
            await Class.updateClass(this.classId, this.sessionClass);
          } else {
            const res = await Class.createClass(this.sessionClass);
          }
          this.$store.commit("router/SET_EDITING", false);
          this.$message({
            message: "Success!",
            type: "success"
          });
          this.$router.go(-1);
        } else {
          // if (!this.teacher_users || this.teacher_users.length < 1) {
          //   this.$message.error("Choose at least one teacher!");
          // } else {
          //   console.log("error submit!!");
          // }
          // return false;
        }
      });
    }
  }
};
</script>

<style scoped>
::v-deep .select-popper {
  z-index: 9999 !important;
  top: auto !important;
  left: auto !important;
}
.box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding-top: 60px;
}
.box .left-menu {
  width: 200px;
  height: 100%;
}
.box .right-content {
  background: #fafafa;
}
.right-content-detail {
  width: inherit;
  margin-left: 72px;
  margin-right: 72px;
  margin-top: 20px !important;
  padding-bottom: 20px;
}
.button-group {
  padding: 1rem 20px;
}
::v-deep .el-menu {
  border: none;
}
::v-deep .button-group .el-button {
  width: 100%;
  margin: 0.5rem 0;
}
.right-content {
  flex: 1;
  height: 100%;
  overflow: auto;
}
</style>

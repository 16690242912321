import ApiService from "@/common/api.service";

export default {
  getUsers(data) {
    return ApiService.query("users", data);
  },
  postAIExplanation(questionID, data) {
    return ApiService.post(`/ai/questions/${questionID}/ask`, data);
  }
};

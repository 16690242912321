var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"act-box"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"left-side-menu"},[_c('div',{staticClass:"slimScrollDiv mm-active",staticStyle:{"position":"relative","overflow":"hidden","width":"auto","height":"1129px"}},[_c('div',{staticClass:"slimscroll-menu mm-show",staticStyle:{"overflow":"hidden","width":"auto","height":"1129px"}},[_c('div',{staticClass:"mm-active",attrs:{"id":"sidebar-menu"}},[_c('ul',{staticClass:"metismenu mm-show",attrs:{"id":"side-menu"}},[_c('li',[_c('router-link',{class:_vm.$route.name === 'SATList' ? 'active' : '',attrs:{"to":{
                    name: 'SATList'
                  }}},[_c('i',{staticClass:"dripicons-pencil"}),_c('span',[_vm._v(" Take SAT Tests ")])])],1),_c('li',[_c('router-link',{class:_vm.$route.name === 'SATHistory' ? 'active' : '',attrs:{"to":{
                    name: 'SATHistory'
                  }}},[_c('i',{staticClass:"dripicons-pamphlet"}),_c('span',[_vm._v(" SAT Exam History ")])])],1)])]),_c('div',{staticClass:"clearfix"})]),_c('div',{staticClass:"slimScrollBar",staticStyle:{"background":"rgb(158, 165, 171)","width":"8px","position":"absolute","top":"0px","opacity":"0.4","display":"none","border-radius":"7px","z-index":"99","right":"1px","height":"1129px"}}),_c('div',{staticClass:"slimScrollRail",staticStyle:{"width":"8px","height":"100%","position":"absolute","top":"0px","display":"none","border-radius":"7px","background":"rgb(51, 51, 51)","opacity":"0.2","z-index":"90","right":"1px"}})])]),_c('div',{staticClass:"content-page",staticStyle:{"height":"auto !important","min-height":"0px !important"}},[_c('div',{staticClass:"phone-menu"},[_c('el-menu',{staticClass:"el-menu-demo",attrs:{"default-active":_vm.$route.name,"mode":"horizontal","router":true},on:{"select":_vm.handleSelect}},[_c('el-menu-item',{attrs:{"index":"SATList","route":{
              name: 'SATList'
            }}},[_c('span',{staticClass:"text-success"},[_vm._v("Take SAT Tests")])]),_c('el-menu-item',{attrs:{"index":"SATHistory","route":{
              name: 'SATHistory'
            }}},[_c('span',{staticClass:"text-success"},[_vm._v("SAT Exam History")])])],1)],1),_c('div',{staticClass:"container-fluid",staticStyle:{"height":"auto !important"}},[_c('router-view')],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div >
      <!-- <el-header>
        <Header :theme="theme"></Header>
      </el-header> -->
      <div class="box-content">
        <div class="left-menu">
          <div @click="isCollapse = !isCollapse" class="show-left">
            <h3 class="text-right m-0">
              <span v-show="isCollapse">
                <i class="fas fa-angle-double-left"></i>
              </span>
              <span v-show="!isCollapse">
                <i class="fas fa-angle-double-right"></i>
              </span>
            </h3>
          </div>
          <div v-show="isCollapse" class="collapse-box">
            <el-menu :default-active="activeRoute" @select="selectPage">
              <el-menu-item index="CompanyInfo">
                <i class="el-icon-house"></i>
                <b>Info</b>
              </el-menu-item>
              <!-- <el-menu-item index="CompanyClasses">
                <i class="el-icon-collection" />
                <b>{{ $t("TeacherCourses.Classes Management") }}</b>
              </el-menu-item> -->
              <el-menu-item index="CompanyUsers">
                <i class="el-icon-user" />
                <b>Students</b>
              </el-menu-item>
              <el-menu-item index="CompanyPoints">
                <i class="el-icon-coin" />
                <b>Coins</b>
              </el-menu-item>
            </el-menu>
          </div>
        </div>
        <div class="right-content">
          <div class="right-content-detail">
            <el-card shadow="never"> <router-view /></el-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { instant } from "@ivy-way/material";
import Common from "@/mixins/common.js";
import Theme from "@/common/theme";
import Header from "@/views/company/header/Index.vue";


export default {
  metaInfo() {
    return {
      title: "Company - " + this.CompanyName
    };
  },

  components: {},

  mixins: [Common],

  props: [],
  data() {
    return {
      isCollapse: true
    };
  },
  computed: {
    activeRoute() {
      return this.$route.name;
    },
    instant() {
      return instant;
    },
    ...mapState("user", ["token", "lang"]),
    isLogin() {
      return this.token !== "";
    },
    theme() {
      return Theme;
    }
  },
  watch: {},

  async mounted() {},

  methods: {
    openMenu(route){
      console.log(route);
    },
    selectPage(route) {
      if (route === "SatAddDrillPractice") {
        this.$router.replace({
          name: "SatAddPractice",
          query: { type: "drill" }
        });
      } else if (route === "SatManagementPractice") {
        this.$router.replace({
          name: "CompanySatManagement",
          query: { type: "practice" }
        });
      } else if (route === "SatManagementCompose") {
        this.$router.replace({
          name: "CompanySatManagement",
          query: { type: "composePractice" }
        });
      } else if (route === "SatManagementAdaptive") {
        this.$router.replace({
          name: "CompanySatManagement",
          query: { type: "adaptive" }
        });
      } else if (route === "SatManagementDrill") {
        this.$router.replace({
          name: "CompanySatManagement",
          query: { type: "drill" }
        });
      } else {
        this.$router.replace({
          name: route
        });
      }
    }
  }
};
</script>

<style scoped>
.box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  /* padding-top: 60px; */
}
.box-content {
  display: flex;
  height: 100%;
}
.box-ivyway {
  padding-top: 100px;
}
.box .left-menu {
  background: #fff;
  max-width: 200px;
  height: 100%;
}
.collapse-box {
  min-width: 200px;
}
.box .right-content {
  background: #fafafa;
}
.right-content-detail {
  width: inherit;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px !important;
  padding-bottom: 20px;
}
.button-group {
  padding: 0 20px;
}
::v-deep .el-menu {
  border: none;
}
::v-deep .button-group .el-button {
  width: 100%;
  margin: 0.5rem 0;
}
.right-content {
  flex: 1;
  height: 100%;
  overflow: auto;
}
.show-left {
  color: #86909c;
  padding: 10px 20px;
  padding-bottom: 0;
  cursor: pointer;
}
</style>

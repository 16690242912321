var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.testType == 'SAT')?[(_vm.userExam.finished_at)?_c('SAT',{attrs:{"tscore":_vm.userExam.score,"examId":_vm.examId,"userExam":_vm.userExam,"resultRoute":"SATResultPage"}}):_c('router-link',{attrs:{"to":{
        name: 'SATTest',
        params: {
          examId: _vm.examId,
          userExamId: _vm.userExam.id
        }
      },"target":"_blank"}},[_c('el-button',{staticStyle:{"width":"100%"},attrs:{"type":"warning"}},[_c('i',{staticClass:"fa fa-play"}),_vm._v(" Resume ")])],1)]:_vm._e(),(_vm.testType == 'ACT')?[(_vm.userExam.finished_at)?_c('ACT',{attrs:{"examId":_vm.examId,"userExam":_vm.userExam,"resultRoute":"ACTResultPage"}}):_c('router-link',{attrs:{"to":{
        name: 'ACTTest',
        params: {
          examId: _vm.examId,
          userExamId: _vm.userExam.id
        }
      },"target":"_blank"}},[_c('el-button',{staticStyle:{"width":"100%"},attrs:{"type":"warning"}},[_c('i',{staticClass:"fa fa-play"}),_vm._v(" Resume ")])],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
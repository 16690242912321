<template>
  <div>
    <el-dialog :visible.sync="showDialog"  :width="isPhone?'95%' : '80%'" class="p-0">
      <h4 slot="title">
        <div class="plans-title plan-max-button ">
          Education Max Plan For Educator
        </div>
      </h4>
      <el-card v-if="!isMax">
        <div>
          <div class="plan-options">
            <b>Key Features:</b>
            <div
              class="plan-option"
              v-for="(item, index) in maxList"
              :key="index"
            >
              <span class="icon">
                <i class="fa fa-check"></i>
              </span>
              <span>
                <span v-show="index > 8">
                  <b class="logo-color mr-1">AI</b>
                </span>
                <span v-html="item"></span>
              </span>
            </div>
          </div>
          <hr />
          <div>
            <div class="mb-2"><b>Validity period</b></div>
            <el-date-picker
              style="width: 100%"
              v-model="planDate"
              type="daterange"
              range-separator="~"
              :start-placeholder="$t('TeacherCourses.Start Date')"
              :end-placeholder="$t('TeacherCourses.End Date')"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </div>
        <hr />
        <div>
          <el-button type="primary" @click="getMaxPayUrl" style="width: 100%;" :disabled="planDate.length < 2">
            Unlock
          </el-button>
        </div>
      </el-card>
      <el-card class="mt-4" v-if="myPlans && myPlans.length > 0">
        <Heading content="My Plans" heading="h6"></Heading>
        <hr />
        <MyPlans :myPlans="myPlans" :forAdmin="true"></MyPlans>
      </el-card>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import MyPlans from "@/views/profile/components/MyPlans.vue";
import plansApi from "@/apis/plans";

export default {
  metaInfo() {},

  components: { MyPlans },

  mixins: [],

  props: ["userNow"],
  data() {
    return {
      planDate: [],
      showDialog: false,
      maxList: [
        "Access to All Students' Features",
        "<b>20</b> Full Length SATs",
        "<b>40</b> Full Length ACTs",
        "<b>20</b> Full Length APs (Coming Soon)",
        "<b>6</b> Full Length PSATs (Coming Soon)",
        "<b>50</b> Full Length TOEFLs (Coming Soon)",
        "<b>40</b> Full Length IELTSs (Coming Soon)",
        "Create and Manage Classes",
        "Assign Tests and Homework",
        "Proctor Tests with Intelligent Cheating Intervention",
        "Create Unlimited Questions and Tests with AI",
        "Upload Custom Questions and Tests",
        "Smart Performance Reports",
        "Dynamic Progress Tracker",
        "Adaptive Assignment Creator",
        "Engagement Analytics",
        "Predictive Score Insights",
        "Smart Curriculum Builder",
        "Integrate With Your Curriculum"
      ]
    };
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    myPlans() {
      if (this.userNow) {
        return this.userNow.subscriptions;
      } else {
        return [];
      }
    },
    isMax() {
      let isTrue = false;
      this.myPlans.forEach(item => {
        if (item.subscription_plan_id === 6) {
          isTrue = true;
        }
      });
      return isTrue;
    }
  },
  watch: {},

  mounted() {},

  methods: {
    async getMaxPayUrl() {
      let plan_id = 6;
      const res = await plansApi.subscriptionForUser(this.userNow.id, {
        subscription_plan_id: plan_id,
        period_start: this.planDate[0],
        period_end: this.planDate[1]
      });
      this.showDialog = false;
      this.$emit("updateUsers");
    },
    selectTeacher() {
      this.showDialog = true;
    }
  }
};
</script>

<style scoped>
.plan-options {
  font-size: 14px;
}
.plan-option {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  margin: 10px 0;
}
.plan-option .icon {
  font-weight: 700;
  margin-right: 0.8rem;
}

::v-deep .el-dialog__body {
  padding-top: 0;
  /* padding-bottom: 0; */
}

.free-color {
  color: #01b884;
}
.pro-color {
  color: #fa17e3;
}
.max-color {
  color: #1f7cff;
}

@media screen and (max-width: 768px) {
  .plan-container {
    max-width: 100%;
    padding: 0 15px 20px 15px;
  }
  .plan-cover {
    max-width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }
  .plan-item {
    width: 35rem !important;
    word-break: normal !important;
  }
  .plan-option {
    font-size: 1rem;
    line-height: 2rem;
    margin: 2px 0;
  }
  .switch-date {
    width: 100%;
  }
}
</style>

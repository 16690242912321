<template>
  <div>
    <el-form ref="form" label-width="100px">
      <!-- <el-form-item :label="$t('AddPassage.Method')" v-if="!isEdit">
        <el-radio-group v-model="howToHandlePassages">
          <el-radio label="chooseExistPassage" border>
            {{ $t("AddPassage.Choose from an existing passage") }}
          </el-radio>
          <el-radio label="createNewPassage" border>
            {{ $t("AddPassage.Create a new passage") }}
          </el-radio>
        </el-radio-group>
      </el-form-item> -->
      <template
        v-if="
          howToHandlePassages === 'createNewPassage' ||
            (questionId && questionId > 0)
        "
      >
        <el-form-item label="Title" v-if="isNew === 0">
          <el-input
            placeholder="Title"
            :value="passage.title"
            @input="title => $emit('setPassage', { title })"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="Intro" v-if="isNew === 0 && passage.introduction">
          <Ckeditor
            :value="passage.introduction.intro"
            @input="
              intro =>
                $emit('setPassage', {
                  introduction: {
                    intro: intro,
                    below_content: passage.introduction.below_content
                  }
                })
            "
          >
          </Ckeditor>
        </el-form-item>
        <el-form-item label="Passage">
          <div slot="label">
            <div>
              Passage
            </div>
          </div>

          <div>
            <h5 v-if="!isNew">Passage I</h5>
            <Ckeditor
              :enableQuestionNumStyle="!isNew"
              :value="passage.content"
              @input="content => $emit('setPassage', { content: content })"
            />
          </div>
          <div class="mt-3" v-if="showPassage2">
            <h5>Passage II</h5>
            <Ckeditor
              :enableQuestionNumStyle="true"
              :value="passage.introduction.below_content"
              @input="
                below_content =>
                  $emit('setPassage', {
                    introduction: {
                      intro: passage.introduction.intro,
                      below_content: below_content
                    }
                  })
              "
            />
          </div>
          <div class="mt-3" v-else>
            <el-button
              v-if="!isNew"
              type="success"
              @click="showPassage2 = true"
            >
              Add Passage II
            </el-button>
          </div>
        </el-form-item>
      </template>
    </el-form>
    <template
      v-if="
        howToHandlePassages === 'chooseExistPassage' &&
          !(questionId && questionId > 0)
      "
    >
      <el-input
        :placeholder="$t('AddPassage.Type keyword here')"
        v-model="keyWord"
      >
        <el-button slot="append" icon="el-icon-search" @click="searchPassages">
          Search
        </el-button>
      </el-input>
      <hr />
      <el-table :data="passages" border>
        <el-table-column prop="content" label="Passages">
          <template slot-scope="scope">
            <b>{{ scope.row.title }}</b>
            <div v-html="scope.row.content"></div>
            <div v-html="scope.row.introduction.below_content"></div>
          </template>
        </el-table-column>
        <el-table-column label="Action" width="120">
          <template slot-scope="scope">
            <el-button
              v-if="questionId !== scope.row.id"
              @click="$emit('selectPassage', scope.row)"
              type="success"
              size="small"
            >
              <i class="fa fa-plus"></i> Select
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </div>
</template>

<script>
// import Ckeditor from "@/components/ckeditor/index.vue";
import Ckeditor from "@/components/ckeditor/ckeditor5.vue";
import SAT from "@/apis/sat.js";

export default {
  components: {
    Ckeditor
    // Ckeditor5
  },
  props: ["passage", "questionId", "isNew", "isEdit"],
  computed: {},
  data() {
    return {
      loading: false,
      howToHandlePassages: "createNewPassage",
      searchVal: "",
      visibleSearchPassageDialog: false,
      visiblePreviewPassageDialog: false,
      searchedPassages: [],
      previewPassage: {},
      showPassage2: false,
      keyWord: null,
      passages: []
    };
  },
  watch: {
    passage: {
      deep: true,
      handler() {
        this.setMath("ivy-question-content");
      }
    }
  },
  mounted() {
    this.setMath("ivy-question-content");
  },
  created() {
    if (this.passage.introduction.below_content) {
      this.showPassage2 = true;
    }
  },
  methods: {
    setMath(ref) {
      this.$nextTick(() => {
        let tag = this.$refs[ref];
        if (this.$mathJaxSAT.isMathjaxConfig) {
          this.$mathJaxSAT.initMathjaxConfig();
        }
        if (tag) {
          this.$mathJaxSAT.MathQueue([tag]);
        }
      });
    },
    async searchPassages() {
      const res = await SAT.searchAllPassages({
        search: this.keyWord
      });
      this.passages = res.data;
    },
    toggleSearchPassage(status) {
      this.visibleSearchPassageDialog = status;
    }
  }
};
</script>

<style scope>
kbd {
  background-color: #000;
  border-color: #000;
  color: #fff;
  padding: 0 10px;
}
</style>
<style scoped>
.line {
  margin-top: 10px;
  margin-bottom: 15px;
}
::v-deep .ck.ck-content {
  max-height: 400px;
}
/* .explanation {
  margin: 20px 0px;
} */

/* .explanationText > li {
  color: #000;
} */
::v-deep .el-table__row .cell {
  max-height: 200px;
  overflow: auto;
}
::v-deep .el-alert .el-alert__description {
  font-size: 14px;
}
::v-deep .sat-material-questionBox-MultipleChoiceWithPassage,
::v-deep .sat-material-circleButton-MultipleChoiceWithPassage {
  display: none;
}
::v-deep .sat-material-container-MultipleChoiceWithPassage {
  display: block;
}
::v-deep .sat-material-passageBox-MultipleChoiceWithPassage {
  width: 100%;
}
::v-deep .sat-material-explanationPassage-MultipleChoiceWithPassage,
::v-deep .sat-material-explanationQuestion-MultipleChoiceWithPassage {
  height: auto !important;
}
::v-deep
  .sat-material-explanationWrap-MultipleChoiceWithPassage
  .sat-material-passageTitle-MultipleChoiceWithPassage {
  margin-top: 0;
}
::v-deep .MathJax .mrow {
  font-size: 1.2rem !important;
}
::v-deep .ck-content {
  min-height: 200px;
  max-height: 400px;
  overflow: auto;
}
</style>

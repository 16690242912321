var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-tabs',{staticClass:"mt-4",attrs:{"type":"border-card"}},[_c('el-tab-pane',{attrs:{"label":_vm.$t('AIQuestions.Computer Preview')}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('i',{staticClass:"fas fa-laptop"}),_vm._v(" "+_vm._s(_vm.$t("AIQuestions.Computer Preview")))]),_c('div',{ref:"tab-pc",staticClass:"tab-pc"},[(_vm.question && _vm.question.type === 'single')?[(
              _vm.passage !== null &&
                _vm.passage.content !== null &&
                _vm.passage.content !== ''
            )?_c('MultipleChoiceWithPassage',{attrs:{"passageIntro":_vm.passage &&
              _vm.passage.introduction &&
              _vm.passage.introduction.length > 0
                ? _vm.passage.introduction[0]
                : null,"passageContent":_vm.passage.content,"content":_vm.question.content,"options":_vm.getOptions(_vm.question.options),"explanation":_vm.question.explanations.length > 0
                ? _vm.question.explanations[0].explanation
                : '-',"correctAnswer":_vm.getCAnswers(_vm.question.answers),"showMark":false,"mode":"explanation"}}):_c('MultipleChoice',{attrs:{"content":_vm.question.content,"options":_vm.getOptions(_vm.question.options),"explanation":_vm.question.explanations.length > 0
                ? _vm.question.explanations[0].explanation
                : '-',"correctAnswer":_vm.getCAnswers(_vm.question.answers),"showMark":false,"mode":"explanation"}})]:_vm._e(),(_vm.question && _vm.question.type === 'math')?_c('Math',{attrs:{"mode":"explanation","content":_vm.question.content,"options":_vm.getOptions(_vm.question.options),"explanation":_vm.question.explanations.length > 0
              ? _vm.question.explanations[0].explanation
              : '-',"correctAnswer":_vm.getCAnswers(_vm.question.answers),"showMark":false}}):_vm._e()],2)]),_c('el-tab-pane',{attrs:{"label":_vm.$t('AIQuestions.Mobile Preview')}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('i',{staticClass:"fas fa-mobile-alt"}),_vm._v(" "+_vm._s(_vm.$t("AIQuestions.Mobile Preview")))]),_c('div',{ref:"tab-phone-box",staticClass:"tab-phone-box"},[_c('div',{staticClass:"tab-phone"},[(_vm.question && _vm.question.type === 'single')?[(
                _vm.passage !== null &&
                  _vm.passage.content !== null &&
                  _vm.passage.content !== ''
              )?_c('MultipleChoiceWithPassage_M',{attrs:{"passageIntro":_vm.passage &&
                _vm.passage.introduction &&
                _vm.passage.introduction.length > 0
                  ? _vm.passage.introduction[0]
                  : null,"passageContent":_vm.passage.content,"content":_vm.question.content,"options":_vm.getOptions(_vm.question.options),"explanation":_vm.question.explanations.length > 0
                  ? _vm.question.explanations[0].explanation
                  : '-',"correctAnswer":_vm.getCAnswers(_vm.question.answers),"showMark":false,"mode":"explanation"}}):_c('MultipleChoice_M',{attrs:{"content":_vm.question.content,"options":_vm.getOptions(_vm.question.options),"explanation":_vm.question.explanations.length > 0
                  ? _vm.question.explanations[0].explanation
                  : '-',"correctAnswer":_vm.getCAnswers(_vm.question.answers),"showMark":false,"mode":"explanation"}})]:_vm._e(),(_vm.question && _vm.question.type === 'math')?_c('Math_M',{attrs:{"mode":"explanation","content":_vm.question.content,"options":_vm.getOptions(_vm.question.options),"explanation":_vm.question.explanations.length > 0
                ? _vm.question.explanations[0].explanation
                : '-',"correctAnswer":_vm.getCAnswers(_vm.question.answers),"showMark":false}}):_vm._e()],2)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from "vue";

import App from "@/App.vue";

import router from "@/router";
import store from "@/store";
import Theme from "@/common/theme";

import i18n from "@/i18n";
import htmlToPdfShort from "@/mixins/htmlToPdfShort.js";
import htmlToPdf from "@/mixins/htmlToPdf.js";
Vue.use(htmlToPdfShort);
Vue.use(htmlToPdf);

import ApiService from "@/common/api.service";
ApiService.init();

import ElementUI from "@/plugin/elementui.js";
import locale from "element-ui/lib/locale/lang/en";
Vue.use(ElementUI, { locale });

//手机号组件
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

const TelOptions = {
  autoFormat: false,
  dropdownOptions: {
    disabled: false,
    showDialCodeInList: true,
    showDialCodeInSelection: true,
    showFlags: true,
    showSearchBox: true
  }
};
Vue.use(VueTelInput, TelOptions);

//全局的通用組件
import commonComponents from "./commonComponents";
Vue.use(commonComponents);

import satMaterial from "@ivy-way/sat-material";
import "@ivy-way/sat-material/dist/@ivy-way/sat-material.css";
Vue.use(satMaterial);
/*
  一些 hidden-尺寸 的 class
  https://element.eleme.io/#/en-US/component/layout#utility-classes-for-hiding-elements
*/

import MathJax from "@/common/mathJax";
Vue.prototype.$mathJaxSAT = MathJax;

// console.log(ThemeDetail);
//主題色的參數
var themeColor = "";
var canRegister = true;
var CompanyName = "";
var CompanyExamName = "";
var CompanyEmail = "";
var isCompany = false;
console.log(Theme);
console.log(window.location.hostname);
if (Theme.name === "Ivy-Way") {
  import("@/assets/scss/ivy-element/themes/Ivy-Way.scss");
  import("@/assets/scss/sat-material/Ivy-Way.css");
} else if (Theme.name === "SAT") {
  import("@/assets/scss/ivy-element/themes/SAT.scss");
  import("@/assets/scss/sat-material/SAT.css");
} else if (Theme.name === "Primerica Institute") {
  import("@/assets/scss/ivy-element/themes/Primerica.scss");
  import("@/assets/scss/sat-material/Primerica.css");
} else if (Theme.name === "2400 SAT Learning Center") {
  import("@/assets/scss/ivy-element/themes/SATCenter.scss");
  import("@/assets/scss/sat-material/SATCenter.css");
} else if (Theme.name === "Taoyuan American School") {
  import("@/assets/scss/ivy-element/themes/Taoyuan.scss");
  import("@/assets/scss/sat-material/Taoyuan.css");
} else {
  import("@/assets/scss/ivy-element/themes/SAT.scss");
  import("@/assets/scss/sat-material/SAT.css");
}
isCompany = Theme.isCompany;
themeColor = Theme.themeColor;
CompanyName = Theme.tabName;
CompanyEmail = Theme.email;
CompanyExamName = Theme.examName;

// 主要是 helper class，沒有全加
import "@/assets/scss/ivy-bootstrap.scss";

import VueMeta from "vue-meta";
Vue.use(VueMeta);

// 加入 CKEditor
import CKEditor from "ckeditor4-vue";
Vue.use(CKEditor);

// 加入 CKEditor5
import CKEditor5 from "@ckeditor/ckeditor5-vue2";
Vue.component("ckeditor5", CKEditor5.component);

Vue.mixin({
  methods: {
    routerPush(route) {
      this.$router.push(route);
    },
    historyBack() {
      this.$router.go(-1);
    }
  }
});
let isPhone = false;
if (document.body.clientWidth <= 768) {
  isPhone = true;
}
Vue.prototype.isPhone = isPhone;

Vue.config.productionTip = false;
Vue.prototype.themeColor = themeColor;
Vue.prototype.canRegister = canRegister;
Vue.prototype.recorderGet = null;
Vue.prototype.CompanyName = CompanyName;
Vue.prototype.CompanyExamName = CompanyExamName;
Vue.prototype.CompanyEmail = CompanyEmail;
Vue.prototype.isCompany = isCompany;
Vue.prototype.showAIChat = false;

Vue.prototype.getCompanyExamTitle = function(title) {
  return title.replace(
    "SATMocks",
    this.CompanyExamName ? this.CompanyExamName : ""
  );
};
Vue.prototype.getSourceTitle = function(title) {
  if(title){
    return title.replace(this.CompanyExamName, "SATMocks");
  }else  {
    return title;
  }
};

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-tabs',{staticClass:"mt-4",attrs:{"type":"border-card"}},[_c('el-tab-pane',{attrs:{"label":_vm.$t('AIQuestions.Computer Preview')}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('i',{staticClass:"fas fa-laptop"}),_vm._v(" "+_vm._s(_vm.$t("AIQuestions.Computer Preview")))]),_c('div',{ref:"tab-pc",staticClass:"tab-pc"},[_c('MultipleChoiceWithPassageACT',{ref:"MultipleChoiceWithPassage",attrs:{"id":1,"order":"1","questionId":_vm.question.id,"testType":1,"canHighlighter":false,"canEliminator":false,"canMasking":false,"passageContent":_vm.passage.content,"questionOrder":"1","content":_vm.question.content,"options":_vm.question.options,"answer":``,"tags":_vm.question.question_tags ? _vm.question.question_tags : [],"activities":[],"isCorrect":true,"correctAnswer":_vm.question.answers && _vm.question.answers.length > 0
              ? _vm.question.answers[0].answer[0]
              : '',"explanation":_vm.question.explanations.length > 0
              ? _vm.question.explanations[0].explanation
              : '',"mode":"preview"}})],1)]),_c('el-tab-pane',{attrs:{"label":_vm.$t('AIQuestions.Mobile Preview')}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('i',{staticClass:"fas fa-mobile-alt"}),_vm._v(" "+_vm._s(_vm.$t("AIQuestions.Mobile Preview")))]),_c('div',{ref:"tab-phone-box",staticClass:"tab-phone-box"},[_c('div',{staticClass:"tab-phone"},[_c('MultipleChoiceWithPassageACT',{ref:"MultipleChoiceWithPassage",attrs:{"id":1,"questionId":_vm.question.id,"testType":1,"canHighlighter":false,"canEliminator":false,"canMasking":false,"passageContent":_vm.passage.content,"questionOrder":"1","content":_vm.question.content,"options":_vm.question.options,"answer":_vm.question.answer,"tags":_vm.question.question_tags ? _vm.question.question_tags : [],"activities":[],"isCorrect":true,"correctAnswer":_vm.question.answers ? _vm.question.answers[0].answer[0] : '',"explanation":_vm.question.explanations.length > 0
                ? _vm.question.explanations[0].explanation
                : '',"isPhone":true,"mode":"preview"}})],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <template v-if="testType == 'SAT'">

      <SAT
        v-if="userExam.finished_at"
        :tscore="userExam.score"
        :examId="examId"
        :userExam="userExam"
        resultRoute="EducatorSATResultPage"
      />
      <router-link
        v-else
        :to="{
          name: 'EducatorSATResultPage',
          params: { userExamId: userExam.id }
        }"
        target="_blank"
      >
        <el-tag
          class="text-center"
          style="width: 12rem;"
          type="success"
          effect="plain"
        >
          <i class="fas fa-user-check"></i>
          <b v-if="userExam.metadata">
            {{ `Module: ${userExam.metadata.sectionOrder}` }},
            {{ `Question: ${userExam.metadata.questionOrder}` }}
          </b>
          <b v-else>
            Module: 1, Question: 1
          </b>
        </el-tag>
      </router-link>
    </template>
    <template v-if="testType == 'ACT'">
      <ACT
        v-if="userExam.finished_at"
        :examId="examId"
        :userExam="userExam"
        resultRoute="EducatorACTResultPage"
      />
      <router-link
        v-else
        :to="{
          name: 'EducatorACTResultPage',
          params: { userExamId: userExam.id }
        }"
        target="_blank"
      >
        <el-tag
          class="text-center"
          style="width: 12rem;"
          type="success"
          effect="plain"
        >
          <i class="fas fa-user-check"></i>
          <b v-if="userExam.metadata">
            {{ `Module: ${userExam.metadata.sectionOrder}` }},
            {{ `Question: ${userExam.metadata.questionOrder}` }}
          </b>
          <b v-else>
            Module: 1, Question: 1
          </b>
        </el-tag>
      </router-link>
    </template>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import SAT from "@/views/courses/tests/components/SatScore";
import ACT from "@/views/courses/tests/components/ActScore";

export default {
  metaInfo() {},

  components: { SAT, ACT },

  mixins: [],

  props: ["examId", "userExam", "testType", "role"],
  data() {
    return {};
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    }
  },
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped></style>

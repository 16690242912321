<template>
  <div class="container pt-3">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading
      heading="h4"
      :content="
        `${myTitle}${searchTest.testType ? ' for ' + searchTest.testType : ''}`
      "
    >
      <el-tooltip
        slot="button"
        class="item"
        effect="dark"
        content="Create"
        placement="top"
        v-show="false"
      >
        <router-link
          :to="{
            name: 'EducatorFullTestsAdd'
          }"
        >
          <el-button type="success" size="mini"
            ><i class="fa fa-plus"></i
          ></el-button>
        </router-link>
      </el-tooltip>
    </Heading>
    <!-- <h4 class="m-0">
      {{ myTitle }}
      for&nbsp;
      <span class="test-color" v-if="searchTest.testType">
        {{ searchTest.testType }}
      </span>
    </h4> -->
    <hr />
    <el-form
      class="mt-4"
      :model="searchTest"
      label-position="right"
      label-width="80px"
      @submit.native.prevent
    >
      <el-form-item label="Test">
        <el-radio
          v-for="(test, index) in Tests"
          :key="index"
          v-model="searchTest.testType"
          @input="search"
          :label="test"
          border
        >
          <b class="test-color">{{ test }}</b></el-radio
        >
      </el-form-item>
      <el-form-item label="Source">
        <el-radio
          v-for="(source, index) in sources"
          :key="index"
          v-model="searchTest.source"
          @input="search"
          :label="source.value"
          border
        >
          <b>{{ source.name }}</b></el-radio
        >
      </el-form-item>
      <el-form-item label="Keywords">
        <el-input
          ref="filterInput"
          placeholder="Name"
          v-model="searchTest.search"
          @keyup.enter.native="search"
        >
          <el-button
            el-button
            slot="append"
            icon="el-icon-search"
            @click="search"
          >
            Search
          </el-button>
        </el-input>
      </el-form-item>
      <!-- <el-form-item label="發佈狀態">
      </el-form-item> -->
    </el-form>
    <template v-if="practices">
      <hr />
      <el-alert v-if="resultsTotal > -1" type="success" :closable="false">
        <span slot="title">
          Results: <b>{{ resultsTotal }}</b>
        </span>
      </el-alert>
      <div class="text-center" style="margin:15px 0">
        <el-pagination
          :hide-on-single-page="true"
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="9"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
      <template v-if="practices.length > 0">
        <el-table :data="practices" style="width: 100%" stripe>
          <el-table-column label="Name">
            <template slot-scope="scope">
              <template v-if="scope.row.can_purchase">
                <router-link
                  :to="{
                    name: 'EducatorFullTestBrowse',
                    params: { id: scope.row.id }
                  }"
                >
                  <b>{{ scope.row.name }}</b>
                </router-link>
              </template>
              <span v-else style="opacity: 0.5;">{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Questions" width="100">
            <template slot-scope="scope">
              <template>
                <b class="text-success">{{ scope.row.question_count }}</b>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="Date Created" width="150">
            <template slot-scope="scope">
              {{ instant.formatGoogleTime(scope.row.created_at) }}
            </template>
          </el-table-column>
          <el-table-column width="400" label="Actions" fixed="right">
            <template slot-scope="scope">
              <template v-if="scope.row.can_purchase">
                <el-tooltip
                  class="mr-2"
                  effect="dark"
                  content="Preview"
                  placement="top"
                >
                  <router-link
                    :to="{
                      name: 'EducatorFullTestBrowse',
                      params: { id: scope.row.id }
                    }"
                  >
                    <el-button type="success" size="mini">
                      <i class="fas fa-eye"></i>
                    </el-button>
                  </router-link>
                </el-tooltip>
              </template>
              <template v-else>
                <router-link
                  :to="{
                    name: 'EducatorPlans'
                  }"
                >
                  <el-tooltip
                    effect="dark"
                    content="Subscribe to unlock all Tests."
                    placement="top"
                  >
                    <el-button
                      style="color: white !important;"
                      type="info"
                      size="mini"
                    >
                      <i class="fas fa-lock"></i>
                    </el-button>
                  </el-tooltip>
                </router-link>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <div v-else>
        <el-empty :description="$t('courses.empty-text')"></el-empty>
      </div>
      <div class="text-center" style="margin:15px 0">
        <el-pagination
          background
          :hide-on-single-page="true"
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="9"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
    </template>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import { instant } from "@ivy-way/material";
import SAT from "@/apis/sat.js";
import List from "@/views/tests/fullTests/apis/list.js";
import Common from "@/mixins/common.js";
import Theme from "@/common/theme";

export default {
  metaInfo() {
    return {
      title: this.myTitle + " - " + this.CompanyName
    };
  },

  components: { Breadcrumb },

  mixins: [Common],

  props: [],
  data() {
    return {
      Tests: ["SAT", "ACT"],
      searchTest: {
        testType: null,
        search: null,
        source: null
      },
      practices: null,
      resultsTotal: 0,
      pageSize: 0,
      subjects: [],
      companies: [],
      showLink: false
      // composePractices: null
    };
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    ...mapState("user", ["profile"]),
    sources() {
      if (this.searchTest.testType === "SAT") {
        return [
          {
            name: "All",
            value: null
          },
          {
            name: "XMocks",
            value: "xmocks"
          },
          {
            name: "Official",
            value: "official"
          },
          {
            name: "CB Khan",
            value: "cb"
          },
          {
            name: "Barron's",
            value: "barron"
          },
          {
            name: "Kaplan",
            value: "kaplan"
          },
          {
            name: "The Princeton Review",
            value: "princeton"
          }
        ];
      } else if (this.searchTest.testType === "ACT") {
        return [
          {
            name: "All",
            value: null
          },
          {
            name: "Official",
            value: "official"
          }
        ];
      } else {
        return [
          {
            name: "All",
            value: null
          }
        ];
      }
    },
    CreateLink() {
      let Link = "";
      if (this.searchTest.type === "drill") {
        if (this.isAdmin) {
          Link = {
            name: "SatAddPractice",
            query: { type: "drill" }
          };
        }
        if (this.isTeacher) {
          Link = {
            name: "TeacherSatAddPractice",
            query: { type: "drill" }
          };
        }
      } else if (this.searchTest.type === "practice") {
        if (this.isAdmin) {
          Link = {
            name: "SatAddPractice"
          };
        }
        if (this.isTeacher) {
          Link = {
            name: "TeacherSatAddPractice"
          };
        }
      } else if (this.searchTest.type === "adaptive") {
        Link = {
          name: "SatAddAdaptive"
        };
      } else if (this.searchTest.type === "composePractices") {
        Link = {
          name: "SatAddComposePractice"
        };
      }
      return Link;
    },
    myTitle() {
      return "Full Tests";
    },
    instant() {
      return instant;
    },
    isTeacher() {
      return this.$route.name === "TeacherSatManagement";
    },
    isCompany() {
      return this.$route.name === "CompanySatManagement";
    },
    isAdmin() {
      return this.$route.name === "SatManagement";
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    isNew() {
      let isNew = 1;
      if (this.$route.query.isNew === undefined) {
        isNew = 1;
      } else {
        isNew = parseInt(this.$route.query.isNew);
      }
      return isNew;
    },
    ...mapState("user", ["token", "lang"]),
    isLogin() {
      return this.token !== "";
    },
    company_id() {
      let company_id = null;
      if (this.$route.query.company_id) {
        company_id = this.$route.query.company_id;
      }
      return company_id;
    },
    subjectId() {
      let subjectId = null;
      if (this.$route.query.subject_id) {
        subjectId = this.$route.query.subject_id;
      }
      return subjectId;
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    },
    theme() {
      return Theme.name;
    }
  },
  watch: {},

  async mounted() {
    this.searchTest.testType = this.$route.query.testType
      ? this.$route.query.testType
      : "SAT";
    this.searchTest.search = this.$route.query.search
      ? this.$route.query.search
      : null;
    this.searchTest.source = this.$route.query.source
      ? this.$route.query.source
      : null;
    this.getFullTests();
  },

  methods: {
    async getFullTests() {
      const res = await List.getFullTests({
        test_name: this.searchTest.testType,
        exam_type_name: "Full Mock",
        resource_group_identifier: this.searchTest.source,
        page: this.page,
        per_page: 10,
        keyword: this.searchTest.search
      });
      this.practices = res.exams.data;
      this.resultsTotal = res.exams.total;
      this.pageSize = res.exams.per_page;
    },
    async getData() {
      let res = null;
      let edit_user_id = null;
      if (this.searchTest.type === "composePractices") {
        res = await SAT.getComposePractices({
          // edit_user_id,
          is_new: this.searchTest.isNew,
          page: this.page,
          per_page: 10,
          is_public: this.searchTest.status === "PUBLIC" ? 1 : 0,
          keyword: this.searchTest.search,
          company_id: this.searchTest.company_id,
          status: this.searchTest.status
          // category: this.searchTest.category
        });
        this.practices = res.compose_practice.data;
        this.resultsTotal = res.compose_practice.total;
        this.pageSize = res.compose_practice.per_page;
      } else if (
        this.searchTest.type === "practice" ||
        this.searchTest.type === "drill"
      ) {
        // if (this.searchTest.source === 4) {
        res = await SAT.getPractices({
          page: this.page,
          per_page: 10,
          status: this.searchTest.status,
          keyword: this.searchTest.search,
          company_id: this.searchTest.company_id,
          category: this.searchTest.category
        });
        this.practices = res.practices.data;
        this.resultsTotal = res.practices.total;
        this.pageSize = res.practices.per_page;
      }
    },
    async updatePublic(id, title, is_public) {
      if (
        this.searchTest.type === "practice" ||
        this.searchTest.type === "drill"
      ) {
        await SAT.updatePractice(id, {
          practice: {
            exam: {
              title: title,
              is_public: is_public
            }
          }
        });
      } else if (this.searchTest.type === "composePractices") {
        await SAT.updateComposePractice(id, {
          compose_practice: {
            exam: {
              title: title,
              is_public: is_public
            }
          }
        });
      }
      if (is_public === 1) {
        await this.$message({
          message: this.$t("management.Published!"),
          type: "success"
        });
      } else {
        await this.$message(this.$t("management.Moved to Drafts"));
      }
      this.getData();
    },
    async updateStatus(id, status) {
      await SAT.updateStatus(id, {
        status: status
      });
      await this.$message({
        message: this.$t("management.Published!"),
        type: "success"
      });
      this.getData();
    },
    async search(type) {
      if (type === "changeNew") {
        this.searchTest.subject = null;
      }
      this.$router.replace({
        params: {
          ...this.$route.params
        },
        query: {
          ...this.$route.query,
          ...this.searchTest,
          page: 1
        }
      });
    },
    setPage(page) {
      this.routerPush({
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    }
  }
};
</script>

<style scoped>
.referral-code span {
  display: inline-block;
  height: 41px;
  line-height: 41px;
  padding: 0 1rem;
  font-size: 1rem;
  background: rgb(241, 243, 244);
  border-radius: 4px;
}
::v-deep .class-link .el-dialog__body {
  padding-top: 0.2rem;
}
</style>

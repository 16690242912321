<template>
  <div>
    <Heading heading="h4" content="Tests">
      <router-link
        slot="button"
        :to="{
          name: 'EducatorClassMaterialsEdit',
          params: { id: $route.params.id }
        }"
      >
        <el-button type="primary" size="mini">
          <i class="fa fa-edit"></i>
        </el-button>
      </router-link>
    </Heading>
    <hr />
    <div v-if="tests">
      <div v-if="tests.length > 0">
        <el-card
          v-for="test in tests"
          :key="test.id"
          class="mb-4"
          shadow="never"
        >
          <h5 class="mb-2">
            <div class="d-flex">
              <div class="test-color mr-2">
                {{ test.teaching_resource.content.test.name }}
              </div>
              <div class="mr-2">
                {{ test.teaching_resource.content.name }}
              </div>
            </div>
          </h5>
          <div style="max-width: 1080px;">
            <el-table
              :data="test.user_progresses"
              style="width: 100%;"
              :key="test.id"
              max-height="250px"
            >
              <el-table-column prop="Students" label="Students" width="180">
                <template slot-scope="scope">
                  <b>
                    {{
                      user.displayName(
                        scope.row.user.first_name,
                        scope.row.user.last_name
                      )
                    }}
                  </b>
                </template>
              </el-table-column>
              <el-table-column prop="Score" label="Score">
                <template slot-scope="scope">
                  <template v-if="scope.row.user_exam">
                    <TestScore
                      :userExam="scope.row.user_exam"
                      :testType="test.teaching_resource.content.test.name"
                      role="Educator"
                    ></TestScore>
                  </template>
                  <div v-else>
                    <template v-if="scope.row.status === 'locked'">
                      <router-link
                        :to="{
                          name: 'CompanyUsers',
                          params: {
                            id: 'class'
                          }
                        }"
                      >
                        <el-tooltip
                          effect="dark"
                          :content="
                            `Unlock all ${test.teaching_resource.content.test.name} tests for student`
                          "
                          placement="top"
                        >
                          <el-button
                            type="info"
                            size="mini"
                            style="width: 12rem;"
                          >
                            <i class="fa fa-lock"></i>
                          </el-button>
                        </el-tooltip>
                      </router-link>
                    </template>
                    <template v-else>
                      <el-tag
                        style="width: 12rem;"
                        class="text-center"
                        type="info"
                        effect="plain"
                      >
                        <b>
                          <i class="fas fa-user"></i>
                          Pending
                        </b>
                      </el-tag>
                    </template>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="Test Date" label="Test Date" width="150">
                <template slot-scope="scope">
                  <template v-if="scope.row.user_exam">
                    <span>
                      {{
                        instant.formatGoogleTime(scope.row.user_exam.updated_at)
                      }}
                    </span>
                  </template>
                  <template v-else>
                    -
                  </template>
                </template>
              </el-table-column>
            </el-table>
            <div
              class="d-flex mt-2"
              style="align-items:center"
              v-if="isTeacher || isAdmin"
            >
              <h6 class="pl-2 mr-2 mb-0" style="width: 180px;">
                AVG
                <el-tooltip
                  class="item"
                  content="The average score only counts completed exercises."
                  placement="top"
                  popper-class="tooltipColor"
                >
                  <span class="action-icon">
                    <i class="fas fa-question-circle" />
                  </span>
                </el-tooltip>
              </h6>
              <OnlineSatScore
                v-if="scoreData[`Test${test.which_week}`].length > 0"
                :escore="
                  showScore(
                    scoreData[`Test${test.which_week}`].english_score,
                    scoreData[`Test${test.which_week}`].length
                  )
                "
                :mscore="
                  showScore(
                    scoreData[`Test${test.which_week}`].math_score,
                    scoreData[`Test${test.which_week}`].length
                  )
                "
                :tscore="
                  showScore(
                    scoreData[`Test${test.which_week}`].total_score,
                    scoreData[`Test${test.which_week}`].length
                  )
                "
                :question_count="0"
                :testType="0"
                :rscore_up="0"
                :gscore_up="0"
                :escore_up="0"
                :mscore_up="0"
                :score_up="0"
                :canEdit="false"
              ></OnlineSatScore>
            </div>
          </div>
          <!-- {{ test.content.name }} -->
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import Tests from "@/views/courses/tests/apis/tests.js";
import Common from "@/mixins/common.js";

import TestScore from "@/views/courses/tests/components/EducatorTestScore";

export default {
  metaInfo() {},

  components: { TestScore },

  mixins: [Common],

  props: [],
  data() {
    return {
      tests: null
    };
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    ...mapState("user", ["token", "lang"]),
    myTitle() {
      return "Tests";
    },
    instant() {
      return instant;
    },
    user() {
      return user;
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    }
  },
  watch: {},

  async mounted() {
    if (this.$route.params.id) {
      const res = await Tests.getTests(this.$route.params.id);
      this.tests = res;
    }
  },

  methods: {}
};
</script>

<style scoped></style>

<template>
  <div>
    <el-table :data="showPlans" style="width: 100%">
      <el-table-column label="Plan">
        <template slot-scope="scope">
          <b v-if="scope.row.subscription_plan.name === 'Free'">
            Basic
          </b>
          <b
            v-else-if="scope.row.subscription_plan.id === 6"
            class="test-color"
          >
            Education Max
          </b>
          <b v-else class="test-color">
            {{ scope.row.subscription_plan.name }}
          </b>
        </template>
      </el-table-column>
      <el-table-column label="Type">
        <template slot-scope="scope">
          <b v-if="scope.row.subscription_plan_price">
            {{ scope.row.subscription_plan_price.nickname }}
          </b>
          <b v-if="scope.row.source === 'organization'">
            Deposit from
            <span class="text-success">Teacher</span>
          </b>
          <b v-if="scope.row.source === 'super_admin'">
            Deposit from
            <span class="text-success">Admin</span>
          </b>
          <span v-if="scope.row.source === 'Free'">
            -
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Price" v-if="!forAdmin">
        <template slot-scope="scope">
          <b v-if="scope.row.subscription_plan_price"
            >${{ scope.row.subscription_plan_price.amount }}</b
          >
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="Validity period">
        <template slot-scope="scope">
          <template v-if="scope.row.period_start && scope.row.period_end">
            <b>{{ moment(scope.row.period_start).format("YYYY-MM-DD") }}</b>
            ~ <br />
            <b>{{ moment(scope.row.period_end).format("YYYY-MM-DD") }}</b>
          </template>
          <b v-if="scope.row.source === 'Free'">Free Forever</b>
        </template>
      </el-table-column>
      <el-table-column label="Next subscription date" v-if="!forAdmin">
        <template slot-scope="scope">
          <template v-if="scope.row.cancel_at_period_end === 0">
            <b>
              {{ moment(scope.row.period_end).format("YYYY-MM-DD") }}
            </b>
          </template>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="Automatic subscription" v-if="!forAdmin">
        <template slot-scope="scope">
          <template v-if="scope.row.cancel_at_period_end === 0">
            <b class="text-success">
              <i class="fa fa-check-circle"></i>
            </b>
          </template>
          <el-template v-else>
            <span>-</span>
          </el-template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import moment from "moment";

export default {
  metaInfo() {},

  components: {},

  mixins: [],

  props: ["myPlans", "forAdmin"],
  data() {
    return {
      moment
    };
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    showPlans() {
      let showPlan = [];
      if (this.myPlans) {
        showPlan = JSON.parse(JSON.stringify(this.myPlans));
        showPlan.unshift({
          subscription_plan: {
            name: "Free"
          },
          source: "Free",
          period_start: null,
          period_end: null
        });
      }
      return showPlan;
    }
  },
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped>
.free-color {
  color: #01b884;
}
</style>

<template>
  <div class="container pt-4">
    <Breadcrumb v-if="exam && isStudent">
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link :to="{ name: 'SATHistory' }">
            SAT Exam History
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-if="exam">
          <router-link
            :to="{
              name: 'SATResultPage',
              params: {
                userExamId: $route.params.userExamId
              }
            }"
          >
            {{ exam.name }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-if="exam">
          <kbd>{{ questionOrder }}</kbd>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          Explanation
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading
      v-if="exam"
      class="mb-3"
      :content="`${exam.name} - Explanation`"
    ></Heading>
    <div class="question-title" v-if="exam">
      <el-collapse v-model="sectionOrder" accordion>
        <el-collapse-item
          :name="practiceIndex + 1"
          v-for="(practice, practiceIndex) in practices"
          :key="practiceIndex"
        >
          <template slot="title">
            <h6 class="m-0">
              {{ titleCase(practice.name) }}
            </h6>
          </template>
          <div class="p-3">
            <div class="showTags">
              <div class="showTags-item">
                <span class="view_question" />
                <span style="line-height:1.8rem">Correct</span>
              </div>
              <div class="showTags-item">
                <span class="view_question wrong" />
                <span style="line-height:1.8rem">Wrong</span>
              </div>
              <div class="showTags-item">
                <span class="view_question empty" />
                <span style="line-height:1.8rem">Unanswered</span>
              </div>
            </div>
            <div>
              <span
                v-for="(title, index) in practice.exam_questions"
                @click="viewBrowse(practiceIndex + 1, index + 1)"
                :key="index"
                :class="{
                  view_question: true,
                  wrong: title.answer ? title.is_correct == 0 : false,
                  empty: title.answer === null || title.answer === ''
                }"
              >
                <span
                  v-if="title.id == question.id"
                  class="currentIcon questionsOrderActive"
                >
                  <i class="fa-solid fa-location-dot" />
                </span>
                {{ index + 1 }}
              </span>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="pt-3" v-if="question">
      <div class="test-paper" v-if="question.question">
        <MultipleChoiceWithPassage
          v-if="
            question.question &&
              question.question.type === 'single' &&
              question.question.passage_id > 0 &&
              question.question.passage.content
          "
          mode="explanation"
          :passageIntro="
            question.question.passage.introduction
              ? question.question.passage.introduction.intro
              : null
          "
          :passageContent="getPassage"
          :questionOrder="questionOrder"
          :content="question.question.content"
          :options="question.question.options"
          :answer="question.answer ? question.answer : '/'"
          :correctAnswer="getCAnswers(question.question.answers)"
          :isCorrect="question.answer ? question.is_correct == 1 : false"
          :explanation="
            question.question.explanations.length > 0
              ? question.question.explanations[0].explanation
              : '-'
          "
          :isFree="false"
          :company="CompanyName"
          :email="CompanyEmail"
          :showMark="false"
          :showLine="false"
          :activities="
            question.answer && question.metadata
              ? question.metadata.activities
              : null
          "
        >
          <AI
            class="pl-2"
            :content="`Explanation`"
            :questionId="questionId"
          ></AI>
        </MultipleChoiceWithPassage>
        <MultipleChoice
          v-else-if="
            question.question &&
              question.question.type === 'single' &&
              (question.question.passage === null ||
                question.question.passage.content === null)
          "
          mode="explanation"
          :questionOrder="questionOrder"
          :content="question.question.content"
          :options="question.question.options"
          :answer="question.answer ? question.answer : '/'"
          :correctAnswer="getCAnswers(question.question.answers)"
          :isCorrect="question.answer ? question.is_correct == 1 : false"
          :explanation="
            question.question.explanations.length > 0
              ? question.question.explanations[0].explanation
              : '-'
          "
          :isFree="false"
          :company="CompanyName"
          :email="CompanyEmail"
          :showMark="false"
          :activities="
            question.answer && question.metadata
              ? question.metadata.activities
              : null
          "
        >
          <AI
            class="pl-2"
            :content="`Explanation`"
            :questionId="questionId"
          ></AI>
        </MultipleChoice>
        <Math
          v-else-if="
            question.question &&
              question.question.type === 'math' &&
              getCAnswers(question.question.answers)
          "
          mode="explanation"
          :questionOrder="questionOrder"
          :content="question.question.content"
          :options="question.question.options"
          :answer="question.answer !== null ? question.answer : '/'"
          :correctAnswer="getCAnswers(question.question.answers)"
          :isCorrect="question.answer ? question.is_correct == 1 : false"
          :explanation="
            question.question.explanations.length > 0
              ? question.question.explanations[0].explanation
              : '-'
          "
          :isFree="false"
          :showMark="false"
          :company="CompanyName"
          :email="CompanyEmail"
          :activities="
            question.answer && question.metadata
              ? question.metadata.activities
              : null
          "
        >
          <AI
            class="pl-2"
            :content="`Explanation`"
            :questionId="questionId"
          ></AI>
        </Math>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Common from "@/mixins/common.js";
import { user, instant } from "@ivy-way/material";
import Breadcrumb from "@/components/Breadcrumb";
import ACTTest from "@/views/act/apis/actTest.js";
export default {
  metaInfo() {
    return {
      title: "1 - " + this.CompanyName
    };
  },

  components: { Breadcrumb },

  mixins: [Common],

  props: [],
  data() {
    return {
      exam: null,
      practices: null,
      userExamAnswers: [],
      sectionOrder: 1,
      questionOrder: 1,
      practice: null,
      questions: [],
      question: null
    };
  },
  computed: {
    isEducator() {
      return this.$route.meta && this.$route.meta.role
        ? this.$route.meta.role === "educator"
        : false;
    },
    isStudent() {
      return this.$route.meta && this.$route.meta.role
        ? this.$route.meta.role === "student"
        : false;
    },
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    getPassage() {
      let passage = "";
      if (
        this.answer &&
        this.answer.other_status &&
        this.answer.other_status.annotation &&
        this.answer.other_status.annotation.passage
      ) {
        passage = this.answer.other_status.annotation.passage;
      } else {
        if (this.question.question.passage) {
          let p1 = this.question.question.passage.content;
          let p2 = this.question.question.passage.introduction?.below_content;
          if (p2) {
            passage = `<h5 style="padding-left:40px">Passage I</h5>${p1} <br /><h5 style="padding-left:40px">Passage II</h5>${p2}`;
          } else {
            passage = p1;
          }
        }
      }
      // passage = this.getLine(passage);
      return passage;
    }
  },
  watch: {},

  mounted() {
    this.getExplanation();
    if (this.$route.query.sectionOrder) {
      this.sectionOrder = Number(this.$route.query.sectionOrder);
    }
    if (this.$route.query.questionOrder) {
      this.questionOrder = Number(this.$route.query.questionOrder);
    }
  },

  methods: {
    getCAnswers(CAnswers) {
      console.log(CAnswers);
      let CAList = [];
      if (CAnswers) {
        CAnswers.forEach(CA => {
          if (CA.answer[0]) {
            CAList.push(CA.answer[0]);
          }
        });
      }
      console.log(CAList);
      return CAList.length > 0 ? CAList[0] : null;
    },
    viewBrowse(sectionOrder, questionOrder) {
      this.sectionOrder = sectionOrder;
      this.questionOrder = questionOrder;
      this.setSection();
      this.setQuestion();
    },
    setQuestion() {
      if (this.questions) {
        let question = this.questions[this.questionOrder - 1];
        this.question = question;
      }
    },
    async getExplanation() {
      const res = await ACTTest.getExplanation(this.$route.params.examId, {
        user_exam_ids: [this.$route.params.userExamId]
      });
      res.exam.exam_sections.forEach(section => {
        section.exam_questions.forEach(question => {
          question.question.options.forEach(option => {
            option["letter"] = option["label"];
            option["title"] = option["content"];
          });
        });
      });
      this.exam = res.exam;
      this.practices = res.exam.exam_sections;
      //获取之前的考试
      if (res.user_exams && res.user_exams.length > 0) {
        this.userExamAnswers = res.user_exams[0].user_exam_answers;
      }
      this.setAnswers();
      this.setSection();
      this.setQuestion();
    },
    setSection() {
      this.practice = this.practices[this.sectionOrder - 1];
      this.questions = this.practice.exam_questions;
    },
    setAnswers() {
      this.practices.forEach(practice => {
        practice.exam_questions.forEach(question => {
          let answer = null;
          let is_correct = false;
          let metadata = null;
          if (this.userExamAnswers && this.userExamAnswers.length > 0) {
            this.userExamAnswers.forEach(userAnswer => {
              if (question.id === userAnswer.exam_question_id) {
                answer =
                  userAnswer.answer && userAnswer.answer[0]
                    ? userAnswer.answer[0]
                    : "";
                metadata = userAnswer.metadata ? userAnswer.metadata : null;
                is_correct = userAnswer.is_correct;
              }
            });
          }
          question["answer"] = answer;
          question["metadata"] = metadata;
          question["is_correct"] = is_correct;
        });
      });
    }
  }
};
</script>

<style scoped>
.test-paper {
  /* margin-bottom: 4rem; */
}
.question-title {
  margin: 0;
}
::v-deep .el-collapse-item .el-collapse-item__header {
  padding: 0 1rem;
}
::v-deep .el-collapse-item__header:hover {
  background-color: var(--themeColor);
  color: white !important;
}
.view_question {
  position: relative;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  line-height: 1.8rem;
  text-align: center;
  font-weight: 700;
  font-size: 1rem;
  border-radius: 5px;
  margin: 1rem 5px 1rem;
  border: 0.1rem solid #d4edda;
  text-decoration: none;
  cursor: pointer;
  background-color: #d4edda;
  color: #212529 !important;
}
.wrong {
  border-color: #ffdadd;
  background-color: #ffdadd;
}
.view_question.empty {
  background-color: #ccc;
  border-color: #ccc;
}
.showTags {
  display: flex;
  /* justify-content: center; */
}
.showTags-item {
  margin: 0 10px 20px 0;
  display: flex;
}
.showTags-item .view_question {
  height: 2rem;
  width: 2rem;
  line-height: 1.8rem;
  margin: 0 5px;
}
.questionsOrderActive {
  position: absolute;
  display: inline-block;
  width: 1.8rem;
  left: 0;
  text-align: center;
  top: -1.7rem;
  color: var(--themeColor);
}
::v-deep .MathJax .mrow {
  font-size: 1.2rem !important;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Heading',{attrs:{"heading":"h4","content":"Tests"}},[_c('router-link',{attrs:{"slot":"button","to":{
        name: 'EducatorClassMaterialsEdit',
        params: { id: _vm.$route.params.id }
      }},slot:"button"},[_c('el-button',{attrs:{"type":"primary","size":"mini"}},[_c('i',{staticClass:"fa fa-edit"})])],1)],1),_c('hr'),(_vm.tests)?_c('div',[(_vm.tests.length > 0)?_c('div',_vm._l((_vm.tests),function(test){return _c('el-card',{key:test.id,staticClass:"mb-4",attrs:{"shadow":"never"}},[_c('h5',{staticClass:"mb-2"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"test-color mr-2"},[_vm._v(" "+_vm._s(test.teaching_resource.content.test.name)+" ")]),_c('div',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(test.teaching_resource.content.name)+" ")])])]),_c('div',{staticStyle:{"max-width":"1080px"}},[_c('el-table',{key:test.id,staticStyle:{"width":"100%"},attrs:{"data":test.user_progresses,"max-height":"250px"}},[_c('el-table-column',{attrs:{"prop":"Students","label":"Students","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('b',[_vm._v(" "+_vm._s(_vm.user.displayName( scope.row.user.first_name, scope.row.user.last_name ))+" ")])]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"Score","label":"Score"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.user_exam)?[_c('TestScore',{attrs:{"userExam":scope.row.user_exam,"testType":test.teaching_resource.content.test.name,"role":"Educator"}})]:_c('div',[(scope.row.status === 'locked')?[_c('router-link',{attrs:{"to":{
                        name: 'CompanyUsers',
                        params: {
                          id: 'class'
                        }
                      }}},[_c('el-tooltip',{attrs:{"effect":"dark","content":`Unlock all ${test.teaching_resource.content.test.name} tests for student`,"placement":"top"}},[_c('el-button',{staticStyle:{"width":"12rem"},attrs:{"type":"info","size":"mini"}},[_c('i',{staticClass:"fa fa-lock"})])],1)],1)]:[_c('el-tag',{staticClass:"text-center",staticStyle:{"width":"12rem"},attrs:{"type":"info","effect":"plain"}},[_c('b',[_c('i',{staticClass:"fas fa-user"}),_vm._v(" Pending ")])])]],2)]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"Test Date","label":"Test Date","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.user_exam)?[_c('span',[_vm._v(" "+_vm._s(_vm.instant.formatGoogleTime(scope.row.user_exam.updated_at))+" ")])]:[_vm._v(" - ")]]}}],null,true)})],1),(_vm.isTeacher || _vm.isAdmin)?_c('div',{staticClass:"d-flex mt-2",staticStyle:{"align-items":"center"}},[_c('h6',{staticClass:"pl-2 mr-2 mb-0",staticStyle:{"width":"180px"}},[_vm._v(" AVG "),_c('el-tooltip',{staticClass:"item",attrs:{"content":"The average score only counts completed exercises.","placement":"top","popper-class":"tooltipColor"}},[_c('span',{staticClass:"action-icon"},[_c('i',{staticClass:"fas fa-question-circle"})])])],1),(_vm.scoreData[`Test${test.which_week}`].length > 0)?_c('OnlineSatScore',{attrs:{"escore":_vm.showScore(
                  _vm.scoreData[`Test${test.which_week}`].english_score,
                  _vm.scoreData[`Test${test.which_week}`].length
                ),"mscore":_vm.showScore(
                  _vm.scoreData[`Test${test.which_week}`].math_score,
                  _vm.scoreData[`Test${test.which_week}`].length
                ),"tscore":_vm.showScore(
                  _vm.scoreData[`Test${test.which_week}`].total_score,
                  _vm.scoreData[`Test${test.which_week}`].length
                ),"question_count":0,"testType":0,"rscore_up":0,"gscore_up":0,"escore_up":0,"mscore_up":0,"score_up":0,"canEdit":false}}):_vm._e()],1):_vm._e()],1)])}),1):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
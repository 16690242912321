<template>
  <div>
    <el-dialog
      :visible.sync="showDialog"
      :width="isPhone ? '95%' : '80%'"
      class="p-0"
    >
      <h4 slot="title">
        <div class="plans-title plan-pro-button">
          Select Plan For Student
        </div>
      </h4>
      <el-tabs>
        <el-tab-pane>
          <span slot="label">
            <b class="pro-color">
              Pro Plans
            </b>
          </span>
          <div>
            <el-card
              class="mb-4"
              v-if="unlockedProTypes && unlockedProTypes.length > 0"
            >
              <div>
                <el-radio-group v-model="ProType">
                  <el-radio
                    v-for="(type, index) in unlockedProTypes"
                    :key="index"
                    :label="type.name"
                    border
                  >
                    <b class="test-color">{{ type.name }}</b>
                  </el-radio>
                </el-radio-group>
              </div>
              <template v-if="ProType">
                <hr />
                <div class="plan-options">
                  <b>Key Features:</b>
                  <div class="plan-option">
                    <span class="icon">
                      <i class="fa fa-check"></i>
                    </span>
                    <span>
                      100% Replica of the Bluebook/TestNav Platform
                    </span>
                  </div>
                  <div class="plan-option">
                    <span class="icon">
                      <i class="fa fa-check"></i>
                    </span>
                    <span>
                      <b class="text-success" v-show="ProType === 'SAT'">20</b>
                      <span v-show="ProType !== 'SAT'">2</span>
                      Full Length SATs
                    </span>
                  </div>
                  <div class="plan-option">
                    <span class="icon">
                      <i class="fa fa-check"></i>
                    </span>
                    <span>
                      <b class="text-success" v-show="ProType === 'ACT'">40</b>
                      <span v-show="ProType !== 'ACT'">4</span>
                      Full Length ACTs
                    </span>
                  </div>
                  <div class="plan-option">
                    <span class="icon">
                      <i class="fa fa-check"></i>
                    </span>
                    <span>
                      <b class="text-success" v-show="ProType === 'AP'">20</b>
                      <span v-show="ProType !== 'AP'">2</span>
                      Full Length APs (Coming Soon)
                    </span>
                  </div>
                  <div class="plan-option">
                    <span class="icon">
                      <i class="fa fa-check"></i>
                    </span>
                    <span>
                      <b class="text-success" v-show="ProType === 'PSAT'">6</b>
                      <span v-show="ProType !== 'PSAT'">1</span>
                      Full Length
                      <span v-show="ProType === 'PSAT'">
                        PSATs
                      </span>
                      <span v-show="ProType !== 'PSAT'">
                        PSAT
                      </span>
                      (Coming Soon)
                    </span>
                  </div>
                  <div class="plan-option">
                    <span class="icon">
                      <i class="fa fa-check"></i>
                    </span>
                    <span>
                      <b v-show="ProType === 'TOEFL'">50</b>
                      <span v-show="ProType !== 'TOEFL'">2</span>
                      Full Length TOEFLs (Coming Soon)
                    </span>
                  </div>
                  <div class="plan-option">
                    <span class="icon">
                      <i class="fa fa-check"></i>
                    </span>
                    <span>
                      <b v-show="ProType === 'IELTS'">40</b>
                      <span v-show="ProType !== 'IELTS'">2</span>
                      Full Length IELTSs (Coming Soon)
                    </span>
                  </div>
                  <div
                    class="plan-option"
                    v-for="(item, index) in proList"
                    :key="index"
                  >
                    <span class="icon">
                      <i class="fa fa-check"></i>
                    </span>
                    <span>
                      <span v-show="index > 0">
                        <b class="logo-color mr-1">AI</b>
                      </span>
                      <span v-html="item"></span>
                    </span>
                  </div>
                </div>
                <hr />
                <template v-if="usePoints">
                  <div class="b-info">
                    <h3 class="balance-price">1</h3>
                    <span class="balance-name">
                      Coin / 1 Month
                    </span>
                  </div>
                  <div class="text-center">
                    <el-button
                      type="primary"
                      @click="getProByPoints"
                      v-show="ProType"
                      style="width: 100%;"
                    >
                      Unlock
                    </el-button>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <div class="mb-2"><b>Validity period</b></div>
                    <el-date-picker
                      style="width: 100%"
                      v-model="planDate"
                      type="daterange"
                      range-separator="~"
                      :start-placeholder="$t('TeacherCourses.Start Date')"
                      :end-placeholder="$t('TeacherCourses.End Date')"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </div>
                  <hr />
                  <div class="text-center">
                    <el-button
                      type="primary"
                      @click="getProPayUrl"
                      v-show="ProType"
                      style="width: 100%;"
                      :disabled="planDate.length < 2"
                    >
                      Unlock
                    </el-button>
                  </div>
                </template>
              </template>
            </el-card>
          </div>
        </el-tab-pane>
        <el-tab-pane>
          <span slot="label">
            <b class="max-color">
              Max Plan
            </b>
          </span>
          <el-card class="mb-4" v-if="!isMax">
            <div class="plan-options">
              <b>Key Features:</b>
              <div
                class="plan-option"
                v-for="(item, index) in maxList"
                :key="index"
              >
                <span class="icon">
                  <i class="fa fa-check"></i>
                </span>
                <span>
                  <span v-show="index > 8">
                    <b class="logo-color mr-1">AI</b>
                  </span>
                  <span v-html="item"></span>
                </span>
              </div>
            </div>
            <hr />
            <template v-if="usePoints">
              <div class="b-info">
                <h3 class="balance-price">2</h3>
                <span class="balance-name">
                  Coins / 1 Month
                </span>
              </div>
              <div class="text-center">
                <el-button
                  type="primary"
                  @click="getMaxByPoints"
                  style="width: 100%;"
                >
                  Unlock
                </el-button>
              </div>
            </template>
            <template v-else>
              <div>
                <div class="mb-2"><b>Validity period</b></div>
                <el-date-picker
                  style="width: 100%"
                  v-model="planDate"
                  type="daterange"
                  range-separator="~"
                  :start-placeholder="$t('TeacherCourses.Start Date')"
                  :end-placeholder="$t('TeacherCourses.End Date')"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
                <hr />
              </div>
              <div class="text-right">
                <el-button
                  type="primary"
                  @click="getMaxPayUrl"
                  style="width: 100%;"
                  :disabled="planDate.length < 2"
                >
                  Unlock
                </el-button>
              </div>
            </template>
          </el-card>
        </el-tab-pane>
      </el-tabs>
      <el-card v-if="myPlans">
        <Heading
          :content="
            `${user.displayName(
              userNow.first_name,
              userNow.last_name
            )} 's Plans`
          "
          heading="h6"
        ></Heading>
        <hr />
        <MyPlans :myPlans="myPlans" :forAdmin="true"></MyPlans>
      </el-card>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import MyPlans from "@/views/profile/components/MyPlans.vue";

import plansApi from "@/apis/plans";

export default {
  metaInfo() {},

  components: { MyPlans },

  mixins: [],

  props: ["userNow", "usePoints", "companyId"],
  data() {
    return {
      showDialog: false,
      planDate: [],
      ProType: null,
      activeProTypes: [
        { name: "SAT", canUseIds: [4, 5] },
        { name: "ACT", canUseIds: [1, 5] }
        // { name: "AP", canUseIds: [2, 5] }
      ],
      proList: [
        "Immediate Scoring",
        "<b>Unlimited</b> Interactive Answer Explanations ",
        "Time Tracking & Proctoring",
        "Performance Analysis",
        "Intelligent Flash Cards",
        "Smart Score Projector",
        "Focused Practice Drills",
        "Powered Study Plan"
      ],
      maxList: [
        "<b>Unlimited Access</b> to All Exams",
        "100% Replica of the Bluebook/TestNav Platform",
        "<b>20</b> Full Length SATs",
        "<b>40</b> Full Length ACTs",
        "<b>20</b> Full Length APs (Coming Soon)",
        "<b>6</b> Full Length PSATs (Coming Soon)",
        "<b>50</b> Full Length TOEFLs (Coming Soon)",
        "<b>40</b> Full Length IELTSs (Coming Soon)",
        "Immediate Scoring",
        "<b>Unlimited</b> Interactive Answer Explanations ",
        "Time Tracking & Proctoring",
        "Performance Analysis",
        "Intelligent Flash Cards",
        "Smart Score Projector",
        "Focused Practice Drills",
        "Powered Study Plan"
      ]
    };
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    myPlans() {
      if (this.userNow) {
        return this.userNow.subscriptions;
      } else {
        return [];
      }
    },
    unlockedProTypes() {
      let arr = [];
      this.activeProTypes.forEach(type => {
        let canUse = false;
        this.myPlans.forEach(plan => {
          if (type.canUseIds.indexOf(plan.subscription_plan_id) > -1) {
            canUse = true;
          }
        });
        if (!canUse) {
          arr.push(type);
        }
      });
      return arr;
    },
    isMax() {
      let isTrue = false;
      this.myPlans.forEach(item => {
        if (item.subscription_plan_id === 5) {
          isTrue = true;
        }
      });
      return isTrue;
    }
  },
  watch: {},

  mounted() {},

  methods: {
    selectStudent() {
      this.showDialog = true;
    },
    async getProPayUrl() {
      let plan_id = null;
      if (this.ProType === "ACT") {
        plan_id = 1;
      } else if (this.ProType === "SAT") {
        plan_id = 4;
      }
      const res = await plansApi.subscriptionForUser(this.userNow.id, {
        subscription_plan_id: plan_id,
        period_start: this.planDate[0],
        period_end: this.planDate[1]
      });
      this.showDialog = false;
      this.$emit("updateUsers");
    },
    async getProByPoints() {
      let plan_id = null;
      if (this.ProType === "ACT") {
        plan_id = 1;
      } else if (this.ProType === "SAT") {
        plan_id = 4;
      }
      const res = await plansApi.subscriptionForByPoints(this.companyId, {
        subscription_plan_id: plan_id,
        user_id: this.userNow.id
      });
      this.showDialog = false;
      this.$emit("updateUsers");
    },
    async getMaxPayUrl() {
      let plan_id = 5;
      const res = await plansApi.subscriptionForUser(this.userNow.id, {
        subscription_plan_id: plan_id,
        period_start: this.planDate[0],
        period_end: this.planDate[1]
      });
      this.showDialog = false;
      this.$emit("updateUsers");
    },
    async getMaxByPoints() {
      let plan_id = 5;
      const res = await plansApi.subscriptionForByPoints(this.companyId, {
        subscription_plan_id: plan_id,
        user_id: this.userNow.id
      });
      this.showDialog = false;
      this.$emit("updateUsers");
    }
  }
};
</script>

<style scoped>
.plan-options {
  font-size: 14px;
}
.plan-option {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  margin: 10px 0;
}
.plan-option .icon {
  font-weight: 700;
  margin-right: 0.8rem;
}

::v-deep .el-dialog__body {
  padding-top: 0;
  /* padding-bottom: 0; */
}

.free-color {
  color: #01b884;
}
.pro-color {
  color: #fa17e3;
}
.max-color {
  color: #1f7cff;
}

.b-info {
  text-align: right;
}
.b-info .balance-price {
  margin: 1rem 0 1rem 0;
  color: orange;
}
.b-info .balance-name {
  color: orange;
}
.b-info .balance-price {
  display: inline-block;
  font-size: 50px;
}
.b-info .balance-name {
  display: inline-block;
  font-size: 16px;
  margin-left: 4px;
}

@media screen and (max-width: 768px) {
  .plan-container {
    max-width: 100%;
    padding: 0 15px 20px 15px;
  }
  .plan-cover {
    max-width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }
  .plan-item {
    width: 35rem !important;
    word-break: normal !important;
  }
  .plan-option {
    font-size: 1rem;
    line-height: 2rem;
    margin: 2px 0;
  }
  .switch-date {
    width: 100%;
  }
}
</style>

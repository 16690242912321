import store from "@/store";

export default {
  methods: {
    isRole(role) {
      return store.getters["user/roles"].forEach(role => {
        role.name == role;
      });
    },
    isRoleAdmin() {
      return store.getters["user/roles"].includes("super-admin");
    },
    // isRoleParent() {
    //   return store.getters["user/roles"].includes("parent");
    // },
    isRoleStudent() {
      return store.getters["user/roles"] && store.getters["user/roles"].length < 1;
    },
    isRoleTeacher() {
      let isRole = false;
      store.getters["user/roles"].forEach(role => {
        if (role.name == "teacher") {
          isRole = true;
        };
      });
      return isRole;
    },
    isRoleCompanyAdmin() {
      let isRole = false;
      store.getters["user/roles"].forEach(role => {
        if (role.name == "organization_admin") {
          isRole = true;
        };
      });
      return isRole;
    }
  }
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.userExam.finished_at)?_c('div',{staticClass:"satMocks-score"},[(
        _vm.userExam.user_exam_results && _vm.userExam.user_exam_results.length > 1
      )?[_c('el-tooltip',{attrs:{"effect":"dark","content":"English","placement":"top"}},[_c('b',[_vm._v(" "+_vm._s(_vm.userExam.user_exam_results[0].score)+" + ")])]),_c('el-tooltip',{attrs:{"effect":"dark","content":"Math","placement":"top"}},[_c('b',[_vm._v(" "+_vm._s(_vm.userExam.user_exam_results[1].score)+" = ")])])]:_vm._e(),(_vm.userExam.id > 0)?_c('el-tooltip',{attrs:{"effect":"dark","content":"Total","placement":"top"}},[_c('b',[_c('router-link',{attrs:{"to":{
            name: _vm.resultRoute,
            params: { userExamId: _vm.userExam.id }
          },"target":"_blank"}},[_vm._v(" "+_vm._s(Number(_vm.tscore))+" "),_c('span',{staticStyle:{"color":"black"}})])],1)]):_c('b',[_vm._v(" "+_vm._s(Number(_vm.tscore))+" "),_c('span',{staticStyle:{"color":"black"}})])],2):_c('div',[_c('router-link',{attrs:{"to":{
        name: 'EducatorSATResultPage',
        params: { userExamId: _vm.userExam.id }
      },"target":"_blank"}},[_c('el-tag',{staticClass:"text-center",staticStyle:{"width":"12rem"},attrs:{"type":"success","effect":"plain"}},[_c('i',{staticClass:"fas fa-user-check"}),(_vm.userExam.metadata)?_c('b',[_vm._v(" "+_vm._s(`Module: ${_vm.userExam.metadata.sectionOrder}`)+", "+_vm._s(`Question: ${_vm.userExam.metadata.questionOrder}`)+" ")]):_c('b',[_vm._v(" Module: 1, Question: 1 ")])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="container pt-3">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link
            :to="{
              name: 'EducatorQuestionsList'
            }"
          >
            Questions
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle"></Heading>
    <hr />
    <el-card class="test-paper mb-4">
      <template v-if="isSATQuestion">
        <MultipleChoiceWithPassage
          v-if="
            question &&
              question.type === 'single' &&
              question.passage_id > 0 &&
              question.passage.content
          "
          mode="explanation"
          :passageIntro="
            question.passage.introduction
              ? question.passage.introduction.intro
              : null
          "
          :passageContent="getPassage"
          :questionOrder="questionOrder"
          :content="question.content"
          :options="question.options"
          :answer="``"
          :correctAnswer="getCAnswers(question.answers)"
          :isCorrect="false"
          :explanation="
            question.explanations && question.explanations.length > 0
              ? question.explanations[0].explanation
              : ''
          "
          :showMark="false"
          :activities="
            answer && answer.other_status
              ? answer.other_status.activities
              : null
          "
        ></MultipleChoiceWithPassage>
        <MultipleChoice
          v-else-if="
            question &&
              question.type === 'single' &&
              (question.passage === null || question.passage.content === null)
          "
          mode="explanation"
          :questionOrder="questionOrder"
          :content="question.content"
          :options="question.options"
          :answer="``"
          :correctAnswer="getCAnswers(question.answers)"
          :isCorrect="false"
          :explanation="
            question.explanations && question.explanations.length > 0
              ? question.explanations[0].explanation
              : ''
          "
          :showMark="false"
          :activities="
            answer && answer.other_status
              ? answer.other_status.activities
              : null
          "
        ></MultipleChoice>
        <Math
          v-else-if="question && question.type === 'math'"
          mode="explanation"
          :questionOrder="questionOrder"
          :content="question.content"
          :options="question.sat_options"
          :answer="``"
          :correctAnswer="getCAnswers(question.answers)"
          :isCorrect="false"
          :explanation="
            question.explanations && question.explanations.length > 0
              ? question.explanations[0].explanation
              : ''
          "
          :showMark="false"
          :activities="
            answer && answer.other_status
              ? answer.other_status.activities
              : null
          "
        ></Math>
      </template>
      <template v-if="isACTQuestion">
        <MultipleChoiceWithPassageACT
          v-if="question"
          :id="questionOrder"
          ref="MultipleChoiceWithPassage"
          :questionId="question.id"
          :testType="1"
          :canHighlighter="false"
          :canEliminator="false"
          :canMasking="false"
          :passageContent="question.passage ? question.passage.content : ''"
          :questionOrder="questionOrder"
          :content="question.content"
          :options="question.options"
          :answer="''"
          :tags="question.question_tags ? question.question_tags : []"
          :activities="
            question.metadata && question.metadata.activities
              ? question.metadata.activities
              : null
          "
          :isCorrect="question.is_correct"
          :correctAnswer="
            question.answers && question.answers.length > 0
              ? question.answers[0].answer[0]
              : ''
          "
          :explanation="
            question && question.explanations
              ? question.explanations[0].explanation
              : ''
          "
          mode="preview"
        ></MultipleChoiceWithPassageACT>
      </template>
    </el-card>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import Detail from "@/views/questions/apis/detail.js";
import role from "@/mixins/role.js";
import Common from "@/mixins/common.js";
import MultipleChoiceWithPassageACT from "@/views/act/actResult/components/MultipleChoiceWithPassage";

// import QuestionInfo from "@/views/satTestQuestionExplanation/QuestionInfo.vue";

export default {
  metaInfo() {
    return {
      title: this.myTitle + " - " + this.CompanyName
    };
  },

  components: { Breadcrumb, MultipleChoiceWithPassageACT },

  mixins: [Common, role],

  props: [],
  data() {
    return {
      exam: null,
      practices: null,
      result: null,
      answer: null,
      question: null,
      activeName: null,
      questionOrder: "1"
    };
  },
  computed: {
    myTitle() {
      return "Preview Question";
    },
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    questionId() {
      return this.$route.params.questionId;
    },
    isSATQuestion() {
      const satSubjectIdArr = [13, 14];
      if (this.question && this.question.subject_id) {
        return satSubjectIdArr.indexOf(this.question.subject_id) > -1;
      }
      return false;
    },
    isACTQuestion() {
      const actSubjectIdArr = [1, 2, 3, 4];
      if (this.question && this.question.subject_id) {
        return actSubjectIdArr.indexOf(this.question.subject_id) > -1;
      }
      return false;
    },
    getPassage() {
      let passage = "";
      let p1 = this.question.passage.content;
      let p2 = this.question.passage.introduction?.below_content;
      if (p2) {
        passage = `<h5 style="padding-left:40px">Passage I</h5>${p1} <br /><h5 style="padding-left:40px">Passage II</h5>${p2}`;
      } else {
        passage = p1;
      }
      // passage = this.getLine(passage);
      return passage;
    }
  },
  watch: {},

  async mounted() {
    const res = await Detail.getQuestionDetail(this.questionId);
    res.options.forEach(option => {
      option["letter"] = option["label"];
      option["title"] = option["content"];
    });
    this.question = res;

    // if (!this.question.sat_sat_subject) {
    //   this.question.sat_sat_subject = {
    //     sat_subject: {
    //       id: 5,
    //       name: "english"
    //     }
    //   };
    // }
    // this.answer = res.answers;
    // this.getSubjects();
    // this.getUserAnswers();
  },

  methods: {
    getCAnswers(CAnswers) {
      console.log(CAnswers);
      let CAList = [];
      if (CAnswers) {
        CAnswers.forEach(CA => {
          if (CA.answer[0]) {
            CAList.push(CA.answer[0]);
          }
        });
      }
      console.log(CAList);
      return CAList.length > 0 ? CAList[0] : null;
    },
    // getLine(passage) {
    //   let new_passage = passage.replace(/[\n]/g, "<br>");
    //   new_passage = new_passage.replace(
    //     /<br>/g,
    //     "</p><p style='margin-bottom:0;height:34px;'>"
    //   );
    //   new_passage =
    //     "<p style='margin-bottom:0;height:34px;'>" + new_passage + "</p>";
    //   return new_passage;
    // },
    getUserAnswers() {
      let count = 0;
      this.practices.forEach(practice => {
        let arr = [];
        let correctCount = 0;

        practice.exam.exam_questions.forEach(question => {
          count++;
          let answer = null;
          this.result.user_exam_answers.forEach(userAnswer => {
            if (question.id === userAnswer.exam_question.id) {
              answer = userAnswer;
              if (userAnswer.is_correct === 1) {
                correctCount++;
              }
            }
          });
          question["userAnswer"] = answer;
          arr.push(answer);
        });
        practice["correctCount"] = correctCount;
      });
    }
  }
};
</script>

<style scoped>
.question-title {
  margin: 0 -1rem;
}
::v-deep .el-collapse-item .el-collapse-item__header {
  padding: 0 1rem;
}
::v-deep .el-collapse-item__header:hover {
  background-color: var(--themeColor);
  color: white !important;
}
.view_question {
  position: relative;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  line-height: 1.8rem;
  text-align: center;
  font-weight: 700;
  font-size: 1rem;
  border-radius: 5px;
  margin: 1rem 5px 1rem;
  border: 0.1rem solid #d4edda;
  text-decoration: none;
  cursor: pointer;
  background-color: #d4edda;
  color: #212529 !important;
}
.wrong {
  border-color: #ffdadd;
  background-color: #ffdadd;
}
.view_question.empty {
  background-color: #ccc;
  border-color: #ccc;
}
.showTags {
  display: flex;
  /* justify-content: center; */
}
.showTags-item {
  margin: 0 10px 20px 0;
  display: flex;
}
.showTags-item .view_question {
  height: 2rem;
  width: 2rem;
  line-height: 1.8rem;
  margin: 0 5px;
}
.questionsOrderActive {
  position: absolute;
  display: inline-block;
  width: 1.8rem;
  left: 0;
  text-align: center;
  top: -1.7rem;
  color: var(--themeColor);
}
::v-deep .MathJax .mrow {
  font-size: 1.2rem !important;
}
</style>

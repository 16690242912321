<template>
  <div class="container">
    <Breadcrumb class="mt-4">
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link :to="{ name: 'ACTHistory' }">
            ACT Exam History
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-if="exam">
          {{ exam.name }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div
      v-loading="loading"
      element-loading-text="Loading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      style="min-height: 100px;"
    >
      <div v-if="userExam">
        <div v-if="userExam.finished_at">
          <el-alert
            :title="$t('courses.completed')"
            type="success"
            center
            show-icon
            :closable="false"
          >
          </el-alert>
        </div>
        <div v-else>
          <el-alert
            :title="$t('courses.Incomplete')"
            type="error"
            center
            show-icon
            :closable="false"
          >
          </el-alert>
        </div>
      </div>
      <div class="row sat">
        <div class="col-md-5 sat_left print_div text-success">
          <div>
            <p>ACT</p>
            <span><i class="fa fa-registered" aria-hidden="true"></i></span>
          </div>
        </div>
        <div class="col-md-7 sat_right">
          <div class="col-md-8">
            <div>
              <ul>
                <li>
                  <b>Name: </b>
                  {{
                    user.displayName(
                      userExam.user.first_name,
                      userExam.user.last_name
                    )
                  }}
                </li>
                <li>
                  <b>Test Name: </b>
                  <span>{{ exam.name }} </span>
                </li>
                <li>
                  <b>Test Date: </b>
                  {{ instant.formatGoogleTime(userExam.updated_at) }}
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="col-md-4 hidden-xs">
            <div
              class="text-success"
              style="line-height:93px;font-size: 50px;font-weight: 700;"
            >
              XMocks
            </div>
          </div> -->
        </div>
      </div>
      <!-- Your Composite Score -->
      <div class="row total">
        <div class="col-md-6 col-sm-12 total_score print_div">
          <h4 class="title">
            Your Composite Score
          </h4>
          <div class="total_above row">
            <p class="col-8 score">
              {{ Number(this.userExam.score) }}
            </p>
            <p class="col-4 t_score">0 to 36</p>
          </div>
          <h6>Your total Score</h6>
        </div>
      </div>
      <!-- Your Composite Score -->
      <template v-if="sectionScoreArr && sectionScoreArr.length > 0">
        <el-divider></el-divider>
        <div class="total">
          <h4 class="title">
            Section Scores
          </h4>
          <div class="row">
            <div
              class="col-md-6 col-sm-12 total_score print_div"
              v-for="section in sectionScoreArr"
              :key="section.id"
            >
              <div class="total_above row">
                <p class="col-8 score">
                  {{ section.score }}
                </p>
                <p class="col-4 t_score">0 to 36</p>
              </div>
              <h6>Your {{ section.subject.subject }} Score</h6>
            </div>
          </div>
        </div>
      </template>
      <!-- Score Breakdown -->
      <el-divider></el-divider>

      <AIShow
        v-if="AIPerformance"
        :content="`Performance Analysis`"
        :text="`${AIPerformance}`"
      ></AIShow>
      <el-divider></el-divider>
      <div class="total">
        <h4 class="title mb-4">
          Score Breakdown &nbsp;
          <el-button
            size="small"
            type="success"
            v-show="!showAnswer"
            @click="showAnswer = true"
            class="m-0"
          >
            Show Answers
          </el-button>
          <el-button
            size="small"
            type="danger"
            v-show="showAnswer"
            @click="showAnswer = false"
            class="m-0"
          >
            Hide Answers
          </el-button>
        </h4>
        <el-card>
          <div v-if="exam && exam.exam_sections.length > 0">
            <UserTestResultTable
              :showAnswer="showAnswer"
              :list="exam.exam_sections"
              :examId="exam.id"
              :userExamId="userExamId"
            ></UserTestResultTable>
          </div>
        </el-card>
      </div>
      <div class="total">
        <h4 class="title mb-4">Questions Timeline</h4>
        <el-card v-if="exam && exam.exam_sections.length > 0">
          <div
            v-for="(passage, pIndex) in exam.exam_sections"
            :key="pIndex"
            class="mb-4"
          >
            <h4>{{ titleCase(passage.name) }}:</h4>
            <TimeTable
              class="mt-4"
              :sectionTime="
                getSectionTime(
                  titleCase(passage.name),
                  passage.exam_questions.length
                )
              "
              :questions="passage.exam_questions"
            ></TimeTable>
            <hr />
          </div>
        </el-card>
      </div>
      <!-- Performance Overview -->
      <template v-if="false">
        <el-divider></el-divider>
        <div class="total">
          <h4 class="title mb-4">
            Performance Overview
          </h4>
          <template>
            <div class="row">
              <div
                v-for="(tag_analysis, key, index) in tag_analysis_arr_big"
                :key="index"
                class="col-md-6"
              >
                <PerformanceBar
                  v-if="tag_analysis && tag_analysis.length > 0"
                  section="Reading"
                  :tagType="key.toUpperCase()"
                  :tags="tag_analysis"
                  :examId="exam.id"
                  :userExamId="userExamId"
                ></PerformanceBar>
              </div>
            </div>
          </template>
        </div>
      </template>
      <!-- Detailed Performance Analysis -->
      <el-divider></el-divider>
      <div class="total">
        <h4 class="title mb-4">
          Detailed Performance Analysis
        </h4>
        <el-card>
          <template>
            <div
              v-for="(tag_analysis, key, index) in tag_analysis_arr"
              :key="index"
              class="mb-4"
            >
              <Performance
                v-if="tag_analysis && tag_analysis.length > 0"
                section="Reading"
                :tagType="key.toUpperCase()"
                :tags="tag_analysis"
                :examId="exam.id"
                :userExamId="userExamId"
                testType="ACT"
              ></Performance>
            </div>
          </template>
        </el-card>
      </div>
      <!-- Prediction Scores -->
      <el-divider></el-divider>
      <div class="total">
        <h4 class="title">
          Prediction Scores
        </h4>
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <AIShow
              class="mt-3"
              :content="`ACT Score Prediction`"
              :title="``"
              text="Based on this test, you are expected to get a score within this range on the real ACT."
            >
              <div class="total_above row">
                <p class="col-8 score">
                  {{ predictionScoresACT[0] }} - {{ predictionScoresACT[1] }}
                </p>
                <p class="col-4 t_score">0 to 36</p>
              </div>
            </AIShow>
          </div>
          <div class="col-md-6 col-sm-12">
            <AIShow
              class="mt-3"
              :content="`SAT Score Prediction`"
              :title="``"
              text="Based on this test, you are expected to get a score within this range on the real SAT."
            >
              <div class="total_above row">
                <p class="col-8 score">
                  {{ predictionScoresSAT[0] }} - {{ predictionScoresSAT[1] }}
                </p>
                <p class="col-4 t_score">400 to 1600</p>
              </div>
            </AIShow>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
    </div>
  </div>
</template>

<script>
import role from "@/mixins/role.js";
import ACTList from "@/views/act/apis/list.js";
import UserTestResultTable from "@/views/act/actResult/components/UserTestResultTable.vue";
import { user, instant } from "@ivy-way/material";
import Breadcrumb from "@/components/Breadcrumb";
import AIShow from "@/views/act/actResult/components/AIShow";
import Performance from "@/views/act/actResult/components/Performance.vue";
import PerformanceBar from "@/views/act/actResult/components/PerformanceBar.vue";
import TimeTable from "@/views/act/actResult/components/TimeTable";
import Common from "@/mixins/common.js";
import _ from "lodash";

export default {
  components: {
    Breadcrumb,
    UserTestResultTable,
    AIShow,
    Performance,
    PerformanceBar,
    TimeTable
  },

  mixins: [Common, role],

  props: [],
  data() {
    return {
      exam: null,
      userExam: null,
      userAnswers: [],
      tag_analysis: null,
      showAnswer: false,
      AIPerformance: null,
      loading: true
    };
  },
  computed: {
    user() {
      return user;
    },
    tag_analysis_arr() {
      let res = null;
      if (this.tag_analysis) {
        res = _.groupBy(this.tag_analysis, "subject");
      }

      let newObj = {
        English: [],
        Math: [],
        Reading: [],
        Science: []
      };
      Object.keys(res).forEach(key => {
        if (key === "English") {
          newObj.English = res[key];
        }
        if (key === "Reading") {
          newObj.Reading = res[key];
        }
        if (key === "Math") {
          newObj.Math = res[key];
        }
        if (key === "Science") {
          newObj.Science = res[key];
        }
      });

      return newObj;
    },
    tag_analysis_arr_big() {
      let res = null;
      if (this.tag_analysis) {
        res = _.groupBy(this.tag_analysis, "subject");
      }

      let newObj = {
        English: [],
        Math: [],
        Reading: [],
        Science: []
      };
      Object.keys(res).forEach(key => {
        if (key === "English") {
          newObj.English = _.groupBy(res[key], "type");
        }
        if (key === "Reading") {
          newObj.Reading = _.groupBy(res[key], "type");
        }
        if (key === "Math") {
          newObj.Math = _.groupBy(res[key], "type");
        }
        if (key === "Science") {
          newObj.Science = _.groupBy(res[key], "type");
        }
      });
      return res;
    },
    instant() {
      return instant;
    },
    userExamId() {
      return this.$route.params.userExamId;
    },
    sectionScoreArr() {
      let arr = [];
      if (
        this.userExam.user_exam_results &&
        this.userExam.user_exam_results.length > 0
      ) {
        arr = this.userExam.user_exam_results;
      }
      return arr;
    },
    predictionScoresACT() {
      let score = Number(this.userExam.score);
      let min_score = Math.round(score) - 2;
      let max_score = Math.round(score) + 2;
      if (min_score <= 0) {
        min_score = 0;
      }
      if (max_score > 36) {
        max_score = 36;
      }
      return [min_score, max_score];
    },
    predictionScoresSAT() {
      let score = 400 + Math.round(Number(this.userExam.score) * (1200 / 36));
      let min_score = score - 50;
      let max_score = score + 50;
      if (min_score <= 400) {
        min_score = 400;
      }
      if (max_score > 1600) {
        max_score = 1600;
      }
      return [min_score, max_score];
    }
  },
  watch: {},

  async mounted() {
    await this.getUserAnswers();
    await this.getTagAnalysis();
    await this.getAIPerformance();
    this.loading = false;
  },

  methods: {
    getSectionTime(sectionName) {
      let testTime = 60 * 60;
      if (sectionName == "English") {
        testTime = 45 * 60;
      } else if (sectionName == "Math") {
        testTime = 60 * 60;
      } else if (sectionName == "Reading" || sectionName == "Science") {
        testTime = 35 * 60;
      } else {
        testTime = 60 * 60;
      }
      // testTime = 10;
      return Math.round(testTime);
    },
    async getTagAnalysis() {
      const res = await ACTList.getTagAnalysis(this.userExamId);
      res.user_exam_question_tag_analysis.forEach(tag => {
        tag.question_orders.forEach((sectionOrder, index) => {
          let section = sectionOrder[0];
          let order = sectionOrder.slice(2);
          let isCorrect = 0;
          console.log(section, order, isCorrect);
          if (
            this.exam.exam_sections[section - 1].exam_questions[order - 1]
              .userAnswer
          ) {
            isCorrect = this.exam.exam_sections[section - 1].exam_questions[
              order - 1
            ].userAnswer.is_correct;
          }
          tag.question_orders[index] = `${sectionOrder}-${isCorrect}`;
        });
      });
      this.tag_analysis = res.user_exam_question_tag_analysis;
    },
    async getAIPerformance() {
      const res = await ACTList.getAIPerformance(this.userExamId);
      console.log(res);
      this.AIPerformance = res.content;
    },
    async getUserAnswers() {
      const res = await ACTList.getUserExam(this.userExamId);
      let exam = res.exam;
      this.userExam = res.user_exam;
      let count = 0;
      exam.exam_sections.forEach(practice => {
        let arr = [];
        let correctCount = 0;
        practice.exam_questions.forEach(question => {
          count++;
          let answer = null;
          this.userExam.user_exam_answers.forEach(userAnswer => {
            if (question.id === userAnswer.exam_question_id) {
              answer = userAnswer;
              if (userAnswer.is_correct === 1) {
                correctCount++;
              }
            }
          });
          question["userAnswer"] = answer;
          arr.push(answer);
        });
        practice["correctCount"] = correctCount;
        this.userAnswers.push(arr);
      });
      this.count = count;
      this.exam = exam;
    }
  }
};
</script>

<style scoped>
.total p,
.total ul {
  margin: 0px;
}

.total {
  margin-top: 30px;
}
.total .title {
  font-size: 26px;
  line-height: 40px;
  height: 40px;
  color: var(--themeColor);
}
.total_under {
  padding: 0px;
}
.total_above .score {
  font-size: 3rem;
  color: #222;
  font-weight: 700;
  line-height: 70px;
  height: 70px;
}
.differt-score-true {
  color: var(--themeColor);
}
.differt-score {
  font-size: 25px;
  font-weight: 700;
}
.total .score + p {
  margin-top: 25px;
  height: 2rem;
  line-height: 2rem;
  padding-left: 20px;
  border-left: 4px solid #ccc;
}
.total .total_above .title {
  margin-bottom: 0;
  height: 40px;
  line-height: 40px;
}
.total .total_above p.c_score {
  margin-top: 5px;
  margin-left: 20px;
  height: 30px;
  line-height: 30px;
  padding-left: 20px;
  border-left: 4px solid #ccc;
}
.total_under p:first-child {
  font-size: 30px;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  margin-top: 10px;
}
.total .range {
  font-size: 18px;
  color: #000;
}
.total_under p:last-child {
  font-size: 16px;
  color: #666;
}
.essay li > div > div:first-child {
  font-weight: 700;
  color: #222;
  padding: 0px;
}
.essay li > div > div:last-child {
  padding-left: 20px;
  border-left: 3px solid #ccc;
}
.essay li > p {
  font-size: 16px;
  color: #666;
}
.essay .e_score {
  line-height: 34px;
  border-left: 3px solid #ccc;
}
.total_progress {
  margin-top: 20px;
}
.progress {
  margin: 20px 0;
  height: 20px;
  overflow: hidden;
  line-height: 20px;
  font-size: 15px;
  background-color: #e9ecef;
  border-radius: 3px;
}
.progress-bar-info {
  background-color: #007bff;
}
.progress-bar-success {
  background-color: var(--themeColor);
}
.progress > span {
  position: absolute;
  left: 50%;
  color: #222;
  line-height: 20px;
}
.total_progress .tag {
  display: inline-block;
  width: 25px;
  height: 10px;
  margin-right: 5px;
  border-radius: 10%;
}
.total_progress .tag-left {
  background: #007bff;
}
.total_progress .tag-right {
  background: var(--themeColor);
}
.green-td {
  width: 96px;
  text-align: center;
  color: #fff;
  background-color: var(--themeColor);
}

.table td {
  height: 20px;
  padding: 2px 5px;
  text-align: center;
  vertical-align: middle;
  border: var(--themeColor) 1px solid;
}
.table .right-border {
  border: var(--themeColor) 1px solid;
  border-right: 3px solid var(--themeColor);
}
.total .table >>> .el-tag {
  margin: 5px 0;
}
.sat_right {
  display: flex;
  justify-content: space-between;
}
.sat .sat_right ul {
  list-style: none;
  position: relative;
  padding-left: 0;
}
.title {
  font-size: 30px;
}
.sat_left > div > span {
  display: inline-block;
  position: absolute;
  left: 290px;
  top: 28px;
}
.sat_left > div > p:first-child {
  font-size: 140px;
  font-weight: 700;
  color: var(--themeColor);
  line-height: 140px;
  margin-bottom: 0px;
}
.sat_right {
  padding-left: 1rem;
  padding-top: 30px;
  border-bottom: 1px solid#DCDFE6;
}
.sat_right ul {
  clear: both;
}
.sat_right ul li {
  font-size: 1.5rem;
  line-height: 2rem;
}
.sat_right img {
  width: 90%;
}
.view-image {
  margin: 10px 0;
  display: flex;
}
.view-image-item {
  margin-right: 15px;
}
.view-image-item >>> img {
  border-radius: 5px;
  border: 1px solid #ccc;
}
.sat .sat_right ul {
  list-style: none;
  position: relative;
  padding-left: 0;
}
</style>

<template>
  <div class="container pt-3">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle">
      <template slot="button">
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('TeacherCourses.Join a class')"
          placement="top"
        >
          <router-link
            :to="{
              name: 'JoinCourse'
            }"
          >
            <el-button size="small" type="success">
              <i class="fa fa-plus"></i>
            </el-button>
          </router-link>
        </el-tooltip>
      </template>
    </Heading>
    <hr />
    <div v-if="classes">
      <Classes
        v-if="classes.length > 0"
        :classes="classes"
        :page="page"
        :resultsTotal="resultsTotal"
        :pageSize="pageSize"
        routerName="StudentClassDetail"
      ></Classes>
      <el-empty v-else description="No Data"></el-empty>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import { user, instant } from "@ivy-way/material";
import List from "@/views/courses/apis/List.js";
import Class from "@/apis/class.js";
import Common from "@/mixins/common.js";
import Theme from "@/common/theme";
import baseDomainPath from "@/common/baseDomainPath";
import Classes from "@/views/courses/list/components/Classes.vue";

export default {
  metaInfo() {
    return {
      title: "Classes - " + this.CompanyName
    };
  },

  components: { Classes, Breadcrumb },

  mixins: [Common],

  props: [],
  data() {
    return {
      search: {
        search: null
      },
      practices: null,
      resultsTotal: 0,
      pageSize: 10,
      subjects: [],
      classes: null,
      showLink: false,
      link: ""
      // composePractices: null
    };
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    courseDetailLink() {
      if (this.isAdmin) {
        return "AdminCourseDetail";
      } else if (this.isTeacher) {
        return "TeacherCourseDetail";
      } else if (this.isCompany) {
        return "CompanyCourseDetail";
      } else {
        return "TeacherCourseDetail";
      }
    },
    myTitle() {
      return "My Classes";
    },
    instant() {
      return instant;
    },
    user() {
      return user;
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    ...mapState("user", ["token", "lang"]),
    isLogin() {
      return this.token !== "";
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    },
    theme() {
      return Theme.name;
    },
    isAdmin() {
      return this.$route.name === "AdminSatCourses";
    },
    isTeacher() {
      return this.$route.name === "TeacherSatCourses";
    },
    isCompany() {
      return this.$route.name === "CompanySatCourses";
    },
    companyId() {
      return this.getProfile.company_id;
    }
  },
  watch: {
    companyId() {
      this.getClasses();
    }
  },

  async mounted() {
    if (this.$route.query.search) {
      this.search.search = this.$route.query.search;
    }
    this.getClasses();
  },

  methods: {
    removeClass(id) {
      this.$confirm(
        this.$t("newCourses.This will delete it forever, Continue? "),
        this.$t("newCourses.Confirmation"),
        {
          confirmButtonText: this.$t("newCourses.Confirm"),
          cancelButtonText: this.$t("newCourses.Cancel"),
          type: "error"
        }
      ).then(async () => {
        let role = "teacher";
        if (this.isAdmin) {
          role = "admin";
        }
        if (this.isCompany) {
          role = "company";
        }
        if (this.isTeacher) {
          role = "teacher";
        }
        await Class.removeClass(role, id);
        this.getClasses();
      });
    },
    setLink(code) {
      this.link = `${baseDomainPath}student/classes/join?code=${code}`;
      this.showLink = true;
    },
    async getClasses() {
      const res = await List.getCourses({
        role: "student",
        per_page: this.pageSize,
        keyword: this.search.search,
        page: this.page
      });
      this.classes = res.data;
      this.resultsTotal = res.total;
    },
    async searchResult() {
      this.$router.replace({
        params: {
          ...this.$route.params
        },
        query: {
          ...this.$route.query,
          ...this.search,
          page: 1
        }
      });
    },
    setPage(page) {
      this.routerPush({
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    },
    copyLink() {
      var oInput = document.createElement("input");
      oInput.value = this.link;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.showLink = false;
      this.$message({
        message: "Copied",
        type: "success"
      });
    }
  }
};
</script>

<style scoped>
.referral-code span {
  display: inline-block;
  height: 41px;
  line-height: 41px;
  padding: 0 1rem;
  font-size: 1rem;
  background: rgb(241, 243, 244);
  border-radius: 4px;
}
::v-deep .class-link .el-dialog__body {
  padding-top: 0.2rem;
}
</style>

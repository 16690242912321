<template>
  <div>
    <div class="container plan-container mb-4">
      <div class="plan-box">
        <div class="card__content mb-4 mt-4">
          <h1 class="subheader">
            Plans
          </h1>
          <h2
            class="section-v2__header section-v2__header_v2 section-v2__header_main"
          >
            The best test prep tool,<br />
            for the best price.
          </h2>
          <div class="after">&nbsp;</div>
        </div>
        <Plan></Plan>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Landing from "@/views/landingPage/components/Index.vue";
import Plan from "@/views/plans/Students.vue";

export default {
  metaInfo() {
    return {
      title: "Plans - " + this.CompanyName
    };
  },

  components: { Plan },

  mixins: [],

  props: [],
  data() {
    return {};
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped>
.stories-title {
  color: var(--themeColor);
  font-weight: 700;
  font-size: 2.5rem;
  /* font-style: italic; */
  font-family: "Oswald-Bold";
}
.plan-container {
  max-width: calc(1280px + 2 * var(--layout-padding));
  margin: 0 auto;
  padding: 0 var(--layout-padding);
  width: auto;
  padding: 1rem;
}
@media screen and (max-width: 768px) {
  .plan-container {
    max-width: 100%;
    padding: 0 15px 20px 15px;
  }
}
.card.card_picture-right .card__content {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  padding: 0;
}
.card__content {
  /* width: 38%; */
}
.subheader {
  color: #7b68ee;
  font: 800 11px / 12px Axiforma, Arial, serif;
  text-align: left;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0;
  margin: 20px 0 15px;
}
.section-v2__header {
  position: relative;
  font: 800 3.5rem / 5rem Axiforma, Arial, serif;
  margin: 20px 0 15px 0;
  margin-bottom: 0;
}
.after {
  content: "";
  display: block;
  position: absolute;
  width: 100px;
  height: 5px;
  border-radius: 7px;
  position: static;
  margin-top: 30px;
  background: linear-gradient(90deg, #9f8fff 100%, #7b68ee 0);
  margin-bottom: 50px;
}
@media screen and (max-width: 768px) {
  .plan-container {
    max-width: 100%;
    padding: 0 15px 20px 15px;
  }
  .plan-box {
    padding: 1rem;
  }
  .section-v2__header {
    line-height: 1.2;
  }
  .after {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Heading',{staticClass:"mb-4",attrs:{"heading":"h4","content":"Tests"}}),_c('hr'),(_vm.tests)?_c('div',[_c('div',{staticStyle:{"max-width":"1080px"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tests}},[_c('el-table-column',{attrs:{"label":"Test","width":"50px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('b',{staticClass:"test-color"},[_vm._v(" "+_vm._s(scope.row.teaching_resource.content.test.name)+" ")])]}}],null,false,1249657296)}),_c('el-table-column',{attrs:{"label":"Material Name"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('b',[_vm._v(" "+_vm._s(scope.row.teaching_resource.name)+" ")])]}}],null,false,3335556367)}),_c('el-table-column',{attrs:{"label":"Test Name","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('b',[_vm._v(" "+_vm._s(scope.row.teaching_resource.content.name)+" ")])]}}],null,false,812689768)}),_c('el-table-column',{attrs:{"prop":"Score","label":"Score","width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.user_progresses[0].user_exam)?_c('div',[_c('TestScore',{attrs:{"examId":scope.row.teaching_resource.content.id,"userExam":scope.row.user_progresses[0].user_exam,"testType":scope.row.teaching_resource.content.test.name,"role":"Student"}})],1):_c('div',[(scope.row.user_progresses[0].status == 'locked')?_c('div',[_c('router-link',{attrs:{"to":{
                    name: 'StudentPlans'
                  }}},[_c('el-tooltip',{attrs:{"effect":"dark","content":`Unlock all ${scope.row.teaching_resource.content.test.name} tests`,"placement":"top"}},[_c('el-button',{staticStyle:{"width":"100%"},attrs:{"type":"info"}},[_c('i',{staticClass:"fa fa-lock"})])],1)],1)],1):_c('el-button',{staticStyle:{"width":"100%"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.startTest(
                    scope.row.teaching_resource.id,
                    scope.row.teaching_resource.content.id,
                    scope.row.teaching_resource.content.test.name
                  )}}},[_c('i',{staticClass:"fas fa-play"}),_vm._v(" Start a Test ")])],1)]}}],null,false,544134745)}),_c('el-table-column',{attrs:{"prop":"Date","label":"Date","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                scope.row.user_progresses &&
                  scope.row.user_progresses[0].user_exam
              )?[_c('span',[_vm._v(" "+_vm._s(_vm.instant.formatGoogleTime( scope.row.user_progresses[0].user_exam.updated_at ))+" ")])]:[_vm._v(" - ")]]}}],null,false,767911314)})],1)],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="container pt-3">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle"></Heading>
    <hr />
    <!-- <el-input
      ref="filterInput"
      placeholder="Name / Teacher"
      v-model="search.search"
      @keyup.enter.native="searchResult"
    >
      <el-button
        el-button
        slot="append"
        icon="el-icon-search"
        @click="searchResult"
      >
        Search
      </el-button>
    </el-input> -->
    <template v-if="companies">
      <el-alert
        v-if="resultsTotal > -1"
        class="mt-3"
        type="success"
        :closable="false"
      >
        <span slot="title">
          Results: <b>{{ resultsTotal }}</b>
        </span>
      </el-alert>
      <div class="text-center" style="margin:15px 0">
        <el-pagination
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="9"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
      <el-table v-if="companies" :data="companies" stripe>
        <el-table-column label="Company Name" min-width="150">
          <template slot-scope="scope">
            <router-link
              :to="{
                name: 'CompanyInfo',
                params: {
                  id: scope.row.id
                }
              }"
            >
              <b>{{ scope.row.name }}</b>
            </router-link>
          </template>
        </el-table-column>
        <!-- <el-table-column
          :label="$t('TeacherCourses.Teacher')"
          min-width="150"
          v-if="isAdmin || isCompany"
        >
          <template slot-scope="scope">
            <b
              class="mr-1"
              v-for="(teacher, index) in scope.row.teacher_users"
              :key="teacher.id"
            >
              <router-link
                v-if="isAdmin"
                :to="{ name: 'AdminViewUser', params: { id: teacher.id } }"
              >
                <template v-if="index < scope.row.teacher_users.length - 1">
                  {{
                    `${user.displayName(
                      teacher.first_name,
                      teacher.last_name
                    )},`
                  }}
                </template>
                <template v-else>
                  {{
                    `${user.displayName(teacher.first_name, teacher.last_name)}`
                  }}
                </template>
              </router-link>
              <template v-else>
                <template v-if="index < scope.row.teacher_users.length - 1">
                  {{
                    `${user.displayName(
                      teacher.first_name,
                      teacher.last_name
                    )},`
                  }}
                </template>
                <template v-else>
                  {{
                    `${user.displayName(teacher.first_name, teacher.last_name)}`
                  }}
                </template>
              </template>
            </b>
          </template>
        </el-table-column>
        <el-table-column :label="$t('TeacherCourses.Class Description')" min-width="250">
          <template slot-scope="scope">
            <span v-if="scope.row.description">
              {{ scope.row.description }}
            </span>
            <span v-else>
              -
            </span>
          </template>
        </el-table-column>
         -->
        <el-table-column :label="$t('TeacherCourses.Class Dates')" width="150">
          <template slot-scope="scope">
            <template v-if="scope.row.created_at">
              {{ instant.formatGoogleTime(scope.row.created_at) }}
            </template>
            <template v-else>
              -
            </template>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('TeacherCourses.Action')"
          fixed="right"
          width="240"
        >
          <template slot-scope="scope">
            <!-- <el-tooltip
              class="mr-2"
              effect="dark"
              content="Invite Users"
              placement="top"
            >
              <el-button
                type="success"
                size="mini"
                @click="setLink(scope.row.slug)"
              >
                <i class="fas fa-user-plus"></i>
              </el-button>
            </el-tooltip> -->
            <el-tooltip
              class="mr-2"
              effect="dark"
              :content="$t('TeacherCourses.Preview')"
              placement="top"
            >
              <router-link
                :to="{
                  name: 'CompanyInfo',
                  params: {
                    id: scope.row.id
                  }
                }"
              >
                <el-button type="success" size="mini">
                  <i class="fas fa-eye"></i>
                </el-button>
              </router-link>
            </el-tooltip>
            <!-- <el-tooltip
              class="mr-2"
              effect="dark"
              :content="$t('TeacherCourses.Edit Class')"
              placement="top"
            >
              <router-link
                :to="{
                  name: editCourseLink,
                  params: {
                    id: scope.row.id
                  }
                }"
              >
                <el-button type="success" size="mini">
                  <i class="fa fa-edit"></i>
                </el-button>
              </router-link>
            </el-tooltip> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="text-center" style="margin:15px 0">
        <el-pagination
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="9"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
    </template>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import Breadcrumb from "@/components/Breadcrumb";
import Company from "@/views/company/apis/company.js";

export default {
  metaInfo() {
    return {
      title: this.myTitle + " - " + this.CompanyName
    };
  },

  components: { Breadcrumb },

  mixins: [],

  props: [],
  data() {
    return {
      companies: null,
      resultsTotal: 0,
      pageSize: 10
    };
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    myTitle() {
      return "Company";
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    }
  },
  watch: {},

  mounted() {
    this.getCompanies();
  },

  methods: {
    setPage(page) {
      this.routerPush({
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    },
    async getCompanies() {
      const res = await Company.getCompanies({
        page: this.page
      });
      this.companies = res.data;
      this.resultsTotal = res.total;
    }
  }
};
</script>

<style scoped></style>

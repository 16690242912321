<template>
  <div class="container">
    <div class="wrapper card__wrapper">
      <div class="card__content">
        <h1 class="subheader">
          About
        </h1>
        <h2
          class="section-v2__header section-v2__header_v2 section-v2__header_main"
        >
          Our mission.
        </h2>
        <div class="after">&nbsp;</div>
        <div class="section-v2__text">
          We want to revolutionize standardized test preparation by empowering
          students and educators with an intelligent, interactive platform.
        </div>
        <div class="section-v2__text">
          Through expert-crafted practice materials, AI-driven insights, and
          adaptive tools, we aim to make learning efficient, personalized, and
          engaging, helping every learner achieve their highest potential.
        </div>
      </div>
      <div class="card__picture">
        <div class="right-title">
          <h4 class="text-success">
            <span class="logo-color-icon">X</span>Mocks is
          </h4>
          <h4>
            the Future of <br />
            Test Prep
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";

export default {
  metaInfo() {
    return {
      title: "About - " + this.CompanyName
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {};
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    }
  },
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped>
.card__picture {
  position: relative;
  padding: 2rem;
}
.right-title {
  font-size: 5rem;
  font-weight: 700;
  background-color: white;
  border-radius: 1rem;
  opacity: 0.8rem;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  border: 1px solid #ebeef5;
  background-color: #fff;
  padding: 2rem 4rem;
}
.right-title h4 {
  margin: 0;
  font-size: 6rem;
  line-height: 8rem;
}
.card__picture:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: url("https://clickup.com/images/collaboration-detection/bg__with-dotted.svg")
    no-repeat top left / cover;
}

.card__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 10px 0;
  padding-top: 10rem;
}
.card.card_picture-right .card__content {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  padding: 0;
}
.card__content {
  width: 38%;
}
.subheader {
  color: #7b68ee;
  font: 800 11px / 12px Axiforma, Arial, serif;
  text-align: left;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0;
  margin: 20px 0 15px;
}
.section-v2__header {
  position: relative;
  font: 800 3.5rem / 5rem Axiforma, Arial, serif;
  margin: 20px 0 15px 0;
  margin-bottom: 0;
}
.card__wrapper_v2 .card__content > * {
  text-align: left;
}

.section-v2__text {
  color: #7c828d;
  font: 400 14px / 25px, Arial, serif;
  font-size: 1rem;
  margin: 15px auto;
}
.after {
  content: "";
  display: block;
  position: absolute;
  width: 100px;
  height: 5px;
  border-radius: 7px;
  position: static;
  margin-top: 30px;
  background: linear-gradient(90deg, #9f8fff 100%, #7b68ee 0);
  margin-bottom: 50px;
}

*,
::after,
::before {
  box-sizing: inherit;
}
.logo-color-icon {
  font-weight: 700;
  font-size: 110px;
  background: linear-gradient(to right, #0579ff, #ea00ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
@media screen and (max-width: 768px) {
  .after {

    margin-top: 10px;
    margin-bottom: 20px;
  }
  .section-v2__header {
    line-height: 1.2;
  }
  .logo-color-icon {
    font-size: 3.5rem;
  }

  .card__wrapper {
    display: block;
    padding: 1rem;
  }
  .card__content {
    width: 100%;
  }
  .section-v2__text {
    width: 100%;
  }
  .right-title {
    padding: 2rem;
  }
  .right-title h4 {
    margin: 0;
    font-size: 3rem;
    line-height: 4rem;
  }
}


</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container pt-3"},[_c('Breadcrumb',[_c('template',{slot:"restPage"},[_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{
            name: 'EducatorQuestionsList'
          }}},[_vm._v(" Questions ")])],1),_c('el-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.myTitle)+" ")])],1)],2),_c('Heading',{attrs:{"heading":"h4","content":_vm.myTitle}}),_c('hr'),_c('el-card',{staticClass:"test-paper mb-4"},[(_vm.isSATQuestion)?[(
          _vm.question &&
            _vm.question.type === 'single' &&
            _vm.question.passage_id > 0 &&
            _vm.question.passage.content
        )?_c('MultipleChoiceWithPassage',{attrs:{"mode":"explanation","passageIntro":_vm.question.passage.introduction
            ? _vm.question.passage.introduction.intro
            : null,"passageContent":_vm.getPassage,"questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.options,"answer":``,"correctAnswer":_vm.getCAnswers(_vm.question.answers),"isCorrect":false,"explanation":_vm.question.explanations && _vm.question.explanations.length > 0
            ? _vm.question.explanations[0].explanation
            : '',"showMark":false,"activities":_vm.answer && _vm.answer.other_status
            ? _vm.answer.other_status.activities
            : null}}):(
          _vm.question &&
            _vm.question.type === 'single' &&
            (_vm.question.passage === null || _vm.question.passage.content === null)
        )?_c('MultipleChoice',{attrs:{"mode":"explanation","questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.options,"answer":``,"correctAnswer":_vm.getCAnswers(_vm.question.answers),"isCorrect":false,"explanation":_vm.question.explanations && _vm.question.explanations.length > 0
            ? _vm.question.explanations[0].explanation
            : '',"showMark":false,"activities":_vm.answer && _vm.answer.other_status
            ? _vm.answer.other_status.activities
            : null}}):(_vm.question && _vm.question.type === 'math')?_c('Math',{attrs:{"mode":"explanation","questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.sat_options,"answer":``,"correctAnswer":_vm.getCAnswers(_vm.question.answers),"isCorrect":false,"explanation":_vm.question.explanations && _vm.question.explanations.length > 0
            ? _vm.question.explanations[0].explanation
            : '',"showMark":false,"activities":_vm.answer && _vm.answer.other_status
            ? _vm.answer.other_status.activities
            : null}}):_vm._e()]:_vm._e(),(_vm.isACTQuestion)?[(_vm.question)?_c('MultipleChoiceWithPassageACT',{ref:"MultipleChoiceWithPassage",attrs:{"id":_vm.questionOrder,"questionId":_vm.question.id,"testType":1,"canHighlighter":false,"canEliminator":false,"canMasking":false,"passageContent":_vm.question.passage ? _vm.question.passage.content : '',"questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.options,"answer":'',"tags":_vm.question.question_tags ? _vm.question.question_tags : [],"activities":_vm.question.metadata && _vm.question.metadata.activities
            ? _vm.question.metadata.activities
            : null,"isCorrect":_vm.question.is_correct,"correctAnswer":_vm.question.answers && _vm.question.answers.length > 0
            ? _vm.question.answers[0].answer[0]
            : '',"explanation":_vm.question && _vm.question.explanations
            ? _vm.question.explanations[0].explanation
            : '',"mode":"preview"}}):_vm._e()]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import ApiService from "@/common/api.service";

export default {
  getCompanies(data) {
    return ApiService.query("/organizations", data);
  },
  getCompanyDetail(id) {
    return ApiService.query(`/organizations/${id}`);
  },
  getCompanyUsers(id, data) {
    return ApiService.query(`/organizations/${id}/users`, data);
  },
  getCompanyClasses(id) {
    return ApiService.query(`/organizations/${id}/teaching-classes`);
  },
  companyGivePoints(id, data) {
    return ApiService.post(`/organizations/${id}/subscription-quota`, data);
  },
  adminSetCoins(id, data) {
    return ApiService.post(`/organizations/${id}/coins`, data);
  },
  adminGetCompanyTransactions(id, data) {
    return ApiService.query(`/organizations/${id}/coin-transactions`, data);
  }
};

import ApiService from "@/common/api.service";

export default {
  createPassage(data) {
    return ApiService.post("/passages", data);
  },
  updatePassage(id, data) {
    return ApiService.patch("/passages/" + id, data);
  },
  createQuestion(data) {
    return ApiService.post("/questions", data);
  },
  updateQuestion(id, data) {
    return ApiService.patch("/questions/" + id, data);
  },
  getQuestionDetail(questionId) {
    return ApiService.query(`/questions/${questionId}`);
  },
  getTags(data) {
    return ApiService.query("/question-tags", data);
  }
};

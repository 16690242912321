<template>
  <div v-if="file">
    <div v-if="file.type === 'document'">
      <div class="fileContainer">
        <h4>
          <i class="fas fa-file"></i>
          &nbsp;File
        </h4>
        <hr />
        <el-form label-width="100px">
          <el-form-item label="File">
            <b class="text-success">
              {{ file.name }}
            </b>
          </el-form-item>
        </el-form>
        <el-button
          type="primary"
          style="width: 100%;"
          @click="downloadFileBySlug(file.content.slug)"
        >
          <i class="fa fa-download"></i>
          Download
        </el-button>
      </div>
    </div>
    <div v-if="file.type === 'link'">
      <div class="fileContainer">
        <h4>
          <i class="fas fa-link"></i>
          &nbsp;{{ $t("newCourses.Link") }}
        </h4>
        <hr />
        <el-form label-width="100px">
          <el-form-item label="Name">
            <b class="text-success">
              {{ file.name }}
            </b>
          </el-form-item>
          <el-form-item label="Description">
            <b class="text-success" v-if="file.description">
              {{ file.description }}
            </b>
            <span v-else>-</span>
          </el-form-item>
          <el-form-item label="Link">
            <b class="text-success">
              <a :href="file.content.url"> {{ file.content.url }}</a>
            </b>
          </el-form-item>
        </el-form>
        <a :href="file.content.url" target="_blank">
          <el-button type="primary" style="width: 100%;">
            <i class="fa fa-eye"></i>
            Preview
          </el-button>
        </a>
      </div>
    </div>
    <div v-if="file.type === 'video'">
      <div class="fileContainer">
        <h4>
          <i class="fab fa-youtube"></i>
          &nbsp;Youtube Video
        </h4>
        <hr />
        <div class="videoContainer">
          <template>
            <template v-if="file.content && file.content.url">
              <iframe
                v-if="getVideo(file.content.url)"
                :src="getVideo(file.content.url)"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </template>
          </template>
        </div>
      </div>
    </div>
    <div v-if="file.type === 'exam'">
      <div>
        <div class="fileContainer" v-if="file.content">
          <h4>
            <i class="fas fa-chart-bar"></i>
            &nbsp;
            <div class="test-color">
              {{ file.content.test.name }}
            </div>
            &nbsp;Test
          </h4>
          <hr />
          <el-form label-width="100px">
            <el-form-item label="Name">
              <b class="text-success">
                {{ file.name }}
              </b>
            </el-form-item>
            <el-form-item label="Description">
              <b class="text-success" v-if="file.description">
                {{ file.description }}
              </b>
              <span v-else>-</span>
            </el-form-item>
            <el-form-item label="Test Name">
              <b class="text-success">
                {{ file.content.name }}
              </b>
            </el-form-item>
            <el-form-item label="Questions">
              <b class="text-success">
                {{ file.content.question_count }}
              </b>
              Questions
            </el-form-item>
            <el-form-item label="Score" v-if="isView">
              <div>
                <template v-if="isStudent && userExams">
                  <div v-if="userExams.user_progresses[0].user_exam">
                    <TestScore
                      :examId="file.content_id"
                      :userExam="userExams.user_progresses[0].user_exam"
                      :testType="file.content.test.name"
                      role="Student"
                    ></TestScore>
                  </div>
                  <template v-else>
                    <div v-if="userExams.user_progresses[0].status == 'locked'">
                      <router-link
                        :to="{
                          name: 'StudentPlans'
                        }"
                      >
                        <el-tooltip
                          effect="dark"
                          :content="
                            `Unlock all ${file.content.test.name} tests`
                          "
                          placement="top"
                        >
                          <el-button type="info" style="width: 100%;">
                            <i class="fa fa-lock"></i>
                          </el-button>
                        </el-tooltip>
                      </router-link>
                    </div>
                    <el-button
                      v-else
                      type="primary"
                      style="width: 100%;"
                      @click="
                        startTest(
                          file.id,
                          file.content.id,
                          file.content.test.name
                        )
                      "
                    >
                      <i class="fas fa-play"></i>
                      Start a Test
                    </el-button>
                  </template>
                </template>
                <template v-if="isEducator && userExams">
                  <el-card class="mb-4 p-4" shadow="never">
                    <h5 class="mb-2">
                      <div class="d-flex">
                        <div class="test-color mr-2">
                          {{ file.content.test.name }}
                        </div>
                        <div class="mr-2">
                          {{ file.content.name }}
                        </div>
                      </div>
                    </h5>
                    <div
                      style="width: 100%;"
                      v-if="userExams && userExams.user_progresses"
                    >
                      <el-table
                        :data="userExams.user_progresses"
                        style="width: 100%;"
                      >
                        <el-table-column
                          prop="Students"
                          label="Students"
                          width="180"
                        >
                          <template slot-scope="scope">
                            <b>
                              {{
                                user.displayName(
                                  scope.row.user.first_name,
                                  scope.row.user.last_name
                                )
                              }}
                            </b>
                          </template>
                        </el-table-column>
                        <el-table-column prop="Score" label="Score">
                          <template slot-scope="scope">
                            <template v-if="scope.row.user_exam">
                              <EducatorTestScore
                                :examId="file.content_id"
                                :userExam="scope.row.user_exam"
                                :testType="file.content.test.name"
                                role="Educator"
                              ></EducatorTestScore>
                            </template>
                            <div v-else>
                              <template v-if="scope.row.status === 'locked'">
                                <router-link
                                  :to="{
                                    name: 'CompanyUsers',
                                    params: {
                                      id: 'class'
                                    }
                                  }"
                                >
                                  <el-tooltip
                                    effect="dark"
                                    :content="
                                      `Unlock all ${file.content.test.name} tests for student`
                                    "
                                    placement="top"
                                  >
                                    <el-button
                                      type="info"
                                      size="mini"
                                      style="width: 12rem;"
                                    >
                                      <i class="fa fa-lock"></i>
                                    </el-button>
                                  </el-tooltip>
                                </router-link>
                              </template>
                              <template v-else>
                                <el-tag
                                  style="width: 12rem;"
                                  class="text-center"
                                  type="info"
                                  effect="plain"
                                >
                                  <b>
                                    <i class="fas fa-user"></i>
                                    Pending
                                  </b>
                                </el-tag>
                              </template>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="Test Date"
                          label="Test Date"
                          width="150"
                        >
                          <template slot-scope="scope">
                            <template v-if="scope.row.user_exam">
                              <span>
                                {{
                                  instant.formatGoogleTime(
                                    scope.row.user_exam.updated_at
                                  )
                                }}
                              </span>
                            </template>
                            <template v-else>
                              -
                            </template>
                          </template>
                        </el-table-column>
                        <!-- <el-table-column prop="Actions" label="Actions" width="250">
                          <template slot-scope="scope">
                            <el-tooltip
                              effect="dark"
                              content="Search for the tests that the student has done in history and set it as the current test."
                              placement="top"
                            >
                              <el-button
                                type="primary"
                                size="small"
                                style="width: 8rem;"
                              >
                                <i class="fas fa-users-cog"></i>
                                Set Test
                              </el-button>
                            </el-tooltip>
                          </template>
                        </el-table-column> -->
                      </el-table>
                    </div>
                  </el-card>
                </template>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import Materials from "@/views/courses/materials/apis/materials";
import TestScore from "@/views/courses/tests/components/TestScore";
import EducatorTestScore from "@/views/courses/tests/components/EducatorTestScore";

export default {
  metaInfo() {},

  components: { TestScore, EducatorTestScore },

  mixins: [],

  props: ["file", "isView"],
  data() {
    return {
      userExams: null
    };
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    isEducator() {
      return this.$route.meta && this.$route.meta.role
        ? this.$route.meta.role === "educator"
        : false;
    },
    isStudent() {
      return this.$route.meta && this.$route.meta.role
        ? this.$route.meta.role === "student"
        : false;
    }
  },
  watch: {
    file() {
      if (this.file.content_type == "exam") {
        this.getUserExams();
      }
    }
  },

  mounted() {
    if (this.file.content_type == "exam") {
      this.getUserExams();
    }
  },

  methods: {
    async getUserExams() {
      this.userExams = null;
      const res = await Materials.getUserExams(this.file.id);
      this.userExams = res && res.length > 0 ? res[0] : null;
    },
    downloadFileBySlug(slug) {
      window.location.href =
        process.env.VUE_APP_BACKEND_URL + `files/${slug}/download/`;
    },
    async startTest(fileId, examId, testName) {
      const res = await Materials.getExam(examId);
      let userExamId = res.user_exam.id;
      await Materials.bindExamToClasses(fileId, {
        user_exam_id: userExamId
      });
      this.$router.push({
        name: testName + "Test",
        params: {
          examId: this.file.content_id,
          userExamId: userExamId
        }
      });
    },
    getVideo(url) {
      return url.replace("youtu.be/", "www.youtube.com/embed/");
    }
  }
};
</script>

<style scoped>
.videoContainer {
  border: 1px solid #ebeef5;
  border-radius: 1rem;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  overflow: hidden;
}
.fileContainer {
  /* border: 1px solid #ccc; */
  /* border-radius: 1rem; */
  position: relative;
  /* padding-bottom: 56.25%; */
  padding: 1.5rem;
  overflow: auto;
  min-height: 600px;
  /* background-color: #fafafa; */
}

.videoContainer iframe,
.video-container object,
.video-container embed,
.videoContainer img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>

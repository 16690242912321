<template>
  <div class="container pt-3">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle"></Heading>
    <hr />
    <template v-if="companies">
      <el-alert
        v-if="resultsTotal > -1"
        class="mt-3"
        type="success"
        :closable="false"
      >
        <span slot="title">
          Results: <b>{{ resultsTotal }}</b>
        </span>
      </el-alert>
      <div class="text-center" style="margin:15px 0">
        <el-pagination
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="9"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
      <el-table v-if="companies" :data="companies" stripe>
        <el-table-column label="Company Name" min-width="150">
          <template slot-scope="scope">
            <!-- <router-link
                :to="{
                  name: 'CompanyInfo',
                  params: {
                    id: scope.row.id
                  }
                }"
              >
            </router-link> -->
            <b>{{ scope.row.name }}</b>
          </template>
        </el-table-column>
        <!-- <el-table-column label="Admin" min-width="150">
          <template slot-scope="scope">
          </template>
        </el-table-column> -->
        <el-table-column label="Coins" min-width="150">
          <template slot-scope="scope">
            <b class="text-success">
              {{ scope.row.coins }}
            </b>
            <el-button
              class="ml-2"
              type="primary"
              size="mini"
              @click="selectCompany(scope.row)"
            >
              <i class="fas fa-edit"></i>
            </el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column
          :label="$t('TeacherCourses.Teacher')"
          min-width="150"
          v-if="isAdmin || isCompany"
        >
          <template slot-scope="scope">
            <b
              class="mr-1"
              v-for="(teacher, index) in scope.row.teacher_users"
              :key="teacher.id"
            >
              <router-link
                v-if="isAdmin"
                :to="{ name: 'AdminViewUser', params: { id: teacher.id } }"
              >
                <template v-if="index < scope.row.teacher_users.length - 1">
                  {{
                    `${user.displayName(
                      teacher.first_name,
                      teacher.last_name
                    )},`
                  }}
                </template>
                <template v-else>
                  {{
                    `${user.displayName(teacher.first_name, teacher.last_name)}`
                  }}
                </template>
              </router-link>
              <template v-else>
                <template v-if="index < scope.row.teacher_users.length - 1">
                  {{
                    `${user.displayName(
                      teacher.first_name,
                      teacher.last_name
                    )},`
                  }}
                </template>
                <template v-else>
                  {{
                    `${user.displayName(teacher.first_name, teacher.last_name)}`
                  }}
                </template>
              </template>
            </b>
          </template>
        </el-table-column>
        <el-table-column :label="$t('TeacherCourses.Class Description')" min-width="250">
          <template slot-scope="scope">
            <span v-if="scope.row.description">
              {{ scope.row.description }}
            </span>
            <span v-else>
              -
            </span>
          </template>
        </el-table-column>
         -->
        <el-table-column :label="$t('TeacherCourses.Class Dates')" width="150">
          <template slot-scope="scope">
            <template v-if="scope.row.created_at">
              {{ instant.formatGoogleTime(scope.row.created_at) }}
            </template>
            <template v-else>
              -
            </template>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-center" style="margin:15px 0">
        <el-pagination
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="9"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
    </template>
    <el-dialog
      title="Add Coins"
      v-if="company"
      :visible.sync="showCompany"
      width="60%"
    >
      <el-form label-width="100px">
        <el-form-item label="Balance">
          <b class="text-success">
            {{ form.oCoins }}
            <small v-if="form.oCoins > 1"> Coins</small>
            <small v-else-if="form.oCoins > -1 && form.oCoins < 2">
              Coin
            </small>
          </b>
        </el-form-item>
        <el-form-item label="">
          <el-input-number
            v-model="form.coins"
            :step="10"
            style="width: 100%;"
          ></el-input-number>
        </el-form-item>
        <el-form-item>
          <div class="text-right">
            <el-button type="success" @click="setCoins">
              Save
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import Breadcrumb from "@/components/Breadcrumb";
import Company from "@/views/company/apis/company.js";

export default {
  metaInfo() {
    return {
      title: this.myTitle + " - " + this.CompanyName
    };
  },

  components: { Breadcrumb },

  mixins: [],

  props: [],
  data() {
    return {
      companies: null,
      resultsTotal: 0,
      pageSize: 10,
      showCompany: false,
      company: null,
      form: {
        id: null,
        coins: 0,
        oCoins: 0
      }
    };
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    myTitle() {
      return "Companies";
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    }
  },
  watch: {},

  mounted() {
    this.getCompanies();
  },

  methods: {
    selectCompany(company) {
      this.company = null;
      this.company = company;
      this.showCompany = true;
      this.form.id = company.id;
      this.form.coins = 0;
      this.form.oCoins = company.coins;
    },
    async setCoins() {
      await Company.adminSetCoins(this.form.id, {
        coins: this.form.coins
      });
      this.$message({
        message: "Success!",
        type: "success"
      });
      this.getCompanies();
      this.showCompany = false;
    },
    setPage(page) {
      this.routerPush({
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    },
    async getCompanies() {
      const res = await Company.getCompanies({
        page: this.page
      });
      this.companies = res.data;
      this.resultsTotal = res.total;
    }
  }
};
</script>

<style scoped></style>

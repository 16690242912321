<template>
  <div>
    <el-card>
      <div>
        <section id="banner">
          <div class="inner">
            <div class="line1">404</div>
            <div class="pb-2 line2">
              This is not the page you're looking for.
            </div>
            <router-link :to="{ name: 'StudentHome' }">
              <i class="fa fa-home"></i>Homepage
            </router-link>
          </div>
        </section>
      </div>
    </el-card>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  metaInfo() {
    return {
      title: "404 - " + this.CompanyName
    };
  },

  mixins: [],

  props: [],
  data() {
    return {};
  },
  computed: {
    routerName() {
      return this.$route.name;
    }
  },
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped>
::v-deep .el-card {
  box-shadow: none;
  border: none;
}

#banner {
  display: -ms-flexbox;
  -ms-flex-pack: center;
  -ms-flex-align: center;
  padding: 2em 0 6em 0;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  min-height: 75vh;
  height: 75vh;
  position: relative;
  text-align: center;
  color: var(--themeColor);
}
.line1 {
  font-size: 4rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.line2 {
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
</style>

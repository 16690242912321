<template>
  <div v-if="Results">
    <el-table :data="Results" :row-class-name="tableRowClassName">
      <el-table-column label="Test" min-width="200">
        <template slot-scope="scope">
          <router-link
            :to="{
              name: 'SATResultPage',
              params: { userExamId: scope.row.id }
            }"
            v-if="scope.row.finished_at"
          >
            <b>
              {{ scope.row.exam.name }}
            </b>
          </router-link>
          <span v-else>
            {{ scope.row.exam.name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Score" width="100">
        <template slot-scope="scope">
          <b v-if="scope.row.finished_at" class="text-success text-bold">
            {{ Number(scope.row.score) }}
          </b>
          <span v-else>
            -
          </span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        label="Correct/Total"
        width="150"
        v-if="type === 'drill'"
      >
        <template slot-scope="scope" v-if="scope.row.correct_count > -1">
          <b v-if="scope.row.is_finished">
            <span class="text-success">{{ scope.row.correct_count }}</span>
            <template v-if="scope.row.exam.question_count">/{{ scope.row.exam.question_count }}</template>
          </b>
          <span v-else>
            <span>{{ scope.row.correct_count }}</span>
            <template v-if="scope.row.exam.question_count">/{{ scope.row.exam.question_count }}</template>
          </span>
        </template>
      </el-table-column> -->
      <el-table-column label="Status" width="120">
        <template slot-scope="scope">
          <el-tag
            :type="!scope.row.finished_at ? 'info' : 'success'"
            disable-transitions
          >
            <span v-if="!scope.row.finished_at">
              Incomplete
            </span>
            <b v-else>Completed</b>
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Date" width="100">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="moment(scope.row.updated_at).format('YYYY-MM-DD HH:mm')"
            placement="top"
          >
            <span>{{ instant.formatGoogleTime(scope.row.updated_at) }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Actions" width="100">
        <template slot-scope="scope">
          <template v-if="!scope.row.finished_at">
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('courses.Resume')"
              placement="top"
            >
              <router-link
                :to="{
                  name: 'SATTest',
                  params: {
                    examId: scope.row.exam.id,
                    userExamId: scope.row.id
                  }
                }"
              >
                <el-button type="warning" size="small" style="width: 50px;">
                  <i class="fa fa-play"></i>
                </el-button>
              </router-link>
            </el-tooltip>
          </template>
          <template v-else>
            <el-tooltip
              class="item"
              effect="dark"
              content="View"
              placement="top"
            >
              <router-link
                :to="{
                  name: 'SATResultPage',
                  params: { userExamId: scope.row.id }
                }"
              >
                <el-button type="success" size="small" style="width: 50px;">
                  <i class="fa fa-eye"></i>
                </el-button>
              </router-link>
            </el-tooltip>
          </template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import SAT from "@/apis/sat";
import { instant, user } from "@ivy-way/material";
import moment from "moment";
export default {
  metaInfo() {},

  components: {},

  mixins: [],

  props: ["type", "Results", "role", "showRole"],
  data() {
    return {
      moment
    };
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    isAdmin() {
      return this.role === "admin";
    }
  },
  watch: {},

  mounted() {},

  methods: {
    reGrading(userExamId) {
      this.$confirm(
        "Are you sure you want to regrade this test?",
        this.$t("management.Regrade"),
        {
          confirmButtonText: this.$t("alert.confirm"),
          cancelButtonText: this.$t("alert.cancel"),
          type: "warning"
        }
      )
        .then(async () => {
          await SAT.reGradingUserExam(userExamId);
          this.$message({
            message: "Success!",
            type: "success"
          });
          setTimeout(async () => {
            await this.$emit("remove");
          }, 1000);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("alert.Canceled")
          });
        });
    },
    removeTestResult(id) {
      this.$confirm(
        "Are you sure you want to delete this test? This cannot be undone.",
        this.$t("newCourses.Confirmation"),
        {
          confirmButtonText: this.$t("newCourses.Confirm"),
          cancelButtonText: this.$t("newCourses.Cancel"),
          type: "error"
        }
      ).then(async () => {
        await SAT.removeTestResult(this.showRole, id);
        setTimeout(async () => {
          await this.$emit("remove");
        }, 1000);
      });
    },
    typeNow(type, row) {
      if (type === "drill") {
        return {
          practiceType: "singlePractice",
          id: row.exam.test_id
        };
      } else {
        if (row.other_status.adaptive_practice_id) {
          return {
            practiceType: "adaptivePractices",
            id: row.other_status.adaptive_practice_id
          };
        } else {
          return {
            practiceType: type,
            id: row.exam.test_id
          };
        }
      }
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.is_finished === 1) {
        return "success-row";
      }
      return "incomplete-row";
    }
  }
};
</script>

<style scoped>
::v-deep .el-table .warning-row {
  background: #fdf5e6;
}
::v-deep .el-table .success-row {
  background: #f0f9eb;
}
/* ::v-deep .el-table .incomplete-row {
  background: #fefefe;
} */
</style>

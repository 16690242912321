<template>
  <div v-if="showSessionClass">
    <!-- <Heading heading="h4" :content="myTitle"></Heading>
    <hr /> -->
    <div>
      <el-form
        :model="sessionClass"
        ref="newTest"
        label-width="140px"
        @submit.native.prevent
        @keyup.enter.native="createNew('newTest')"
      >
        <el-form-item label="Educator">
          <el-table border :data="getEducator(course.users)" class="mb-3">
            <el-table-column label="Name" prop="">
              <template slot-scope="scope">
                {{
                  user.displayName(scope.row.first_name, scope.row.last_name)
                }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('login.email')" prop="email">
            </el-table-column>
            <el-table-column :label="$t('TeacherCourses.Join Date')">
              <template slot-scope="scope">
                {{ instant.formatGoogleTime(scope.row.updated_at) }}
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item :label="$t('TeacherCourses.Students')" prop="students">
          <el-table border :data="getStudents(course.users)" class="mb-3">
            <el-table-column label="Name" prop="">
              <template slot-scope="scope">
                {{
                  user.displayName(scope.row.first_name, scope.row.last_name)
                }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('login.email')" prop="email">
            </el-table-column>
            <el-table-column :label="$t('TeacherCourses.Join Date')">
              <template slot-scope="scope">
                {{ instant.formatGoogleTime(scope.row.updated_at) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('TeacherCourses.Action')" width="100px">
              <template slot-scope="scope">
                <el-tooltip
                  effect="dark"
                  content="Remove Student"
                  placement="top"
                >
                  <el-button
                    type="danger"
                    size="small"
                    @click="removeStudent(scope.row)"
                  >
                    <i class="fas fa-user-minus"></i>
                  </el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
          <el-tooltip
            effect="dark"
            :content="$t('TeacherCourses.Add Student')"
            placement="top"
          >
            <el-button
              type="success"
              size="small"
              @click="setLink(course.slug)"
            >
              <i class="fas fa-user-plus"></i>
              Add Student
            </el-button>
          </el-tooltip>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
      class="class-link"
      :title="`Class Invitation Link`"
      :visible.sync="showLink"
      width="50%"
    >
      <p>{{ $t("TeacherCourses.Copy and send this link to students") }}</p>
      <div class="text-center">
        <div class="referral-code">
          <a>
            <span>
              {{ link }}
            </span>
          </a>
          <el-button class="ml-3" type="success" @click="copyLink">
            <i class="fas fa-copy"></i>
            Copy Link
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import Class from "@/views/courses/apis/Add.js";
import SAT from "@/apis/sat";
import Common from "@/mixins/common.js";
import Theme from "@/common/theme";
import role from "@/mixins/role.js";
import Company from "@/apis/company";
import baseDomainPath from "@/common/baseDomainPath";

export default {
  metaInfo() {
    return {
      title: `${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: {},

  mixins: [Common, role],

  props: [],
  data() {
    return {
      course: null,
      showSessionClass: false,
      showLink: false
    };
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    roleIsTeacherOrAdmin() {
      return this.isRoleAdmin() || this.isRoleTeacher();
    },
    isAdmin() {
      return false;
    },
    isStudent() {
      return false;
    },
    myTitle() {
      return "Edit Roles";
    }
  },
  watch: {},

  async mounted() {
    if (this.$route.params.id) {
      this.showSessionClass = false;
      await this.getClass(this.$route.params.id);
    }
    // await this.getComposePractices();
    // await this.getAdaptivePractices();
    // await this.getModules();
    // await this.getDrills();
    // this.searchUsers();
    // this.searchTeachers();
    if (this.isTeacher && !this.isEdit) {
      if (this.teacher_users.length === 0) {
        this.teacher_users.push(this.getProfile);
      }
    }
    this.showSessionClass = true;
  },

  methods: {
    removeStudent(user) {
      this.$confirm(
        "Are you sure you want to remove the student from the class?",
        "Confirmation",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      ).then(async () => {
        await Class.removeStudent(this.$route.params.id, {
          user_id: user.id
        });
        await this.getClass(this.$route.params.id);
      });
    },
    getStudents(users) {
      if (users["member"]) {
        return users["member"];
      } else {
        return [];
      }
    },
    getEducator(users) {
      if (users["owner"]) {
        return users["owner"];
      } else {
        return [];
      }
    },
    async getClass(id) {
      const res = await Class.getClassDetail(id);
      this.course = res;
    },
    setLink(code) {
      this.linkType = "Class";
      this.link = `${baseDomainPath}student/classes/join?code=${code}`;
      this.showLink = true;
    },
    copyLink() {
      var oInput = document.createElement("input");
      oInput.value = this.link;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.showLink = false;
      this.$message({
        message: "Copied",
        type: "success"
      });
    }
  }
};
</script>

<style scoped>
::v-deep .select-popper {
  z-index: 9999 !important;
  top: auto !important;
  left: auto !important;
}
.box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding-top: 60px;
}
.box .left-menu {
  width: 200px;
  height: 100%;
}
.box .right-content {
  background: #fafafa;
}
.right-content-detail {
  width: inherit;
  margin-left: 72px;
  margin-right: 72px;
  margin-top: 20px !important;
  padding-bottom: 20px;
}
.button-group {
  padding: 1rem 20px;
}
::v-deep .el-menu {
  border: none;
}
::v-deep .button-group .el-button {
  width: 100%;
  margin: 0.5rem 0;
}
.right-content {
  flex: 1;
  height: 100%;
  overflow: auto;
}

.referral-code span {
  display: inline-block;
  height: 41px;
  line-height: 41px;
  padding: 0 1rem;
  font-size: 1rem;
  background: rgb(241, 243, 244);
  border-radius: 4px;
}
::v-deep .class-link .el-dialog__body {
  padding-top: 0.2rem;
}
</style>
